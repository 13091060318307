define('views/layouts/addAWSStorageLayout',[
    'frontend-common-components/objectStorageConfig/js/views/layouts/addAWSStorageLayout',
    'controllers/notificationController',
    'mixins/pageLoadIndicatorMixin',
    'i18n!nls/messages',
    'cocktail'
],
function (
    BaseAddAWSStorageView,
    notificationController,
    pageLoadIndicatorMixin,
    messageBundle,
    Cocktail
) {
    'use strict';
    var AddAWSStorageLayout = BaseAddAWSStorageView.extend({

        onStorageBucketListLoading: function () {
            this.showLoading();
        },

        onStorageBucketListComplete: function () {
            this.hideLoading();
        },

        onStorageConfigSaving: function () {
            this.showLoading();
        },
        
        onStorageConfigSaveSuccess: function () {
            this.hideLoading();
        },
        
        onStorageConfigSaveFailure: function (response) {
            this.hideLoading();
            if (response.status !== 400) {
                notificationController.showErrorNotification(messageBundle.global.unexpectedError);
            }
        }
    });

    Cocktail.mixin(AddAWSStorageLayout, pageLoadIndicatorMixin);

    return AddAWSStorageLayout;
});


/* START_TEMPLATE */
define('hbs!templates/layouts/side',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  


  return "<div id=\"sideNavRegion\" class=\"region\"></div>\n<div id=\"gettingStartedRegion\" class=\"region\"></div>\n";
  });
Handlebars.registerPartial('templates/layouts/side', t);
return t;
});
/* END_TEMPLATE */
;

define('constants/viewNames',[],function () {
    'use strict';
    return {
        mainLayout: {
            overview: 'overview',
            activity: 'activity',
            storage: 'storage',
            keys: 'keys'
        }
    };
});

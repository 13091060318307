define('collections/apiKeyCollection',[
    'collections/base/baseCollection',
    'helpers/skydropContextHelper',
    'underscore',
    'models/apiKey',
    'libs/awsDateToJSDate'
],
function (BaseCollection, contextHelper, _, apiKey, awsDateToJSDate) {
    'use strict';
    var compareDates = function (a, b) {
        var aDate = awsDateToJSDate(a.get('createdDate')),
            bDate = awsDateToJSDate(b.get('createdDate'));
            
        if (aDate < bDate) {
            return 1;
        }
        if (bDate < aDate) {
            return -1;
        }
        return 0;
    };
    
    return BaseCollection.extend({
        model: apiKey,
        url: function () {
            return contextHelper.getBaseUrl() + 'apiKeys';
        },
        comparator: function (a, b) {
            var aStatus = a.get('status');
            var bStatus = b.get('status');
        	
            if(aStatus < bStatus) {
                return 1;
            }

            if(aStatus > bStatus) {
                return -1;
            }

            if(_.isEqual(aStatus, bStatus)) {
                var aName = a.get('name');
                var bName = b.get('name');
  	              
	            if ((_.isUndefined(aName) || _.isNull(aName)) &&
                    (_.isUndefined(bName) || _.isNull(bName))) {
                    return compareDates(a, b);
	            }
            
                if (_.isUndefined(aName) || _.isNull(aName)) {
                    return -1;
                }
                
                if (_.isUndefined(bName) || _.isNull(bName)) {
                    return 1;
                }
                
                aName = aName.toLowerCase();
                bName = bName.toLowerCase();
                
                if (aName > bName) {
                    return 1;
                }
                
                if (aName < bName) {
                    return -1;
                }
                
                return compareDates(a, b);
            }
        }
    });
});

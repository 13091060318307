define('validation/passwordValidation',[
], function () {
    'use strict';
    /*
     * Regex explanation:
     *    [group 0] (?=^.{8,100}$) - a minimum length of 8, no more than 100, and
     *    [group 1] (
     *      (?!.*\s) - no white space characters (NB - negative lookahead, NOT 'lookbehind' which IE doesn't support), 
     *      and
     *      (?=.*[A-Z]){1,} - at least 1 Upper case character, and 
     *    )
     *    [group 2] (?=.*[a-z]){1,} - at least 1 Lower case character, and 
     *    [group 3] ((?=(.*[\d\W_]){1,})) - at least 1 number or special character
     *    ^.*$ - end-of-stmt anchor
     *   
     */
    var regExp = /(?=^.{8,100}$)((?!.*\s)(?=.*[A-Z]{1,}))((?=.*[a-z]{1,}))((?=(.*[\d\W_]){1,}))^.*$/;
    
    return function (password) {
        return regExp.test(password);
    };
});

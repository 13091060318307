
/* START_TEMPLATE */
define('hbs!templates/layouts/keys',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"row\">\n    <div class=\"col-md-12 col-md-offset-1\">\n        <h1 class=\"content-header\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "keys.pageHeader", options) : helperMissing.call(depth0, "i18n", "keys.pageHeader", options)))
    + "</h1>\n        <div id=\"showRevokedKeysWrapper\" class=\"show-revoked-keys-wrapper\">\n		    <label for=\"showRevokedKeysCheckBox\" class=\"show-revoked-keys-label\">\n		        <input type=\"checkbox\" id=\"showRevokedKeysCheckBox\" class=\"show-revoked-keys-checkbox\">\n		        "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "keys.showRevokedCheckBox", options) : helperMissing.call(depth0, "i18n", "keys.showRevokedCheckBox", options)))
    + "\n		    </label>\n	    </div>\n	    <div class=\"vertical-space-10\"></div>\n    </div>\n</div>\n<div class=\"row\">\n    <div class=\"col-md-1\">\n        <a href=\"#\" id=\"keys-add\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "keys.add", options) : helperMissing.call(depth0, "i18n", "keys.add", options)))
    + "</a>\n    </div>\n    <div class=\"table-area col-md-9\"></div>\n</div>";
  return buffer;
  });
Handlebars.registerPartial('templates/layouts/keys', t);
return t;
});
/* END_TEMPLATE */
;

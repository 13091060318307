
/* START_TEMPLATE */
define('hbs!templates/layouts/loginPanel',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  


  return "<div class=\"skydrop-admin-panel-box\">\n    <div class=\"skydrop-admin-panel-logo\" >\n        <img src=\"resources/css/images/flight_signin_logo.svg\" width=\"180px\" height=\"70px\" />\n    </div>\n\n    <div class=\"login-panel-region\">\n        \n    </div>\n</div>";
  });
Handlebars.registerPartial('templates/layouts/loginPanel', t);
return t;
});
/* END_TEMPLATE */
;

define('views/layouts/sideLayout',[
    'views/base/baseLayoutView',
    'hbs!templates/layouts/side'
], function (BaseLayoutView, SideLayoutTmpl) {
    'use strict';
    return BaseLayoutView.extend({
        template: SideLayoutTmpl,

        initialize: function () {
        },

        regions: {
            'sideNavRegion': '#sideNavRegion',
            'gettingStartedRegion': '#gettingStartedRegion'
        }
    });
});

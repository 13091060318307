define('constants/events',[],function () {
    'use strict';

    return {
        SideNav: {
            selectItem: 'sideNavItem:select'
        },
        RadioChannels: {
            pageLoad: 'pageLoad',
            loginRouter: 'loginRouter',
            gettingStarted: 'gettingStarted'
        },
        PageLoadIndicator: {
            show: 'PageLoadIndicator:show',
            hide: 'PageLoadIndicator:hide'
        },
        LoginRouter: {
            route: 'LoginRouter:route'
        },
        TransferActivity: {
            pageLoaded: 'transferActivity:pageLoaded',
            pageLoadError: 'transferActivity:pageLoadError'
        },
        GettingStarted: {
            closed: 'gettingStarted:closed',
            opened: 'gettingStarted:opened',
            stateTransition: 'gettingStarted:stateTransition',
            cliModalClosed: 'gettingStarted:cliModalClosed'
        },
        TransferFileHelp: {
        	addStorageClicked: 'addStorageClicked'
        }
    };
});

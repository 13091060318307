
/* START_TEMPLATE */
define('hbs!templates/layouts/activity',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"row\">\n    <div class=\"content-header-col col-md-12 col-sm-12 col-xs-12 col-md-offset-1 col-sm-offset-1 col-xs-offset-1\">\n        <h1 class=\"content-header\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "activity.pageHeader", options) : helperMissing.call(depth0, "i18n", "activity.pageHeader", options)))
    + "</h1>\n    </div>\n</div>\n<div class=\"row\">\n    <div class=\"col-md-12 col-sm-12 col-xs-12 col-md-offset-1 col-sm-offset-1 col-xs-offset-1\">\n        <div class=\"activities\"/>\n    </div>\n</div>\n<div class=\"row\">\n    <div class=\"col-md-11 col-sm-11 col-xs-11 col-md-offset-1 col-sm-offset-1 col-xs-offset-1\">\n        <span class=\"fa fa-angle-left fa-5x paging-icon invisible\"></span>\n        <span class=\"fa fa-angle-right fa-5x paging-icon col-md-offset-9 col-sm-offset-9 col-xs-offset-9 invisible\"></span>\n    </div>\n</div>";
  return buffer;
  });
Handlebars.registerPartial('templates/layouts/activity', t);
return t;
});
/* END_TEMPLATE */
;

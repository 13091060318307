define('views/layouts/addStorageLayout',[
    'views/base/baseLayoutView',
    'hbs!frontend-common-components/objectStorageConfig/templates/layouts/addStorage',
    'views/items/storageSelectionView',
    'views/layouts/addAWSStorageLayout',
    'views/layouts/addAzureStorageLayout',
    'models/awsStorageConfig',
    'models/azureStorageConfig',
    'frontend-common-components/objectStorageConfig/js/constants/storageConfigs',
    'helpers/skydropContextHelper',
    'frontend-common-components/objectStorageConfig/js/helpers/addStorageTemplateHelper',
    'frontend-common-components/objectStorageConfig/js/constants/events',
    'environment'
],
function (
    BaseLayoutView,
    addStorageTmpl,
    StorageSelectionView,
    AddAWSStorageLayout,
    AddAzureStorageLayout,
    AWSStorageConfig,
    AzureStorageConfig,
    StorageConfigs,
    skydropContextHelper,
    addStorageTemplateHelper,
    ObjectStorageEvents,
    env
) {
    'use strict';
    return BaseLayoutView.extend({
        template: addStorageTmpl,
        
        className: 'add-storage-view',

        ui: {
            close: '.close'
        },


        regions: {
            bodyRegion: '.add-storage-body'
        },

        events: {
            'click @ui.close': 'closeAddStorageView'
        },
        
        initialize: function () {
            this.currentView = null;
        },

        closeAddStorageView: function () {
            this.trigger(ObjectStorageEvents.closeAddStorage);
        },
        
        onShow: function () {
            this.showStorageSelectionView();
        },

        showStorageSelectionView: function () {
            this.currentView = new StorageSelectionView();
            this.listenTo(this.currentView, ObjectStorageEvents.typeSelected, this.typeSelected);
            this.listenTo(this.currentView, ObjectStorageEvents.closeAddStorage, this.closeAddStorageView);
            this.bodyRegion.show(this.currentView);
        },

        hideStorageSelectionView: function () {
            this.stopListening(this.currentView, ObjectStorageEvents.typeSelected, this.typeSelected);
            this.stopListening(this.currentView, ObjectStorageEvents.closeAddStorage, this.closeAddStorageView);
        },

        AWSConfigAdded: function (model) {
            this.trigger(ObjectStorageEvents.awsConfigAdded, model);
        },

        azureConfigAdded: function (model) {
            this.trigger(ObjectStorageEvents.azureConfigAdded, model);
        },

        showAWSView: function () {
            this.currentView = new AddAWSStorageLayout({
                model: new AWSStorageConfig(),
                templateHelpers: addStorageTemplateHelper(env.signiantAccountId, env.accounts[0]),
                baseURL: skydropContextHelper.getBaseUrl()
            });
            this.bodyRegion.show(this.currentView);
            this.listenTo(this.currentView, ObjectStorageEvents.awsConfigAdded, this.AWSConfigAdded);
        },

        showAzureView: function () {
            this.currentView = new AddAzureStorageLayout({
                model: new AzureStorageConfig(),
                baseURL: skydropContextHelper.getBaseUrl()
            });
            this.bodyRegion.show(this.currentView);
            this.listenTo(this.currentView, ObjectStorageEvents.azureConfigAdded, this.azureConfigAdded);
        },

        showStorageConfigView: function (type) {
            if (StorageConfigs.Types.AWS === type) {
                this.showAWSView();
            } else if (StorageConfigs.Types.Azure === type) {
                this.showAzureView();
            }
            this.listenTo(this.currentView, ObjectStorageEvents.closeAddStorage, this.closeAddStorageView);
        },
        typeSelected: function (type) {
            this.hideStorageSelectionView();
            this.showStorageConfigView(type);
        }

    });

});
 

define('helpers/contentViewFactory',[
    'views/layouts/overviewLayout',
    'views/layouts/activityLayout',
    'views/layouts/keysLayout',
    'views/layouts/storageLayout'
], function (OverviewLayout, ActivityLayout, KeysLayout, StorageLayout) {
    'use strict';
    return {
        createView: function (viewName, options) {
            var returnView = null;
            switch (viewName) {
            case 'overview':
                returnView = new OverviewLayout(options);
                break;
            case 'activity':
                returnView = new ActivityLayout(options);
                break;
            case 'storage':
                returnView = new StorageLayout(options);
                break;
            case 'keys':
                returnView = new KeysLayout(options);
                break;
            }
            
            if (returnView === null) {
                throw 'view: ' + viewName + ' not found';
            }
            
            return returnView;
        }
    };
});

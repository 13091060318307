
/* START_TEMPLATE */
define('hbs!templates/items/pageLoadIndicator',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  


  return "<div class=\"page-load-mask\">\n</div>\n<div class=\"page-load-indicator\">\n    <img src=\"resources/images/activity-large.gif\" alt=\"loading indicator\" height=\"56px\" \n         width=\"56px\" />\n</div>";
  });
Handlebars.registerPartial('templates/items/pageLoadIndicator', t);
return t;
});
/* END_TEMPLATE */
;


/* START_TEMPLATE */
define('hbs!templates/layouts/signupLayout',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"signup-banner ";
  if (helper = helpers.bannerClass) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.bannerClass); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n    <div class=\"signup-banner-layout\">\n        <div class=\"signup-banner-logo\"><img src=\"";
  if (helper = helpers.bannerImageUrl) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.bannerImageUrl); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"/></div>\n        <div class=\"signup-banner-text\">";
  if (helper = helpers.bannerText) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.bannerText); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n    </div>\n</div>\n<div class=\"signup-view\"/>";
  return buffer;
  });
Handlebars.registerPartial('templates/layouts/signupLayout', t);
return t;
});
/* END_TEMPLATE */
;


/* START_TEMPLATE */
define('hbs!frontend-common-components/gettingStartedPanel/templates/layouts/gettingStartedLayoutTmpl',['hbs','hbs/handlebars','frontend-common-components/gettingStartedPanel/js/helpers/handlebars/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"row\">\n    <div class=\"panel-content\">\n        <div class=\"getting-started-header\">\n            <span class=\"getting-started-title\">"
    + escapeExpression((helper = helpers['i18n-gettingStartedPanel'] || (depth0 && depth0['i18n-gettingStartedPanel']),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.panel.title", options) : helperMissing.call(depth0, "i18n-gettingStartedPanel", "gettingStarted.panel.title", options)))
    + "</span>\n            <a class=\"getting-started-close pull-right\"><i class=\"fa fa-times\"></i></a>\n        </div>\n        <div class=\"actions-regions-body\">\n        </div>\n    </div>\n</div>\n";
  return buffer;
  });
Handlebars.registerPartial('frontend-common-components/gettingStartedPanel/templates/layouts/gettingStartedLayoutTmpl', t);
return t;
});
/* END_TEMPLATE */
;

define('views/layouts/overviewLayout',[
    'views/base/baseLayoutView',
    'hbs!templates/layouts/overview',
    'i18n!nls/messages',
    'views/items/totalTransfersView',
    'views/items/periodTransfersView',
    'helpers/dateFormatHelper',
    'controllers/notificationController',
    'models/totalTransfers',
    'models/periodTransfers',
    'cocktail',
    'mixins/pageLoadIndicatorMixin'
], function (BaseLayoutView,
             OverviewLayoutTmpl,
             messageBundle,
             TotalTransfersView,
             PeriodTransfersView,
             dateFormatHelper,
             notificationController,
             TotalTransfers,
             PeriodTransfers,
             Cocktail,
             pageLoadIndicatorMixin) {
    'use strict';
    var OverviewLayout = BaseLayoutView.extend({
        template: OverviewLayoutTmpl,
        requests: 0,
        errors: 0,
        
        initialize: function () {
            this.requests = 0;
            this.errors = 0;
        },

        regions: {
            totalRegion: '#total-transfers',
            periodRegion: '#period-transfers'
        },

        onShow: function () {
            var totalTransfers = this.newTotalTransfersModel(),
                periodTransfers = this.newPeriodTransfersModel();
            
            this.listenTo(totalTransfers, 'before:fetch', this.onStartLoading, this);
            this.listenTo(totalTransfers, 'fetch:error', this.onFetchError, this);
            this.listenTo(totalTransfers, 'fetch:success', this.onFinishedLoading, this);
            
            this.listenTo(periodTransfers, 'before:fetch', this.onStartLoading, this);
            this.listenTo(periodTransfers, 'fetch:error', this.onFetchError, this);
            this.listenTo(periodTransfers, 'fetch:success', this.onFinishedLoading, this);
            
            this.showTotalRegion(totalTransfers);
            this.showPeriodRegion(periodTransfers);
        },

        newPeriodTransfersModel: function () {
            return new PeriodTransfers({
                to: dateFormatHelper.utc(true).getToday(),
                from:  dateFormatHelper.utc(true).getDaysFromToday(29)
            });
        },
        
        onStartLoading: function () {
            this.requests++;
            if (this.requests > 0) {
                this.showLoading();
            } else {
                this.hideLoading();
            }
        },
        
        onFinishedLoading: function () {
            this.requests--;
            if (this.requests > 0) {
                this.showLoading();
            } else {
                this.hideLoading();
                
                if (this.errors > 0) {
                    notificationController.showErrorNotification(messageBundle.overview.errorFetchingData);
                    this.errors = 0;
                }
            }
        },
        
        onFetchError: function () {
            this.errors++;
            this.onFinishedLoading();
        },
        
        showTotalRegion: function (totalModel) {
            this.totalRegion.show(new TotalTransfersView({
                model: totalModel
            }));
        },
        
        showPeriodRegion: function (periodModel) {
            this.periodRegion.show(new PeriodTransfersView({
                model: periodModel
            }));
        },
        
        newTotalTransfersModel: function () {
            return new TotalTransfers();
        },
    });
    
    Cocktail.mixin(OverviewLayout, pageLoadIndicatorMixin);
    
    return OverviewLayout;
});

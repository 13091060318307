
/* START_TEMPLATE */
define('hbs!templates/items/storageConfig',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"col-md-7 col-sm-7 col-xs-7 table-column truncate-ellipsis bucket-description\" title=\"";
  if (helper = helpers.bucketDescription) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.bucketDescription); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n	<span>";
  if (helper = helpers.bucketDescription) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.bucketDescription); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n	<span class=\"specifyBucket\">";
  if (helper = helpers.specifyBucketAtClient) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.specifyBucketAtClient); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</span>\n</div>\n<div class=\"col-md-4 col-sm-4 col-xs-4 table-column config-id\">";
  if (helper = helpers.configId) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.configId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n<div class=\"col-md-1 col-sm-1 col-xs-1 table-column cloud-storage-config-status\" >";
  if (helper = helpers.localizedStatus) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.localizedStatus); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n<div class=\"col-md-1 col-sm-1 col-xs-1 table-column storage-context-menu-column\">\n    <div class=\"overflow-container pull-right\">\n        <span class=\"overflow-button pull-right\" data-toggle=\"dropdown\"><i class=\"fa fa-ellipsis-v fa-lg\"></i></span>\n        <ul class=\"dropdown-menu\" role=\"menu\">\n        	<li role=\"presentation\">\n               <a role=\"menuitem\" tabindex=\"-1\" href=\"#\" class=\"storageConfig-action\">";
  if (helper = helpers.action) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.action); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</a>\n            </li>\n        </ul>\n    </div>\n</div>\n";
  return buffer;
  });
Handlebars.registerPartial('templates/items/storageConfig', t);
return t;
});
/* END_TEMPLATE */
;


/* START_TEMPLATE */
define('hbs!templates/layouts/overview',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"row\">\n    <div class=\"content-header-col col-md-12 col-sm-12 col-xs-12 col-md-offset-1 col-sm-offset-1 col-xs-offset-1\">\n        <h1 class=\"content-header\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "overview.pageHeader", options) : helperMissing.call(depth0, "i18n", "overview.pageHeader", options)))
    + "</h1>\n    </div>\n</div>\n<div class=\"row\">\n    <div class=\"row\">\n        <div class=\"sub-header col-md-12 col-md-offset-1\">\n            "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "overview.usage", options) : helperMissing.call(depth0, "i18n", "overview.usage", options)))
    + "\n        </div>\n    </div>\n    <div class=\"row usage-content\">\n        <div id=\"total-transfers\" class=\"col-md-4 col-md-offset-1\"></div>\n        <div id=\"period-transfers\" class=\"col-md-6\"></div>\n    </div>\n</div>\n";
  return buffer;
  });
Handlebars.registerPartial('templates/layouts/overview', t);
return t;
});
/* END_TEMPLATE */
;

define('views/layouts/mainLayout',[
    'views/base/baseLayoutView',
    'hbs!templates/layouts/main'
], function (BaseLayoutView, MainLayoutTmpl) {
    'use strict';
    return BaseLayoutView.extend({
        template: MainLayoutTmpl,

        initialize: function () {
        },

        regions: {
            'sideRegion': '#sideRegion',
            'contentRegion': '#contentRegion'
        }
    });
});

define('helpers/dateFormatHelper',[
    'moment'
], function (moment) {
	'use strict';

	var isUtc = false;
	
    return {
        DATE_FORMAT_TIME_STAMP: 'YYYY-MM-DDTHH:mm:ss.SSSZ',
        DAY_FORMAT_TIME_STAMP: 'YYYY-MM-DDT00:00:00.000[Z]',
        DATE_YEAR_MONTH_DAY: 'YYYY-MM-DD',
        DISPLAY_MONTH_DAY: 'MMM Do',
        
        utc: function (isUtcFormat) {
            isUtc = isUtcFormat;
            return this;
        },
        
		formattedDate: function (timestamp, format) {
            format = format || this.DISPLAY_MONTH_DAY;
            return this.date(timestamp).format(format);
        },
        
        timeFromNow: function (timestamp) {
			return this.date(timestamp).fromNow();
        },
        
        getToday: function () {
            return this.getMoment().format(this.DAY_FORMAT_TIME_STAMP);
        },
        
        getDaysFromToday: function (days) {
            return this.getMoment().subtract(days, 'days').format(this.DAY_FORMAT_TIME_STAMP);
        },
        
        date: function (timestamp, format) {
            format = format || this.DATE_FORMAT_TIME_STAMP;
            return (isUtc) ? moment.utc(timestamp, format) : moment(timestamp, format);
        },
        
        getMoment: function () {
            return (isUtc) ? moment.utc() : moment();
        }
    };
});
        

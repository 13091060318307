define('views/layouts/contentLayout',[
    'module',
    'environment',
    'views/base/baseLayoutView',
    'hbs!templates/layouts/content',
    'views/layouts/headerLayout',
    'controllers/mainLayoutController',
    'helpers/contentViewFactory'
], function (module, env, BaseLayoutView, ContentLayoutTmpl, HeaderLayout, MainLayoutController,
    contentViewFactory) {
    'use strict';
    return BaseLayoutView.extend({
        regions: {
            'headerRegion': '#headerRegion',
            'mainRegion': '#mainRegion'
        },
        template: ContentLayoutTmpl,
        onShow: function () {
            this.headerRegion.show(new HeaderLayout());
            this.initializeController();
        },
        initializeController: function () {
            var view = module.config().initialView;
            var apiKeys = [],
                storageConfigs = [];
            if (env.apiKeys) {
                apiKeys = JSON.parse(env.apiKeys);
            }
            if (env.storageConfigs) {
                storageConfigs = JSON.parse(env.storageConfigs);
            }
            if (apiKeys.length === 0) {
                view = 'keys';
            }
            
            var mainLayoutController = new MainLayoutController({
                region: this.mainRegion,
                sideNavItems: module.config().sideNavItems,
                initialView: view,
                contentViewFactory: contentViewFactory
            });
            mainLayoutController.show();
        }
    });
});

define('ieConsoleFix',[
    'helpers/window'
], function (window) {
    'use strict';
    if (!window.console) {
        window.console = {};
    }
    // union of Chrome, FF, IE, and Safari console methods
    var m = [
            'log', 'info', 'warn', 'error', 'debug', 'trace', 'dir', 'group',
            'groupCollapsed', 'groupEnd', 'time', 'timeEnd', 'profile', 'profileEnd',
            'dirxml', 'assert', 'count', 'markTimeline', 'timeStamp', 'clear'
        ],
        i,
        noop = function () {};
    // define undefined methods as noops to prevent errors
    for (i = 0; i < m.length; i++) {
        if (!window.console[m[i]]) {
            window.console[m[i]] = noop;
        }
    }
});

define('collections/storageConfigCollection',[
        'collections/base/baseCollection',
        'helpers/skydropContextHelper',
        'underscore',
        'models/storageConfig',
        'libs/awsDateToJSDate'
    ],
    function (BaseCollection, contextHelper, _, storageConfig, awsDateToJSDate) {
        'use strict';
        var compareDates = function (a, b) {
            var aDate = awsDateToJSDate(a.get('lastModifiedOn')),
                bDate = awsDateToJSDate(b.get('lastModifiedOn'));

            if (aDate < bDate) {
                return 1;
            }
            if (bDate < aDate) {
                return -1;
            }
            return 0;
        };

        return BaseCollection.extend({
            model: storageConfig,
            url: function () {
                return contextHelper.getStorageConfigURL();
            },
            comparator: function(a, b) {
            	 var aStatus = a.get('status');
                 var bStatus = b.get('status');
                 
                 if(aStatus < bStatus) {
                     return 1;
                 }

                 if(aStatus > bStatus) {
                     return -1;
                 }
                 
                 return compareDates(a, b);
            }
        });
    });

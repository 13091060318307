define('collections/sideNavItemCollection',[
    'collections/base/baseCollection'
], function (BaseCollection) {
    'use strict';

    return BaseCollection.extend({
        initialize: function () {
        }
    });
});


/* START_TEMPLATE */
define('hbs!templates/items/resendActivation',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"resend-activation-view\">\n    <div class=\"main-resend-view\">\n        <div class=\"skydrop-admin-panel-dialog-message info-box\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "resendActivation.intro", options) : helperMissing.call(depth0, "i18n", "resendActivation.intro", options)))
    + "</div>\n\n        <form id=\"resend-activation-form\" action=\"\" method=\"\" class=\"skydrop-admin-panel-form\">\n            <div id=\"resend-activation-btn-wrapper\" class=\"form-element-wrapper\">\n                <button type=\"submit\" class=\"btn btn-primary\" id=\"resend-btn\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "resendActivation.resendBtn", options) : helperMissing.call(depth0, "i18n", "resendActivation.resendBtn", options)))
    + "</button>\n            </div>\n        </form>\n    </div>\n    <div class=\"success-resend-view\">\n        <div class=\"skydrop-admin-panel-dialog-message info-box\">\n            "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "resendActivation.successSent", options) : helperMissing.call(depth0, "i18n", "resendActivation.successSent", options)))
    + "\n            <br/>\n            <span id=\"sentEmail\">Email</span>\n        </div>\n    </div>\n\n    <div class=\"skydrop-admin-panel-dialog-message\" id=\"error-msg-area\"/>\n</div>";
  return buffer;
  });
Handlebars.registerPartial('templates/items/resendActivation', t);
return t;
});
/* END_TEMPLATE */
;

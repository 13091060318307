define('views/items/forgotPasswordView',[
    'jquery',
    'i18n!nls/messages',
    'validation/sigValidator',
    'hbs!templates/items/forgotPassword',
    'views/base/baseItemView',
    'mixins/pageLoadIndicatorMixin',
    'models/forgotPasswordModel',
    'cocktail',
    'jquery.placeholder'
], function ($, messageBundle, validator, forgotPasswordLayoutTmpl, BaseItemView, pageLoadIndicatorMixin,
             ForgotPasswordModel, Cocktail) {
    'use strict';

    var ForgotPasswordView = BaseItemView.extend({
        template: forgotPasswordLayoutTmpl,
        
        initialize: function (options) {
            this.model = new ForgotPasswordModel();
            this.listenTo(this.model, 'save:error', this.handleSaveError, this);
            this.listenTo(this.model, 'save:success', this.handleSaveSuccess, this);
            
            this.error = options.error;
            this.info = options.info;
        },
        
        ui: {
            email: '#forgot-password-email',
            resetButtonWrapper: '#reset-password-btn-wrapper',
            emailFieldWrapper: '#forgot-password-email-wrapper',
            errorMessageArea: '.skydrop-admin-panel-dialog-message'
        },
        
        events: {
            'submit #forgot-password-form': 'handleFormSubmit'
        },
        
        handleFormSubmit: function () {
            if (this.isEmpty(this.ui.email)) {
                this.showError(messageBundle.forgotPassword.emptyEmailAddress, true);
                return false;
            } else {
                var emailValue = this.ui.email.val().toLowerCase();
                
                if (!validator.isSigEmail(emailValue)) {
                    this.showError(messageBundle.login.invalidEmailErrorMessage, true);
                    return false;
                }
                
                this.model.set('email', emailValue);
                this.showLoading();
                this.model.save();
            }
            
            return false;
        },
        
        handleSaveError: function (model, response) {
        	this.showError(messageBundle.forgotPassword.errorSendingEmail, true);
            this.hideLoading();
        },
        
        handleSaveSuccess: function () {
            this.ui.email.attr('disabled', 'disabled');
            this.ui.resetButtonWrapper.hide();
            this.showInfo(messageBundle.forgotPassword.emailSendSuccess);
            this.hideLoading();
        },
        
        showError: function (message, markEmailField) {
            
            if (markEmailField) {
                this.markErrorField(this.ui.emailFieldWrapper);
            }
            
            this.ui.errorMessageArea.addClass('text-error');
            this.ui.errorMessageArea.text(message);
        },
        
        showInfo: function (message) {
            this.ui.errorMessageArea.removeClass('text-error');
            this.clearErrorField(this.ui.emailFieldWrapper);
            this.ui.errorMessageArea.text(message);
        },
        
        showMessages: function () {
            if (this.error) {
                this.showError(messageBundle.forgotPassword[this.error], false);
            } else if (this.info) {
                this.showInfo(messageBundle.forgotPassword[this.info]);
            }
            
        },
        
        onRender: function () {
            this.showMessages();
        },
        
        onShow: function () {
            $('input[placeholder]').placeholder();
            if (!this.isIE()) {
                this.focusEmail();
            }
        },
        
        focusEmail: function () {
            this.ui.email.focus();
        }
    });

    Cocktail.mixin(ForgotPasswordView, pageLoadIndicatorMixin);
    
    return ForgotPasswordView;
});

define('views/layouts/addAzureStorageLayout',[
    'frontend-common-components/objectStorageConfig/js/views/layouts/addAzureStorageLayout',
    'controllers/notificationController',
    'mixins/pageLoadIndicatorMixin',
    'i18n!nls/messages',
    'cocktail'
],
function (
    BaseAddAzureStorageView,
    notificationController,
    pageLoadIndicatorMixin,
    messageBundle,
    Cocktail
) {
    'use strict';
    var AddAzureStorageLayout = BaseAddAzureStorageView.extend({
        onStorageContainerListLoading: function () {
            this.showLoading();
        },

        onStorageContainerListComplete: function () {
            this.hideLoading();
        },

        onStorageConfigSaving: function () {
            this.showLoading();
        },

        onStorageConfigSaveSuccess: function () {
            this.hideLoading();
        },

        onStorageConfigSaveFailure: function (response) {
            this.hideLoading();
            notificationController.showErrorNotification(messageBundle.global.unexpectedError);
        }
    });

    Cocktail.mixin(AddAzureStorageLayout, pageLoadIndicatorMixin);

    return AddAzureStorageLayout;
});

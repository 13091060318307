define('loginApp',[
	'jquery',
	'backbone',
    'marionette',
    'environment',
    'routers/loginRouter',
    'controllers/pageLoadIndicatorController',
    'views/items/pageLoadIndicatorView',
    'constants/events'
],
function ($, Backbone, Marionette, environment, LoginRouter, PageLoadIndicatorController,
          PageLoadIndicatorView, Events) {
    'use strict';
    var App = new Marionette.Application({
        regions: {
            footer: '.footer',
            root: '#wrap',
            pageLoadIndicator: '#page-load-indicator-container'
        }
    });

    App.addInitializer(function () {
        var router = new LoginRouter();
        router.app = App;
        
        Backbone.history.start();
        
        /*jshint unused:false */
        var pageLoadController = new PageLoadIndicatorController({
            region: this.pageLoadIndicator,
            view: PageLoadIndicatorView,
            radioChannel: Events.RadioChannels.pageLoad,
            showEvent: Events.PageLoadIndicator.show,
            hideEvent: Events.PageLoadIndicator.hide
        });
    });
    
    return App;
});


/* START_TEMPLATE */
define('hbs!templates/items/footerLinks',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n','libs/handlebars-helpers/env'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<ul class=\"list-unstyled\">\n    <li><a target=\"_blank\" href=\"https://getsatisfaction.com/signiant\" class=\"small\">\n        "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "footer.feedback", options) : helperMissing.call(depth0, "i18n", "footer.feedback", options)))
    + "\n    </a></li>\n    <li><a target=\"_blank\" href=\"http://www.signiant.com/privacy\" class=\"small\">\n        "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "footer.privacyPolicy", options) : helperMissing.call(depth0, "i18n", "footer.privacyPolicy", options)))
    + "\n    </a></li>\n    <li><a target=\"_blank\"\n           class=\"small\"\n           href=\"http://www.signiant.com/?utm_source=Skydrop&utm_medium=AdminConsole&utm_content=Footer_Link&utm_term=Homepage_Link&utm_campaign=Home_page_landings_from_product_links\">\n        &copy; ";
  stack1 = (helper = helpers.env || (depth0 && depth0.env),options={hash:{}},helper ? helper.call(depth0, "currentYear", options) : helperMissing.call(depth0, "env", "currentYear", options));
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "footer.signiantInc", options) : helperMissing.call(depth0, "i18n", "footer.signiantInc", options)))
    + "\n    </a></li>\n</ul>\n";
  return buffer;
  });
Handlebars.registerPartial('templates/items/footerLinks', t);
return t;
});
/* END_TEMPLATE */
;


/* START_TEMPLATE */
define('hbs!templates/layouts/header',['hbs','hbs/handlebars','libs/handlebars-helpers/env','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<nav class=\"navbar navbar-default navbar-static-top sig-navbar\" role=\"navigation\">\n    <div class=\"container-fluid\">\n        <div class=\"navbar-header\">\n            <div id=\"mainLogoRegion\" class=\"region navbar-brand header-logo\">\n        \n            </div>\n        </div>\n\n        <div class=\"collapse navbar-collapse\">\n            <ul class=\"nav navbar-nav navbar-right\">\n                <li class=\"dropdown\">\n                    <a class=\"dropdown-toggle\" type=\"button\" id=\"user-profile-menu\" data-toggle=\"dropdown\">\n                       	"
    + escapeExpression((helper = helpers.env || (depth0 && depth0.env),options={hash:{}},helper ? helper.call(depth0, "username", options) : helperMissing.call(depth0, "env", "username", options)))
    + "\n                        <span class=\"caret\"></span>\n                    </a>\n                    <ul class=\"dropdown-menu\" role=\"menu\" aria-labelledby=\"user-profile-menu\">\n                        <li role=\"presentation\"><a role=\"menuitem\" tabindex=\"-1\" href=\"https://flight.support.signiant.com/\" target=\"_blank\">\n                            "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "userProfileDropdown.support", options) : helperMissing.call(depth0, "i18n", "userProfileDropdown.support", options)))
    + "\n                        </a></li>\n                        <li id=\"getting-started-menu-item\" role=\"presentation\"><a role=\"menuitem\" tabindex=\"-1\" href=\"#\">\n                            "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "userProfileDropdown.gettingStarted", options) : helperMissing.call(depth0, "i18n", "userProfileDropdown.gettingStarted", options)))
    + "\n                        </a></li>\n                        <li role=\"presentation\"><a role=\"menuitem\" tabindex=\"-1\" href=\"logout\">\n                            "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "userProfileDropdown.signOut", options) : helperMissing.call(depth0, "i18n", "userProfileDropdown.signOut", options)))
    + "\n                        </a></li>\n                    </ul>\n                </li>\n            </ul>    \n        </div>\n    </div>\n\n</nav>\n";
  return buffer;
  });
Handlebars.registerPartial('templates/layouts/header', t);
return t;
});
/* END_TEMPLATE */
;

define('routers/loginRouter',[
    'marionette',
    'backbone',
    'environment',
    'views/layouts/loginLayout',
    'views/layouts/footerLayout',
    'views/items/loginView',
    'views/items/forgotPasswordView',
    'views/items/resetPasswordView',
    'views/items/activateAccountView',
    'views/items/activateAccountWithEmailView',
    'views/items/resendActivationView',
    'constants/events'
], function (Marionette, Backbone, env, LoginLayout, FooterLayout, LoginView,
             ForgotPasswordView, ResetPasswordView, ActivateAccountView, ActivateAccountWithEmailView,
             ResendActivationView, Events) {
    'use strict';

    return Marionette.AppRouter.extend({
        routes : {
            '' : 'login',
            'forgotPassword' : 'forgotPassword',
            'resetPassword/:authToken/:userId' : 'resetPassword',
            'activate/:authToken/:userId': 'activate',
            'activateResend/:userId': 'activateResend',
            'activateSuccess': 'activateSuccess'
        },
        
        initialize: function () {
            this.radio = Backbone.Wreqr.radio.channel(Events.RadioChannels.loginRouter);
            this.radio.commands.setHandler(Events.LoginRouter.route, this.routeEvent, this);
        },
        
        /**
         * ROUTES
         */
            
        login : function () {
            var loginView = new LoginView();
            this.showView(loginView);
        },

        forgotPassword : function (params) {
            if (!params) {
                params = {};
            }
            var forgotPasswordView = new ForgotPasswordView({
                error: params.error,
                info: params.info
            });
            this.showView(forgotPasswordView);
        },
        
        resetPassword: function (authToken, userId) {
            this.setGlobalUserId(userId);
            var resetPasswordView = new ResetPasswordView({
                authToken: authToken
            });
            this.showView(resetPasswordView);
        },

        activate: function (authToken, userId, params) {
            this.setGlobalUserId(userId);
            
            var viewToShow;
            if (params && params.userMustSetEmail === 'true') {
	            viewToShow = new ActivateAccountWithEmailView({
	                authToken: authToken,
	                userId: userId
	            });
            } else {
            	viewToShow = new ActivateAccountView({
	                authToken: authToken,
	                userId: userId
	            });
            }
            this.showView(viewToShow);
        },

        activateResend: function (userId) {
            this.setGlobalUserId(userId);
            var resendActivationView = new ResendActivationView();
            this.showView(resendActivationView);
        },

        activateSuccess: function () {
            // navigate page up one level
            window.location = window.location.pathname.split('/').slice(0, -1).join('/');
        },
        
        /**
         * HELPERS
         */
            
        showView: function (view) {
            this.app.root.show(new LoginLayout({
                view: view
            }));
            this.app.footer.show(new FooterLayout());
        },
        
        routeEvent: function (theUrl, error, info) {
            
            
            env.reason = {};
            env.reason.error = error;
            env.reason.info = info;
            
            this.navigate(theUrl, {trigger: true});
        },

        setGlobalUserId: function (userId) {
            env.userId = userId;
        }
    });
});

define('views/layouts/rootLayout',[
    'views/base/baseLayoutView',
    'hbs!templates/layouts/root',
    'views/layouts/contentLayout'
    
], function (BaseLayoutView, RootLayoutTmpl, ContentLayout) {
    'use strict';
    return BaseLayoutView.extend({
        template: RootLayoutTmpl,
        
        regions: {
            content: '.content'
        },

        initialize: function () {
        },
        
        onShow: function () {
            this.content.show(new ContentLayout());
        }
    });
});

define('helpers/totalTransfersTemplateHelper',[

], function () {
    'use strict';

    return {
        downloadArrowClass: function () {
            return (this.uploadedBytes < this.downloadedBytes) ? 'strong-arrow' : 'weak-arrow';
        },

        uploadArrowClass: function () {
            return (this.uploadedBytes >= this.downloadedBytes) ? 'strong-arrow' : 'weak-arrow';
        },
    };
});


define('views/layouts/headerLayout',[
    'backbone',
    'views/base/baseLayoutView',
    'hbs!templates/layouts/header',
    'views/items/mainLogoView',
    'constants/events'
], function (Backbone, BaseLayoutView, HeaderLayoutTmpl, MainLogoView, Events) {
    'use strict';
    return BaseLayoutView.extend({
        template: HeaderLayoutTmpl,
        
        ui: {
            gettingStartedMenuItem: '#getting-started-menu-item',
            gettingStartedLink: '#getting-started-menu-item a'
        },
        
        events: {
            'click @ui.gettingStartedLink': 'clickGettingStarted'
        },

        regions: {
            'logoRegion': '#mainLogoRegion',
            'userProfileRegion': '#userProfileRegion'
        },

        initialize: function () {
            this.radio = Backbone.Wreqr.radio.channel(Events.RadioChannels.gettingStarted);
        },
        
        onShow: function () {
            this.logoRegion.show(new MainLogoView());
            this.radio.commands.setHandler(Events.GettingStarted.closed, this.showGettingStarted, this);
        },

        showGettingStarted: function () {
            this.ui.gettingStartedMenuItem.show();
        },

        clickGettingStarted: function () {
            this.ui.gettingStartedMenuItem.hide();
            this.radio.commands.execute(Events.GettingStarted.opened);
        }
        
        
    });
});

define('nls/root/messages',{
    'footer': {
        'feedback': 'Feedback',
        'privacyPolicy': 'Privacy Policy',
        'signiantInc': 'Signiant Inc.'
    },
    'userProfileDropdown': {
        'help': 'Help',
        'whatsNew': 'What\'s new',
        'about': 'About Flight',
        'changePassword': 'Change password',
        'support': 'Support',
        'gettingStarted': 'Getting started',
        'signOut': 'Sign out'
    },
    'subNavItems': {
        'overview': 'Overview',
        'activity': 'Activity',
        'storage': 'Storage',
        'keys': 'Keys'
    },
    'signup': {
        'awsMarketplaceBannerText': 'You’re almost done. Complete the form and we’ll send you your Signiant login information.',
    	'namePlaceholderText': 'Enter your full name',
    	'emailPlaceholderText': 'Enter your email address',
    	'registerButtonText': 'Register',
    	'invalidNameErrorMessage': 'Enter a valid name',
    	'invalidEmailErrorMessage': 'Enter a valid email address',
    	'customerAccountAlreadyExists': 'An account already exists',
    	'unexpectedError': 'A problem occurred. Please try again'
    },
    'login': {
        'emailPlaceholderText': 'Email',
        'passwordPlaceholderText': 'Password',
        'signinButtonText': 'Sign in',
        'forgotPasswordLinkText': 'Forgot password?',
        'emptyEmailErrorMessage': 'Enter your email address',
        'emptyPasswordErrorMessage': 'Enter your password',
        'invalidEmailErrorMessage': 'Invalid email address',
        'com.signiant.AuthenticationException': 'Invalid email or password',
        'com.signiant.SessionExpiredException': 'Your web session has expired. Please login again.',
        'resetPasswordSuccess': 'Your password was successfully changed',
        'logout': 'You have signed out. Close your browser to ensure a complete sign out process.',
        'AuthenticationToken': 'You have been signed out. Close your browser to ensure a complete sign out process.',
        'emailInUse': 'Email is currently in use',
        'cannotEditEmail': 'Cannot edit email. Please look at activation link',
        'mustSetEmail': 'Must set your email. Please look at activation link',
        'signupSuccess': 'You have successfully registered. Check your email for a Flight account activation request.',
        'awsMarketplaceAccountAlreadySubscribed': 'Your Amazon account is already subscribed to Signiant Flight with the email address {{email}}.'
    },
    'forgotPassword': {
        'emailPlaceholderText': 'Enter your Flight account email',
        'requestPasswordResetText': 'Request Password Reset',
        'resetPasswordError': 'This reset password link has been used or has expired',
        'errorSendingEmail': 'A problem occurred. Please try again',
        'emptyEmailAddress': 'Enter the email address you used to register your Flight admin account',
        'emailSendSuccess': 'If an account is associated with this email, a password reset link will be sent.'
    },
    'resetPassword': {
        'resetPassword': 'Reset Password',
        'unauthorizedRequest': 'This reset password link has been used or has expired'
    },
    'changePassword': {
        'newPassword': 'New Password',
        'confirmPassword': 'Confirm Password',
        'passwordsDoNotMatch': 'Passwords do not match',
        'invalidPassword': 'Your password must contain at least 8 characters, include upper case and lower case characters and a number or symbol'
    },
    'activateAccount': {
        'newEmail': 'Type your email',
        'newPassword': 'Type your password',
        'confirmPassword': 'Confirm password',
        'activateBtn': 'Activate your account'
    },
    'resendActivation': {
        'intro': 'The activation email has expired',
        'resendBtn': 'Send a new activation email',
        'successSent': 'New activation sent to'
    },
    'overview': {
        'pageHeader': 'Overview',
        'usage': 'Usage',
        'usageDownloaded': 'downloaded',
        'usageUploaded': 'uploaded',
        'errorFetchingData': 'Something went wrong gathering your transfer information',
        'today': 'Today',
        'thirtyDaysAgo': '30 days ago'
    },
    'keys': {
        'pageHeader': 'Keys',
        'showRevokedCheckBox': 'Show Revoked',
        'add': '+ Add',
        'empty': 'Add a key...',
        'nameKey': 'Name the key',
        'clientSoftware': 'Client software',
        'createSecretKeyConfirmation' : 'All transfers will require a digital signature in order to work properly after adding a secret. Are you sure you want to create a secret for this API key?',
        'confirmationButton': 'Create Secret',
        'cancelButton': 'No',
        'table': {
            'nameHeader': 'Name',
            'credentialsHeader': 'Credentials',
            'statusHeader': 'Status',
            'keyLabel': 'key: ',
            'secretLabel': 'secret:',
            'generateSecretLabel': 'Generate a secret for use with the Javascript API'
        },
        'action': {
            'revoke': 'Revoke',
            'activate': 'Activate',
            'remove': 'Remove',
            'rename': 'Rename'
        },
        'status': {
            'revoked': 'Revoked',
            'active': 'Active'
        }
    },
    'storage': {
        'signiantFlightRole': 'SigniantFlightRole',
        'showRevokedCheckBox': 'Show Revoked',
        'pageHeader': 'Storage',
        'add': '+Add',
        'addLabel': 'Add',
        'empty': 'Add a storage config...',
        'addPanel': {
            'selectType': 'Select type:'
        },
        'table': {
            'bucketHeader': 'Cloud Storage',
            'configIdHeader': 'Config ID',
            'statusHeader' : 'Status'
        },
        'specifyConfiguration': {
            'AWS': '(bucket specified in client)',
            'AZURE': '(container specified in client)'
        },
        'action': {
            'revoke': 'Revoke',
            'activate': 'Activate'
        },
        'status': {
            'revoked': 'Revoked',
            'active': 'Active'
        }
    },
    'activity': {
        'pageHeader': 'Activity',
        'client': 'Client',
        'transferred': 'Transferred',
        'key': 'Key',
        'when': 'When',
        'nodata': 'Start an upload or download to see activity',
        'next': 'Next',
        'previous': 'Previous',
        'transferError': 'Transfer Error',
    	'canceled': 'Cancelled',
    	'successfulUpload': 'Successful Upload',
    	'successfulDownload': 'Successful Download'
    },
    'gettingStarted': {
        'titles': {
            'createAKey': 'Create a key',
            'transferAFile': 'Transfer a file'
        },
        'downloadClientText': 'Use sigcli to transfer your files',
        'downloadButton': 'Download',
        'downloadCliVersion': 'Windows 7 or greater (64-bit only) / Mac OS 10.8 or greater / 64-bit Linux',
        'commandLineUsage': 'Command line usage. For more details, see the ',
        'commandLineUsageLinkMessage': 'CLI guide.',
        'serverLocationHelpMessage': 'For the fastest transfers, specify the ',
        'serverLocationLinkMessage': 'server closest to your bucket.',
        'usEastFlightServer': 'us-east-1-am.cloud.signiant.com',
        'addStorageLink': 'add storage',
        'useTempCredentials': ' to use temporary credentials',
        'or': 'or',
        'replaceCredentials': 'To transfer to your own bucket, replace access-key, secret-key and bucket with your AWS credentials,',
    	'transfersToPublicBucket': 'Transfers will be made to our public bucket. Do not transfer confidential information.',
    	'flightCliGuide': 'https://flight.support.signiant.com/customer/en/portal/articles/1986820-signiant-flight-cli-guide',
    	'flightServerLocations': 'https://flight.support.signiant.com/customer/en/portal/articles/2173685-signiant-flight-server-locations',
    	'trialS3Bucket': '--bucket try-signiant-flight'
    },
    'global': {
        'unexpectedError': 'A problem occurred. Reload your browser and try again'
    }
});

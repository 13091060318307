define('constants/gettingStarted',[
    'i18n!nls/messages'
], function (messages) {
    'use strict';

    return {
        Titles: {
            createAKey: messages.gettingStarted.titles.createAKey,
            transfer: messages.gettingStarted.titles.transferAFile
        }
    };
});

define('models/sideNavItem',[
    'models/base/baseModel'
], function (BaseModel) {
    'use strict';
    return BaseModel.extend({
        initialize: function () {
            
        },

        defaults: {
            name: 'name'
        }
    });
});

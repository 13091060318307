
/* START_TEMPLATE */
define('hbs!templates/layouts/main',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  


  return "<div class=\"row\">\n    <div id=\"sideRegion\" class=\"region col-md-2\"></div>\n    <div id=\"contentRegion\" class=\"region container-fluid col-md-11 col-sm-10 col-xs-9\"></div>\n</div>";
  });
Handlebars.registerPartial('templates/layouts/main', t);
return t;
});
/* END_TEMPLATE */
;

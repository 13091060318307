define('helpers/humanizeUnits',[
    'module',
    'quantities',
    'underscore',
    'constants/units'
], function (module, Qty, _, units) {
    'use strict';
    
    var FILE_SIZE_ORDER = [
            units.fileSize.B,
            units.fileSize.KiB,
            units.fileSize.MiB,
            units.fileSize.GiB,
            units.fileSize.TiB,
            units.fileSize.PiB
        ],
        getNextUnit = _.memoize(function (unit) {
            var result = null,
                i;
            for (i = 0; i < FILE_SIZE_ORDER.length; i++)  {
                if (unit === FILE_SIZE_ORDER[i] &&
                    _.isUndefined(FILE_SIZE_ORDER[i + 1]) === false) {
                    return FILE_SIZE_ORDER[i + 1];
                }
            }
            return result;
        });
    
    function buildQty(value) {
        return new Qty(value.value + value.unit);
    }
    function fromQty(qty) {
        return {
            value: qty.scalar,
            unit: qty.units()
        };
    }
    function scaleUp(qty) {
        var nextUnit = getNextUnit(qty.units());
        if (nextUnit !== null) {
            qty = qty.to(nextUnit);
        }
        return qty;
    }
    function upConvert(qty, threshold) {
        var newQty = qty;
        if (qty.scalar >= threshold) {
            newQty = scaleUp(qty);
            if (newQty.same(qty) === false) {
                newQty = upConvert(newQty, threshold);
            }
        }
        return newQty;
    }
    function roundToMaxCharacters(qty, maxCharacterCount) {
        var scalarStr = qty.scalar.toString(),
            decimal = scalarStr.indexOf('.'),
            result = qty.scalar;
        
        if (decimal !== -1) {
            if (decimal >= maxCharacterCount) {
                result = parseFloat(scalarStr.substr(0, decimal));
            } else {
                result = parseFloat(result.toFixed(maxCharacterCount - decimal));
            }
        }
        
        qty = buildQty({
            value: result,
            unit: qty.units()
        });
        return qty;
    }
    function formatQty(qty, options) {
        if (_.isUndefined(options.threshold) === false) {
            qty = upConvert(qty, Math.pow(10, options.threshold));
        }
        
        var unitOptions = options,
            units = qty.units();
        
        if (_.isUndefined(options[units]) === false) {
            //unit specific settings
            unitOptions = _.extend({}, options, options[units]);
        }

        if (_.isUndefined(unitOptions.precision) === false) {
            qty = qty.toPrec(unitOptions.precision);
        }

        if (_.isUndefined(unitOptions.maxCharacterCount) === false) {
            qty = roundToMaxCharacters(qty, unitOptions.maxCharacterCount);
        }
        return qty;
    }
    function buildFileSizeResult(value, options) {
        if (_.isNumber(value)) {
            value = {
                value: value,
                unit: units.fileSize.B
            };
        }
        
        if (_.isUndefined(options.minUnit)) {
            options.minUnit = units.fileSize.B;
        }

        var qty = buildQty(value);
        
        //convert to minimum unit
        qty = qty.to(options.minUnit);
        
        //format nicely
        qty = formatQty(qty, options);

        return fromQty(qty);
    }
    function getMaxUnit(ar) {
        var maxUnit = FILE_SIZE_ORDER[0],
            curUnit,
            i;
        for (i = 0; i < ar.length; i++) {
            curUnit = ar[i].unit;
            if (new Qty(curUnit).gt(new Qty(maxUnit))) {
                maxUnit = curUnit;
            }
        }
        return maxUnit;
    }
    function convertAllTo(ar, maxUnit, options) {
        var results = [],
            i,
            qty;
        for (i = 0; i < ar.length; i++) {
            qty = buildQty(ar[i]);
            qty = qty.to(maxUnit);
            qty = formatQty(qty, options);
            results.push(fromQty(qty));
            
        }
        return results;
    }

    return {
        fileSize: function (value, options) {
            if (_.isUndefined(options)) {
                options = {};
            }
            //add default settings from module
            options = _.extend({}, module.config(), options);
            
            if (_.isArray(value)) {
                var results = [],
                    i,
                    maxUnit;
                for (i = 0; i < value.length; i++) {
                    results.push(buildFileSizeResult(value[i], options));
                }
                if (_.isUndefined(options.uniformUnits) === false &&
                    options.uniformUnits === true) {
                    //must convert all to the max unit
                    maxUnit = getMaxUnit(results);
                    results = convertAllTo(results, maxUnit, options);
                }
                
                return results;
            } else {
                return buildFileSizeResult(value, options);
            }
        }
    };
});

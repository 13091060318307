define('views/items/apiKeyView',[
    'views/base/baseItemView',
    'views/items/generateSecretKeyDialogView',
    'constants/apiKeyStatus',
    'controllers/notificationController',
    'controllers/apiKeySecretController',
    'frontend-common-components/modalController/modalController',
    'hbs!templates/items/apiKey',
    'i18n!nls/messages',
    'mixins/pageLoadIndicatorMixin',
    'cocktail',
    'underscore',
    'jquery',
    'jquery.jeditable.maxlength'
], function (
    BaseItemView,
    GenerateSecretKeyDialogView,
    ApiKeyStatus,
    notificationController,
    apiKeySecretController,
    ModalController,
    apiKeyTmpl,
    messageBundle,
    pageLoadIndicatorMixin,
    Cocktail,
    _,
    $
) {
    'use strict';

    var ApiKeyView =  BaseItemView.extend({
        tagName: 'div',
        className: 'row highlightable-row',
        template: apiKeyTmpl,
        
        ui: {
            overflow: '.overflow-button',
            dropdown: '.dropdown-menu',
            name: '#name',
            status: '#status',
            generateSecret: '.generate-secret',
            secret: '.credentials .secret'
        },
        
        initialize: function () {
            this.listenTo(this.model, 'newlyAdded', this.selectNameField);
            this.generateSecretKeyDialogSelector = '#modal';
            this.modalController = new ModalController({selector: this.generateSecretKeyDialogSelector});
        },
        
        events: {
            'mouseover': 'handleMouseOver',
            'mouseout': 'handleMouseOut',
            'mouseover .overflow-button': 'handleMouseOverOverflow',
            'mouseout .overflow-button': 'handleMouseOutOverflow',
            'shown.bs.dropdown' : 'handleDropdownOpen',
            'hidden.bs.dropdown' : 'handleDropdownClose',
            'click .apiKey-action': 'handleKeyAction',
            'click .apiKey-rename': 'selectNameField',
            'click @ui.generateSecret': 'confirmCreateSecretKey'
        },
        
        nameSubmit: function (value) {
            
            if (this.model.get('name') !== value && !(_.isEmpty(this.model.get('name')) && _.isEmpty(value))) {
                this.model.set('name', value);
                this.updateKey();
            }

            return value;
        },

        templateHelpers: {
            action: function () {
                if (this.status === ApiKeyStatus.active) {
                    return messageBundle.keys.action.revoke;
                } else if (this.status === ApiKeyStatus.revoked) {
                    return messageBundle.keys.action.activate;
                }
                
                return '';
            },
            
            localizedStatus: function () {
                if (this.status === ApiKeyStatus.active) {
                    return messageBundle.keys.status.active;
                } else if (this.status === ApiKeyStatus.revoked) {
                    return messageBundle.keys.status.revoked;
                }
            },
            
            nameFieldEmpty: function () {
                return _.isEmpty(this.name);
            }
        },
        
        confirmCreateSecretKey: function () {
			this.modalController.showModal(new GenerateSecretKeyDialogView({
                callback: this.generateApiKeySecret.bind(this)
            }));
        },

        generateApiKeySecret: function () {
            this.showLoading();
            return apiKeySecretController.generateSecret(this.model.get('id'))
                .done(_.bind(function (updatedApiKey) {
                    this.model.set(updatedApiKey);
                    this.render();
                }, this))
                .fail(_.bind(function (response) {
                    
                    notificationController.showErrorNotification(messageBundle.global.unexpectedError);
                }, this))
                .always(_.bind(function () {
                    this.hideLoading();
                }, this));
        },

        updateKey: function () {
            this.render();
            this.model.save(null, null, {
                url: this.model.updateUrl(),
                error: _.bind(this.updateKeyFailure, this)
            });
        },
        
        handleKeyAction: function () {
            this.handleDropdownClose();
            if (this.model.get('status') === ApiKeyStatus.active) {
                this.model.set('status', ApiKeyStatus.revoked);
            } else if (this.model.get('status') === ApiKeyStatus.revoked) {
                this.model.set('status', ApiKeyStatus.active);
            }
            
            this.updateKey();
        },
        
        handleDropdownOpen: function () {
            this.$el.addClass('active');
            this.ui.overflow.addClass('visible');
            this.ui.overflow.addClass('hover');
            this.ui.overflow.addClass('active');
        },
        
        handleDropdownClose: function () {
            this.$el.removeClass('active');
            this.$el.removeClass('hover');
            this.ui.overflow.removeClass('visible');
            this.ui.overflow.removeClass('hover');
            this.ui.overflow.removeClass('active');
        },
        
        handleMouseOver: function () {
            if (this.$el.hasClass('active')) {
                return;
            }
            this.$el.addClass('hover');
            this.ui.overflow.addClass('visible');
        },
        
        handleMouseOut: function () {
            if (this.$el.hasClass('active')) {
                return;
            }
            
            this.$el.removeClass('hover');
            this.ui.overflow.removeClass('visible');
        },
        
        handleMouseOverOverflow: function () {
            if (this.$el.hasClass('active')) {
                return;
            }
            
            this.ui.overflow.addClass('hover');
        },
        
        handleMouseOutOverflow: function () {
            if (this.$el.hasClass('active')) {
                return;
            }

            this.ui.overflow.removeClass('hover');
        },
        
        selectNameField: function () {
            this.ui.name.trigger('click');
        },
        
        updateKeyFailure: function (model, response) {
            
            notificationController.showErrorNotification(messageBundle.global.unexpectedError);
        },
        
        onRender: function () {
            this.ui.name.editable(_.bind(this.nameSubmit, this),
            {
                type : 'text-max-length',
                onblur: function (val, settings) {
                    //Need to cleanup the views as in jeditable, since we are circumventing all onblur logic
                    var str = settings.target.apply(this, [val, settings]);
                    $(this).html(str);
                    this.editing = false;
                    if (!$.trim($(this).html())) {
                        $(this).html(settings.placeholder);
                    }
                },
                maxlength: '100',
                height: 20,
                placeholder: messageBundle.keys.nameKey
            });
        },
        
        onBeforeDestroy: function () {
            this.ui.name.editable('destroy');
        }
    });

    Cocktail.mixin(ApiKeyView, pageLoadIndicatorMixin);

    return ApiKeyView;
});

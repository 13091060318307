define('controllers/storageConfigController',[
    'marionette',
    'collections/storageConfigCollection',
    'models/storageConfig',
    'underscore'
], function (Marionette, StorageConfigCollection, StorageConfig, _) {
    'use strict';

    var StorageConfigController = Marionette.Object.extend({
        initialize: function () {
            this.storageConfigCollection = new StorageConfigCollection();
        },

        setStorageConfigCollection: function (storageConfigCollection) {
            this.storageConfigCollection.set(storageConfigCollection.models);
        },

        setStorageConfigCollectionFromJSON: function (storageConfigCollectionJSON) {
            var models = _.map(JSON.parse(storageConfigCollectionJSON), function (model) {
                return new StorageConfig(model);
            });
            this.storageConfigCollection.set(models, { silent: true });
        },

        getStorageConfigCollection: function () {
            return this.storageConfigCollection;
        }

    });
    return new StorageConfigController();

});

define('helpers/signupTemplateHelper',[
    'i18n!nls/messages'
], function (messageBundle) {
    
    // AWS Marketplace is currently the only signup page source, so return template values for it only.
    
    return {
        bannerClass: 'aws-marketplace-banner',
        bannerImageUrl: 'resources/images/aws-marketplace-logo.png',
        bannerText: messageBundle.signup.awsMarketplaceBannerText
    };    
});

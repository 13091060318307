define('views/items/storageConfigView',[
    'views/base/baseItemView',
    'constants/storageConfigStatus',
    'controllers/notificationController',
    'frontend-common-components/objectStorageConfig/js/constants/storageConfigs',
    'hbs!templates/items/storageConfig',
    'i18n!nls/messages',
    'underscore',
    'i18n!frontend-common-components/objectStorageConfig/nls/objectStorageConfig',
], function (
    BaseItemView,
    StorageConfigStatus,
    notificationController,
    StorageConfigConstants,
    storageConfigTmpl,
    messageBundle,
    _,
    objectStorageMessageBundle
) {
    'use strict';
    
    var getBucketName = function (storageType, storageConfig) {
        if (storageType === StorageConfigConstants.Types.AWS) {
            return storageConfig.configuration.bucket;
        } else if (storageType === StorageConfigConstants.Types.Azure) {
            return storageConfig.configuration.container;
        }
    };
    
    var getBucketSpecifyConfigurationText = function (storageType, storageConfig) {
        if (storageType === StorageConfigConstants.Types.AWS) {
            return messageBundle.storage.specifyConfiguration.AWS;
        } else if (storageType === StorageConfigConstants.Types.Azure) {
            return messageBundle.storage.specifyConfiguration.AZURE;
        }
    };
    
    var BUCKET_NAME_SEPERATOR = ' > ';
    var FORWARD_SLASH = '/';

    return BaseItemView.extend({
        tagName: 'div',
        className: 'row highlightable-row',
        template: storageConfigTmpl,

        ui: {
            overflow: '.overflow-button',
            dropdown: '.dropdown-menu',
            status: '.cloud-storage-config-status'
        },

        initialize: function () {
        },

        events: {
            'mouseover': 'handleMouseOver',
            'mouseout': 'handleMouseOut',
            'mouseover .overflow-button': 'handleMouseOverOverflow',
            'mouseout .overflow-button': 'handleMouseOutOverflow',
            'shown.bs.dropdown' : 'handleDropdownOpen',
            'hidden.bs.dropdown' : 'handleDropdownClose',
            'click .storageConfig-action': 'handleStorageConfigAction',
        },

        templateHelpers: {
            bucketDescription: function () {
                var storageType = this.configuration.storageType,
                bucketName = getBucketName(storageType, this),
                diretoryPrefix = this.configuration.filePrefix,
                result = objectStorageMessageBundle.storage.services[storageType];
           
                if (_.isString(bucketName) && bucketName.length > 0) {
                    result = result + BUCKET_NAME_SEPERATOR + bucketName ;
                    
                    if(_.isString(diretoryPrefix) && diretoryPrefix.length > 0) {
                    	result = result + FORWARD_SLASH + diretoryPrefix;
                    }
                }
                
                return result;
            },
            
            specifyBucketAtClient: function () {
            	var storageType = this.configuration.storageType,
                bucketName = getBucketName(storageType, this);
            	if (!(_.isString(bucketName) && bucketName.length > 0)) {
            		return getBucketSpecifyConfigurationText(storageType, this);
            	}
            	return "";
            },
            
            localizedStatus: function () {
            	var message = '';
                if (this.status === StorageConfigStatus.active) {
                    message = messageBundle.storage.status.active;
                } else if (this.status === StorageConfigStatus.revoked) {
                    message = messageBundle.storage.status.revoked;
                }
                return message;
            },
            
            action: function () {
           		var message = '';
                if (this.status === StorageConfigStatus.active) {
                	message = messageBundle.storage.action.revoke;
                } else if (this.status === StorageConfigStatus.revoked) {
                	message = messageBundle.storage.action.activate;
                } 
				return message;
            }
        },
        
        handleStorageConfigAction: function () {
        	var model = this.model;
            this.handleDropdownClose();
            if (model.get('status') === StorageConfigStatus.active) {
                model.set('status', StorageConfigStatus.revoked);
            } else if (model.get('status') === StorageConfigStatus.revoked) {
                model.set('status', StorageConfigStatus.active);
            }
            this.updateStorageConfig();
        },
        
        updateStorageConfig: function () {
        	this.render();
            this.model.save(null, null, {
         	   	url: this.model.updateUrl(),
                error: _.bind(this.updateStorageConfigFailure, this)
            });
        },
          
        updateStorageConfigFailure: function (model, response) {
        	
            notificationController.showErrorNotification(messageBundle.global.unexpectedError);
        },
        
        handleDropdownOpen: function () {
            this.$el.addClass('active');
            this.ui.overflow.addClass('visible');
            this.ui.overflow.addClass('hover');
            this.ui.overflow.addClass('active');
        },

        handleDropdownClose: function () {
            this.$el.removeClass('active');
            this.$el.removeClass('hover');
            this.ui.overflow.removeClass('visible');
            this.ui.overflow.removeClass('hover');
            this.ui.overflow.removeClass('active');
        },
        

        handleMouseOver: function () {
            if (this.$el.hasClass('active')) {
                return;
            }
            this.$el.addClass('hover');
            this.ui.overflow.addClass('visible');
        },

        handleMouseOut: function () {
            if (this.$el.hasClass('active')) {
                return;
            }

            this.$el.removeClass('hover');
            this.ui.overflow.removeClass('visible');
        },

        handleMouseOverOverflow: function () {
            if (this.$el.hasClass('active')) {
                return;
            }

            this.ui.overflow.addClass('hover');
        },

        handleMouseOutOverflow: function () {
            if (this.$el.hasClass('active')) {
                return;
            }

            this.ui.overflow.removeClass('hover');
        },
        
        onRender: function () {
        }
    });
});

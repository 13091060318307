
/* START_TEMPLATE */
define('hbs!templates/items/activateAccountWithEmail',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<form id=\"reset-password-form\" action=\"\" method=\"\" class=\"skydrop-admin-panel-form\">\n	<div id=\"new-email-field-wrapper\" class=\"form-element-wrapper has-feedback\">\n		<input id=\"new-email-field\" class=\"form-control\" type=\"field\" placeholder=\""
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "activateAccount.newEmail", options) : helperMissing.call(depth0, "i18n", "activateAccount.newEmail", options)))
    + "\"/>\n		<span class=\"fa fa-exclamation form-control-feedback\"/>\n	</div>\n	<div class=\"message-text\" id=\"email-error-message-area\"/>\n	\n    <div id=\"new-password-field-wrapper\" class=\"form-element-wrapper has-feedback\">\n        <input id=\"new-password-field\" class=\"form-control\" type=\"password\" name=\"new-password\" value=\"\" placeholder=\""
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "activateAccount.newPassword", options) : helperMissing.call(depth0, "i18n", "activateAccount.newPassword", options)))
    + "\" autocomplete=\"off\" disabled/>\n        <span class=\"fa fa-exclamation form-control-feedback\"/>\n    </div>\n\n    <div id=\"confirm-password-field-wrapper\" class=\"form-element-wrapper has-feedback\">\n        <input id=\"confirm-password-field\" class=\"form-control\" type=\"password\" name=\"confirm-password\" value=\"\" placeholder=\""
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "activateAccount.confirmPassword", options) : helperMissing.call(depth0, "i18n", "activateAccount.confirmPassword", options)))
    + "\" autocomplete=\"off\" disabled/>\n        <span class=\"fa fa-exclamation form-control-feedback\"/>\n    </div>\n\n    <div id=\"reset-password-btn-wrapper\" class=\"form-element-wrapper\">\n        <button type=\"submit\" class=\"btn btn-primary disabled\" id=\"activate-btn\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "activateAccount.activateBtn", options) : helperMissing.call(depth0, "i18n", "activateAccount.activateBtn", options)))
    + "</button>\n    </div>\n</form>\n\n<div class=\"skydrop-admin-panel-dialog-message\" id=\"error-msg-area\"/>";
  return buffer;
  });
Handlebars.registerPartial('templates/items/activateAccountWithEmail', t);
return t;
});
/* END_TEMPLATE */
;

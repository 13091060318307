define('helpers/tooltip',[
    'hbs!templates/items/periodicTransferGraphTooltip',
    'helpers/humanizeUnits',
    'helpers/dateFormatHelper',
    'constants/units',
    'd3',
    'd3tip'
], function (tooltipTmpl,
             humanizeUnits,
             dateFormatHelper,
             units,
             d3,
             d3tooltip) {
    'use strict';
    
    return function (opt) {
        opt = opt || {};
        var svg = opt.svg || {};
        var className = opt.className || 'd3-tip';
        var followMouse = opt.followMouse || false;
        
        if (followMouse) {
            var tooltip = d3.select('body')
                .append('div')
                .attr('class', className)
                .style({
                    position: 'absolute',
                    visibility: 'hidden',
                    'z-index': '10'
                });
            
            return {
                show: function (d) {
                    // Convert the units to the base 1000 units for display purposes
                    var download = humanizeUnits.fileSize(d.downloadedBytes);
                    download.unit = units.convertToBase1000Units(download.unit);

                    var upload = humanizeUnits.fileSize(d.uploadedBytes);
                    upload.unit = units.convertToBase1000Units(upload.unit);

                    var date = dateFormatHelper.utc(true).formattedDate(d.startDate);
                
                    var content = tooltipTmpl({
                        upload: upload,
                        download: download,
                        date: date
                    });
                    
                    // Set the content before measuring the size
                    tooltip.html(content);
                    var top = d3.event.pageY - tooltip[0][0].offsetHeight - 10;
                    var left = d3.event.pageX - (tooltip[0][0].offsetWidth /  2);
                    
                    tooltip.style({
                        top: top + 'px',
                        left: left + 'px',
                        visibility: 'visible',
                        opacity: '1',
                    });
                },
                hide: function () {
                    tooltip.style({
                        visibility: 'hidden',
                        opacity: '0',
                    });
                }
            };
        } else {
            var tip = d3tooltip()
                .attr('class', className)
                .offset(function () {
                    var a = this.getScreenCTM().translate(this.getBBox().x, this.getBBox().y).f;
                    var b = this.getScreenCTM().translate(this.getBBox().x, 0).f;
                    return [(b - a) - 9, 0];
                })
                .html(function (d) {
                    // Convert the units to the base 1000 units for display purposes
                    var download = humanizeUnits.fileSize(d.downloadedBytes);
                    download.unit = units.convertToBase1000Units(download.unit);

                    var upload = humanizeUnits.fileSize(d.uploadedBytes);
                    upload.unit = units.convertToBase1000Units(upload.unit);

                    var date = dateFormatHelper.utc(true).formattedDate(d.startDate);
                
                    return tooltipTmpl({
                        upload: upload,
                        download: download,
                        date: date
                    });
                });
            svg.call(tip);
                
            return tip;
        }
    };
});

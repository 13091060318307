define('validation/emailValidation',[
    'validator'
], function (validator) {
    'use strict';
    return function (email, delim) {
        /*jshint -W101*/
        var regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        /*jshint +W101*/

        if (typeof delim === 'string') {
            var emails = email.split(delim);
            for (var i = 0; i < emails.length; i++) {
                emails[i] = validator.trim(emails[i]);

                if (!regex.test(emails[i])) {
                    return false;
                }
            }
            return true;
        }
        //if there is no delimiter, we still need to trim the email
        return regex.test(validator.trim(email));
    };
});

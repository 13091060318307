define('views/items/transferFileHelpDialogView',[
        'views/base/baseItemView',
        'hbs!templates/items/transferFileDialogViewTmpl',
        'constants/events',
        'constants/storageConfigs',
        'environment',
        'i18n!nls/messages',
        'jquery',
        'underscore',
], function (BaseItemView, transferFileDialogViewTmpl, Events, storageConfigs, env, messageBundle, $, _) {
    'use strict';

    return BaseItemView.extend({
        tagName: 'div',
        className: 'row',
        template: transferFileDialogViewTmpl,

        ui: {
            transferHelpClose: '.transfer-help-close-btn',
            addStorageLink:	'#add-storage-link' 
        },

        initialize: function (options) {
            options = options || {};
            if (options.selector) {
                this.selector = options.selector;
            }
        },

        events: {
            'click @ui.downloadCli': 'handleClientDownload',
            'click @ui.transferHelpClose': 'handleCloseDialog',
            'click @ui.addStorageLink': 'handleAddStorageClicked'
        },

        templateHelpers: {
            secretExists: function () {
                if (this.secret) {
                    return true;
                } else {
                    return false;
                }
            },
        
        	hasStorageAdded : function() {
        		return !_.isUndefined(this.configId);
        	},
        	
        	flightTrialAWSAccessKeyId: function() {
        		return env.flightTrialAWSAccessKeyId;
        	},
        	
        	flightTrialAWSSecretAccessKey: function() {
        		return env.flightTrialAWSSecretAccessKey;
        	}
        },

        handleCloseDialog: function () {
            $(this.selector).modal('hide');
            this.triggerMethod(Events.GettingStarted.cliModalClosed);
        },
        
        handleAddStorageClicked: function() {
        	this.handleCloseDialog();
        	Backbone.Wreqr.radio.commands.execute(Events.RadioChannels.gettingStarted, Events.TransferFileHelp.addStorageClicked);
        }
        
    });
});


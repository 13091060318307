define('views/composite/historicalTransfersView',[
    'views/base/baseCompositeView',
    'hbs!templates/composite/historicalTransfers',
    'underscore',
    'helpers/activityTemplateHelper',
    'views/items/historicalTransferView',
], function (BaseCompositeView, HistoricalTransfersTmpl,
        _, activityTemplateHelper, HistoricalTransferView) {
    
    'use strict';
    return BaseCompositeView.extend({
        template: HistoricalTransfersTmpl,
        templateHelpers: activityTemplateHelper,
        childView: HistoricalTransferView,
        
        serializeData: function () {
            if (_.isUndefined(this.collection) === false) {
                return this.collection.toJSON();
            }
            return {};
        }
    
    });
});

define('helpers/storageHelper',[
    'underscore'
], function (
    _
    ) {
    'use strict';
    var FilePrefixRegex = /^[0-9a-zA-Z!*.'()/_-]+$/;
    var DEFAULT_ACCEPTABLE_PREFIX_LENGTH = 100;
    var FilePrefixWithSlashRegex = /^[0-9a-zA-Z!*.'()\/_-]+\/$/;

    return {
        isValidFilePrefix: function (filePrefix) {
            if (_.isEmpty(filePrefix)) {
                return true;
            }
            return FilePrefixRegex.test(filePrefix);
        },

        isStoragePrefixLengthAcceptable: function (directoryPrefix, ACTUAL_ACCEPTABLE_PREFIX_LENGTH) {
            var acceptableLength = DEFAULT_ACCEPTABLE_PREFIX_LENGTH;
            if (ACTUAL_ACCEPTABLE_PREFIX_LENGTH) {
                acceptableLength = ACTUAL_ACCEPTABLE_PREFIX_LENGTH;
            }
            return directoryPrefix.length <= acceptableLength;
        },

        storagePrefixEndsWithSlash: function (filePrefix) {
            if (_.isEmpty(filePrefix)) {
                return false;
            }
            return FilePrefixWithSlashRegex.test(filePrefix);
        }
    };

});


define('controllers/mainLayoutController',[
    'marionette',
    'views/layouts/mainLayout',
    'views/layouts/sideLayout',
    'controllers/gettingStartedController',
    'views/composite/sideNavCompositeView',
    'collections/sideNavItemCollection',
    'models/sideNavItem',
    'constants/events',
    'underscore',
    'cocktail',
    'mixins/validation/requiredParametersMixin',
], function (Marionette, MainLayout, SideLayout, gettingStartedController,
             SideNavView, SideNavItemCollection,
             SideNavItem, Events, _, Cocktail, RequiredParametersMixin) {
    'use strict';
    
    var MainLayoutController = Marionette.Controller.extend({
        expectedParameters: {
            'region': Marionette.Region,
            'sideNavItems': 'Array',
            'initialView': 'String',
            'contentViewFactory': 'Object'
        },
        
        initialize: function (options) {
            this.validateExpectedParameters(options);
            this.region = options.region;
            this.sideNavItems = options.sideNavItems;
            this.initialView = options.initialView;
            this.contentViewFactory = options.contentViewFactory;
            
        },
        
        show: function () {
            var layout = this.getLayout(this.getSideNavItems());
            this.region.show(layout);
        },
        
        getLayout: function () {
            var layout = new MainLayout();
            this.listenTo(layout, 'render', function () {
                this.showMenuAndContent(layout);
            });
            
            return layout;
        },
        
        getSideNavItems: function () {
            return this.sideNavItems;
        },
        
        getInitialView: function () {
            return this.initialView;
        },
        
        getContentViewFactory: function () {
            return this.contentViewFactory;
        },
        
        showMenuAndContent: function (layout) {
            var sideNavItems = this.getSideNavItems(),
                initialView = this.getInitialView(),
                sideLayout = this.addSideLayout(layout.sideRegion),
                sideNav = this.addSideNav(sideLayout.sideNavRegion, sideNavItems, initialView);
                
            this.addGettingStartedPanel(sideLayout.gettingStartedRegion, sideNav);
                
            this.initialContent(initialView, layout.contentRegion);
            
            this.listenTo(sideNav, Events.SideNav.selectItem, function (sideNavItemName) {
                this.showContentViewByName(sideNavItemName, layout.contentRegion);
            });
        },

        addSideLayout: function (region) {
            var layout = new SideLayout();
            region.show(layout);
            return layout;
        },

        addGettingStartedPanel: function (region, sideNav) {
            gettingStartedController.setSideNav(sideNav);
            var view = gettingStartedController.constructGettingStartedLayout();
            region.show(view);
            return view;
        },
                
        showContentViewByName: function (viewName, region) {
            var view = this.createContentView(viewName);
            this.showContentView(view, region);
        },
        
        showContentView: function (view, region) {
            region.show(view);
        },
        
        initialContent: function (initialView, contentRegion) {
            this.showContentViewByName(initialView, contentRegion);
        },
        
        addSideNav: function (region, sideNavItems, initialView) {
            var sideNavItemCollection = this.createSideNavItemCollection(sideNavItems),
                sideNav = new SideNavView({
                    collection: sideNavItemCollection,
                    initialView: initialView
                });
            region.show(sideNav);
            return sideNav;
        },

        createSideNavItemCollection: function (sideNavItems) {
            var models = [],
                i;
            for (i = 0; i < sideNavItems.length; i += 1) {
                models.push(
                    new SideNavItem({
                        name: sideNavItems[i]
                    })
                );
            }
            return new SideNavItemCollection(models);
        },
        

        createContentView: function (viewName, options) {
            return this.getContentViewFactory().createView(viewName, options);
        }
    });

    Cocktail.mixin(MainLayoutController, RequiredParametersMixin);
    
    return MainLayoutController;
});

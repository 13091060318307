define('views/layouts/activityLayout',[
    'views/base/baseLayoutView',
    'hbs!templates/layouts/activity',
    'views/composite/historicalTransfersView',
    'models/paginatedHistoricalTransferCollection',
    'controllers/notificationController',
    'mixins/pageLoadIndicatorMixin',
    'i18n!nls/messages',
    'cocktail',
    'underscore',
    'constants/events'
], function (BaseLayoutView, ActivityLayoutTmpl, HistoricalTransfersView,
        PaginatedHistoricalTransfers, notificationController,
        pageLoadIndicatorMixin, messageBundle, Cocktail, _, Events) {
    'use strict';
    var ActivityLayout = BaseLayoutView.extend({
        template: ActivityLayoutTmpl,

        initialize: function (options) {
            if(_.isUndefined(options) || _.isUndefined(options.paginatedTransferCollection)) {
                this.paginatedTransfersCollection = new PaginatedHistoricalTransfers();
            } else {
                this.paginatedTransfersCollection = options.paginatedTransferCollection;
            }
            this.listenTo(this.paginatedTransfersCollection, Events.TransferActivity.pageLoaded, this.pageChangedHandler, this);
            this.listenTo(this.paginatedTransfersCollection, Events.TransferActivity.pageLoadError, this.onPageLoadError, this);
        },

        ui: {
            nextPageButton: '.fa-angle-right',
            previousPageButton: '.fa-angle-left'
        },

        events: {
            'click @ui.nextPageButton': 'showNextPage',
            'click @ui.previousPageButton': 'showPreviousPage'
        },

        regions: {
            activitiesRegion: '.activities'
        },

        onShow: function () {
            this.showNextPage();
        },
        
        showView: function () {
            this.hideLoading();
            this.activitiesRegion.show(new HistoricalTransfersView({
                collection: this.currentTransfersCollection
            }));
        },
        
        onPageLoadError: function () {
            notificationController.showErrorNotification(messageBundle.global.unexpectedError);
        },
        
        pageChangedHandler: function(fetchedCollection, hasPrevious, hasNext) {
            this.currentTransfersCollection = fetchedCollection;
            this.showView();
            this.updatePagingButtons(hasPrevious, hasNext);
        },
        
        updatePagingButtons: function (hasPrevious, hasNext) {
            this.ui.previousPageButton.toggleClass('invisible', !hasPrevious);
            this.ui.nextPageButton.toggleClass('invisible', !hasNext);
        },
        
        showNextPage: function () {
            this.showLoading();
            this.paginatedTransfersCollection.nextPage();
        },
        
        showPreviousPage: function () {
            this.showLoading();
            this.paginatedTransfersCollection.previousPage();
        }
    });
    
    Cocktail.mixin(ActivityLayout, pageLoadIndicatorMixin);

    return ActivityLayout;

});


/* START_TEMPLATE */
define('hbs!templates/items/storageSelection',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n','frontend-common-components/objectStorageConfig/js/helpers/handlebars/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"first-row\">\n    <div class=\"col-xs-12\">\n        "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.selectType", options) : helperMissing.call(depth0, "i18n", "storage.addPanel.selectType", options)))
    + "\n    </div>\n</div>\n<div class=\"storage-selection\">\n    <div class=\"storage-selection-box aws\">\n        <div id=\"aws-selection-img\" class=\"storage-selection-img\" />\n        <span class=\"storage-title\">"
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.services.AWS", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.services.AWS", options)))
    + "</span>\n    </div>\n    <div class=\"storage-selection-box azure\">\n        <div  id=\"azure-selection-img\" class=\"storage-selection-img\" />\n        <span class=\"storage-title\">"
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.services.AZURE", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.services.AZURE", options)))
    + "</span>\n    </div>\n</div>";
  return buffer;
  });
Handlebars.registerPartial('templates/items/storageSelection', t);
return t;
});
/* END_TEMPLATE */
;

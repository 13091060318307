define('views/base/baseItemView',[
    'marionette',
    'underscore'
], function (Marionette, _) {
    'use strict';
    return Marionette.ItemView.extend(_.extend({
        isIE: function () {
            return ('ActiveXObject' in window);
        },
        
        showInfo: function (errorMessageArea, message) {
            errorMessageArea.removeClass('text-error');
            errorMessageArea.text(message);
        },
        
        showError: function (errorMessageArea, message) {
            errorMessageArea.addClass('text-error');
            errorMessageArea.text(message);
        },
        
        markErrorField: function (field) {
            field.addClass('has-error');
        },
        
        clearErrorField: function (field) {
            field.removeClass('has-error');
        },
        
        isEmpty: function (field) {
            return (field.val().trim() === '');
        }
    }));
});

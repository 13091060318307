/*jshint unused:false, undef:false, eqeqeq:false*/
$.editable.addInputType('text-max-length', {
    element : function (settings, original) {
        'use strict';
        var input = $('<input />');
        if (settings.width  != 'none') { input.width(settings.width);  }
        if (settings.height != 'none') { input.height(settings.height); }
        if (settings.maxlength != 'none') {input.attr('maxlength', settings.maxlength); }
        input.attr('autocomplete', 'off');
        $(this).append(input);
        return (input);
    }
});

define("jquery.jeditable.maxlength", ["jquery.jeditable"], (function (global) {
    return function () {
        var ret, fn;
        return ret || global.jquery;
    };
}(this)));


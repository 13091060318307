define('controllers/pageLoadIndicatorController',[
    'marionette',
    'backbone',
    'cocktail',
    'mixins/validation/requiredParametersMixin'
], function (Marionette, Backbone, Cocktail, RequiredParametersMixin) {
    'use strict';

    var PageLoadIndicatorController = Marionette.Controller.extend({
        expectedParameters: {
            'region': Marionette.Region,
            'view': Marionette.View.constructor,
            'radioChannel': 'String',
            'showEvent': 'String',
            'hideEvent': 'String'
        },
        
        initialize: function (options) {
            this.validateExpectedParameters(options);
            this.region = options.region;
            this.view = options.view;
            this.radio = Backbone.Wreqr.radio.channel(options.radioChannel);
            this.radio.commands.setHandler(options.showEvent, this.show, this);
            this.radio.commands.setHandler(options.hideEvent, this.hide, this);
        },
        
        show: function () {
            
            this.region.show(new this.view());
        },
        
        hide: function () {
            
            this.region.empty();
        }
    });
    Cocktail.mixin(PageLoadIndicatorController, RequiredParametersMixin);
    
    return PageLoadIndicatorController;
});

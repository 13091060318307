define('views/items/historicalTransferView',[
    'views/base/baseItemView',
    'hbs!templates/items/historicalTransfer',
    'helpers/activityTemplateHelper'
], function (BaseItemView, HistoricalTransferTmpl, activityTemplateHelper) {
    'use strict';
    return BaseItemView.extend({
        template: HistoricalTransferTmpl,
        templateHelpers: activityTemplateHelper
    });

});

define('models/paginatedHistoricalTransferCollection',[
    'underscore',
    'collections/historicalTransferCollection',
    'constants/events',
    'models/base/baseModel'
],
function (_, HistoricalTransferCollection, Events, BaseModel) {
    'use strict';
    return BaseModel.extend({
        
        initialize: function () {
            this.collectionCache = [];
        },

        nextPage: function () {
            var lastKey;
            if (_.isUndefined(this.currentPage)) {
                this.currentPage = 0;
            } else {
                lastKey = this.collectionCache[this.currentPage].lastKey;
                this.currentPage++;
            }

            if (_.isUndefined(this.collectionCache[this.currentPage])) {
                var transferCollection = new HistoricalTransferCollection({lastKey: lastKey});
                transferCollection.fetch({
                    success: _.bind(function () {
                        this.collectionCache.push(transferCollection);
                        this.triggerPageLoadedEvent(this.collectionCache[this.currentPage]);
                    }, this),
                    error: _.bind(function () {
                        this.triggerPageLoadErrorEvent();
                    }, this)
                });
            } else {
                this.triggerPageLoadedEvent(this.collectionCache[this.currentPage]);
            }
        },

        previousPage: function () {
            if (!_.isUndefined(this.currentPage) && this.currentPage > 0) {
                this.triggerPageLoadedEvent(this.collectionCache[--this.currentPage]);
            }
        },
        
        triggerPageLoadedEvent: function (data) {
            var hasPrevious = this.currentPage !== 0;
            var hasNext = !this.collectionCache[this.currentPage].finished;
            this.trigger(Events.TransferActivity.pageLoaded, data, hasPrevious, hasNext);
        },
        
        triggerPageLoadErrorEvent: function() {
            this.trigger(Events.TransferActivity.pageLoadError);
        }
    });
});

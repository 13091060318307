
/* START_TEMPLATE */
define('hbs!templates/items/signupForm',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<form id=\"skydrop-admin-sigup-form\" role=\"form\" class=\"skydrop-admin-panel-form\">\n    <div id=\"skydrop-admin-signup-name-wrapper\" class=\"form-element-wrapper has-feedback\">\n        <input id=\"skydrop-admin-signup-name\" class=\"form-control\" type=\"text\" name=\"name\" maxlength=\"";
  if (helper = helpers.NAME_MAX_LENGTH) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.NAME_MAX_LENGTH); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" placeholder=\""
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "signup.namePlaceholderText", options) : helperMissing.call(depth0, "i18n", "signup.namePlaceholderText", options)))
    + "\" />\n        <span class=\"fa fa-exclamation form-control-feedback\"/>\n    </div>\n    <div id=\"skydrop-admin-signup-email-wrapper\" class=\"form-element-wrapper has-feedback\">\n        <input id=\"skydrop-admin-signup-email\" class=\"form-control\" type=\"text\" name=\"email\" maxlength=\"";
  if (helper = helpers.EMAIL_MAX_LENGTH) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.EMAIL_MAX_LENGTH); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" placeholder=\""
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "signup.emailPlaceholderText", options) : helperMissing.call(depth0, "i18n", "signup.emailPlaceholderText", options)))
    + "\" />\n        <span class=\"fa fa-exclamation form-control-feedback\"/>\n    </div>\n    <div class=\"form-element-wrapper\">\n        <button type=\"submit\" class=\"btn btn-primary\" id=\"skydrop-admin-register-btn\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "signup.registerButtonText", options) : helperMissing.call(depth0, "i18n", "signup.registerButtonText", options)))
    + "</button>\n    </div>\n</form>\n<div class=\"skydrop-admin-panel-dialog-message\"/>";
  return buffer;
  });
Handlebars.registerPartial('templates/items/signupForm', t);
return t;
});
/* END_TEMPLATE */
;

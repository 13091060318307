
/* START_TEMPLATE */
define('hbs!frontend-common-components/objectStorageConfig/templates/items/addAWSStorage',['hbs','hbs/handlebars','frontend-common-components/objectStorageConfig/js/helpers/handlebars/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, functionType="function";


  buffer += "<div class=\"row first-row\">\n    <div class=\"col-xs-12\">\n        "
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.instruction", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.instruction", options)))
    + " <a class=\" obvious-link\" href=\"awsStorageHelp\" target=\"_blank\">"
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.help", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.help", options)))
    + "</a>\n   </div>\n</div>\n<div class=\"row separated\">\n    <div class=\"col-xs-5\"><label class=\"text-input-label\">"
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.accountId", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.accountId", options)))
    + "</label></div>\n    <div id=\"signiant-account-id\" class=\"col-xs-6\">";
  if (helper = helpers.signiantAccountId) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.signiantAccountId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n</div>\n<div class=\"row\">\n    <div class=\"col-xs-5\"><label class=\"text-input-label\">"
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.externalId", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.externalId", options)))
    + "</label></div>\n    <div id=\"external-id\" class=\"col-xs-8\">";
  if (helper = helpers.accountId) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.accountId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n</div>\n<div class=\"row separated arn\">\n    <div class=\"col-xs-3 text-input-col\">\n        <div class=\"text-input-label\">\n            <label class=\"text-input-label\">\n                "
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.roleArn", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.roleArn", options)))
    + "\n            </label>\n        </div>\n    </div>\n    <div class=\"col-xs-10 \">\n        <div id=\"role-arn-wrapper\" class=\"has-feedback\">\n            <input class=\"form-control\" type=\"text\" placeholder=\""
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.roleArnPlaceholder", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.roleArnPlaceholder", options)))
    + "\" id=\"role-arn\" />\n            <span id=\"role-arn-icon\" class=\"fa fa-exclamation form-control-feedback\" />\n        </div>\n    </div>\n</div>\n<div class=\"row\">\n    <div class=\"col-xs-3\"></div>\n    <div id=\"role-arn-message\" class=\"col-xs-10 object-storage-message-text\"></div>\n</div>\n\n<div class=\"bucket-configuration-body\"/>\n\n<div class=\"row\">\n    <div class=\"col-xs-5 col-xs-offset-8\"><button class=\"btn btn-primary btn-block\" id=\"confirm-add-storage\">"
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.addButton", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.addButton", options)))
    + "</button></div>\n</div>\n\n";
  return buffer;
  });
Handlebars.registerPartial('frontend-common-components/objectStorageConfig/templates/items/addAWSStorage', t);
return t;
});
/* END_TEMPLATE */
;

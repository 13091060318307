
/* START_TEMPLATE */
define('hbs!templates/layouts/root',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  


  return "<div class=\"content clearfix\"></div>\n<div id=\"push\"></div>";
  });
Handlebars.registerPartial('templates/layouts/root', t);
return t;
});
/* END_TEMPLATE */
;


/* START_TEMPLATE */
define('hbs!templates/items/mainLogo',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  


  return "<div class=\"sig-flight-logo\" title=\"main logo\"></div>";
  });
Handlebars.registerPartial('templates/items/mainLogo', t);
return t;
});
/* END_TEMPLATE */
;

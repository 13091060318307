
/* START_TEMPLATE */
define('hbs!templates/composite/historicalTransfers',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression, self=this;

function program1(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n<div class=\"row\">\n    <div class=\"col-lg-8 col-md-8 col-sm-8 no-activity\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "activity.nodata", options) : helperMissing.call(depth0, "i18n", "activity.nodata", options)))
    + "</div>\n</div>\n";
  return buffer;
  }

  buffer += "<div class=\"row\">\n    <div class=\"col-md-3 col-sm-5 hidden-xs activity-header-col\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "activity.client", options) : helperMissing.call(depth0, "i18n", "activity.client", options)))
    + "</div>\n    <div class=\"col-md-2 col-sm-4 col-xs-4 activity-header-col\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "activity.transferred", options) : helperMissing.call(depth0, "i18n", "activity.transferred", options)))
    + "</div>\n    <div class=\"col-md-3 hidden-xs hidden-sm activity-header-col\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "activity.key", options) : helperMissing.call(depth0, "i18n", "activity.key", options)))
    + "</div>\n    <div class=\"col-md-3 col-sm-3 col-xs-8 activity-header-col\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "activity.when", options) : helperMissing.call(depth0, "i18n", "activity.when", options)))
    + "</div>\n</div>\n";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.isEmptyResult), {hash:{},inverse:self.noop,fn:self.program(1, program1, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n";
  return buffer;
  });
Handlebars.registerPartial('templates/composite/historicalTransfers', t);
return t;
});
/* END_TEMPLATE */
;

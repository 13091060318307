define('views/items/sideNavItemView',[
    'views/base/baseItemView',
    'hbs!templates/items/sideNavItem',
    'i18n!nls/messages',
    'underscore'
], function (BaseItemView, SideNavItemTmpl, messages, _) {
    'use strict';
    var ACTIVE_CLASS = 'active';
    
    return BaseItemView.extend({
        template: SideNavItemTmpl,
        tagName: 'li',

        activate: function () {
            this.$el.addClass(ACTIVE_CLASS);
        },
        
        deactivate: function () {
            this.$el.removeClass(ACTIVE_CLASS);
        },

        triggers: {
            'click': 'sideNavItem:click'
        },
               
        templateHelpers: {
            localizedName: function () {
                if (_.isUndefined(messages.subNavItems[this.name])) {
                    return this.name;
                }
                return messages.subNavItems[this.name];
            }
        }
    });
});

define('views/items/resetPasswordView',[
    'jquery',
    'underscore',
    'backbone',
    'constants/properties',
    'i18n!nls/messages',
    'validation/sigValidator',
    'hbs!templates/items/resetPassword',
    'views/base/baseItemView',
    'constants/events',
    'mixins/pageLoadIndicatorMixin',
    'models/resetPasswordModel',
    'cocktail',
    'jquery.placeholder'
], function ($, _, Backbone, properties, messageBundle, validator,
            resetPasswordTmpl, BaseItemView, Events, pageLoadIndicatorMixin, ResetPasswordModel, Cocktail) {
    'use strict';

    var ResetPasswordView = BaseItemView.extend({
        template: resetPasswordTmpl,
        
        initialize: function (options) {
            this.authToken = options.authToken;
            this.model = new ResetPasswordModel();
            this.listenTo(this.model, 'error', this.handleSaveError, this);
            this.listenTo(this.model, 'save:success', this.handleSaveSuccess, this);
        },
        
        ui: {
            newPasswordField: '#new-password-field',
            newPasswordFieldWrapper: '#new-password-field-wrapper',
            confirmPasswordField: '#confirm-password-field',
            errorMessageArea: '.skydrop-admin-panel-dialog-message',
            resetPasswordButton: '#reset-password-btn'
        },
        
        events: {
            'submit #reset-password-form': 'handleFormSubmit',
            'keyup @ui.newPasswordField': 'handleKeyUp',
            'keyup @ui.confirmPasswordField': 'handleKeyUp'
        },
        
        handleKeyUp: function () {
            if (this.ui.newPasswordField.val() !== '') {
                if (!this.isValidPassword(this.ui.newPasswordField)) {
                    this.showError(messageBundle.changePassword.invalidPassword, this.ui.newPasswordFieldWrapper);
                    this.ui.resetPasswordButton.addClass('disabled');
                } else {
                    this.ui.resetPasswordButton.removeClass('disabled');
                    this.clearErrors();
                }
            }
        },
        
        handleFormSubmit: function () {
            if (!this.isEmpty(this.ui.newPasswordField)) {
                
                if (!this.isValidPassword(this.ui.newPasswordField)) {
                    this.showError(messageBundle.changePassword.invalidPassword, this.ui.newPasswordField);
                    return false;
                }
                
                if (!this.arePasswordsEqual()) {
                    this.showError(messageBundle.changePassword.passwordsDoNotMatch);
                    this.resetForm();
                    
                    this.focusField(this.ui.newPasswordField.focus());
                    
                    return false;
                }
                
                this.model.set('newPassword', this.ui.newPasswordField.val());
                this.showLoading();
                var headers = {};
                headers[properties.rest.skydropXHeader] = this.authToken;
                this.model.save({}, {
                        type: 'put',
                        headers: headers
                    }
                );
            }
            return false;
        },
        
        resetForm: function () {
            this.ui.newPasswordField.val('');
            this.ui.confirmPasswordField.val('');
            this.ui.resetPasswordButton.addClass('disabled');
        },
        
        arePasswordsEqual: function () {
            var newPassword = this.ui.newPasswordField.val();
            var confirmPassword = this.ui.confirmPasswordField.val();
            
            return _.isEqual(newPassword, confirmPassword);
        },

        isValidPassword: function (passwordField) {
            var newPassword = passwordField.val();
            
            return validator.isPassword(newPassword);
        },
        
        handleSaveError: function (model, response) {
            this.hideLoading();
            if (response.status === 401) {
                Backbone.Wreqr.radio.commands.execute(Events.RadioChannels.loginRouter,
                        Events.LoginRouter.route, '#forgotPassword?error=resetPasswordError');
            } else {
                this.showError(messageBundle.global.unexpectedError);
            }
            
        },
        
        handleSaveSuccess: function () {
            this.hideLoading();
            Backbone.Wreqr.radio.commands.execute(Events.RadioChannels.loginRouter,
                    Events.LoginRouter.route, '', '', 'resetPasswordSuccess');
        },
        
        showError: function (message, field) {
            if (field) {
                this.markErrorField(field);
            }
            
            this.ui.errorMessageArea.addClass('text-error');
            this.ui.errorMessageArea.text(message);
        },
        
        clearErrors: function () {
            this.clearErrorField(this.ui.newPasswordFieldWrapper);
            this.ui.errorMessageArea.text('');
            this.ui.errorMessageArea.removeClass('text-error');
        },
        
        onShow: function () {
            $('input[placeholder]').placeholder();
            this.focusField(this.ui.newPasswordField.focus());
        },

        focusField: function (field) {
            if (!this.isIE()) {
                field.focus();
            }
        }
    });
    
    Cocktail.mixin(ResetPasswordView, pageLoadIndicatorMixin);
    
    return ResetPasswordView;
});


/* START_TEMPLATE */
define('hbs!frontend-common-components/gettingStartedPanel/templates/items/actionItemTmpl',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<a class=\"action-item-title\" title=\"";
  if (helper = helpers.title) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.title); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">";
  if (helper = helpers.title) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.title); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</a> <i class=\"fa fa-check action-item-check\"></i> <i class=\"fa fa-arrow-left action-item-arrow\"></i>";
  return buffer;
  });
Handlebars.registerPartial('frontend-common-components/gettingStartedPanel/templates/items/actionItemTmpl', t);
return t;
});
/* END_TEMPLATE */
;

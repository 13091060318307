define('views/layouts/signupLayout',[
    'views/base/baseLayoutView',
    'hbs!templates/layouts/signupLayout',
    'views/layouts/signupPanelLayout',
    'helpers/signupTemplateHelper',
    'i18n!nls/messages',
], function (BaseLayoutView, SignupLayoutTmpl, SignupPanelLayout, signupTemplateHelper, messageBundle) {
    
    'use strict';
    
    return BaseLayoutView.extend({
        template: SignupLayoutTmpl,
        
        templateHelpers: signupTemplateHelper,
        
        className: 'signup-layout',
        
        regions: {
            signupRegion: '.signup-view'
        },
        
        initialize: function (options) {
            this.view = options.view;
        },

        _showView: function () {
            var signupPanelLayout = new SignupPanelLayout({
                view: this.view
            });

            this.signupRegion.show(signupPanelLayout);
        },
        
        onShow: function () {
            this._showView();
        }
    });
});

define('views/items/signupView',[
    'underscore',
    'jquery',
    'views/base/baseItemView',
    'hbs!templates/items/signupForm',
    'validation/emailValidation',
    'models/signupForm',
    'i18n!nls/messages',
    'mixins/pageLoadIndicatorMixin',
    'cocktail',
    'jquery.placeholder'
], function (_, $, BaseItemView, SignupFormTmpl, emailValidator, SignupForm, messageBundle, pageLoadIndicatorMixin,
        Cocktail) {
    'use strict';
        
    var SignupView = BaseItemView.extend({
    	
        template: SignupFormTmpl,
        
        templateHelpers: {
            NAME_MAX_LENGTH: 200,
            EMAIL_MAX_LENGTH: 254
        },
        
        ui: {
            name: '#skydrop-admin-signup-name',
            email: '#skydrop-admin-signup-email',
            nameWrapper: '#skydrop-admin-signup-name-wrapper',
            emailWrapper: '#skydrop-admin-signup-email-wrapper',
            registerButton: '#skydrop-admin-register-btn',
            errorMessageArea: '.skydrop-admin-panel-dialog-message'
        },
        
        events: {
            'submit form#skydrop-admin-sigup-form': 'handleFormSubmit'
        },
        
        modelEvents: {
            'save:success': 'handleSignupSuccess',
            'save:error': 'handleSignupError'
        },
        
        serverErrorMap: {
            'invalid.name': {
                message: messageBundle.signup.invalidNameErrorMessage,
                field: 'nameWrapper'
            },
            'invalid.email': {
                message: messageBundle.signup.invalidEmailErrorMessage,
                field: 'emailWrapper'
            },
            'cloudaccount.create.failure.email.inuse': {
                message: messageBundle.login.emailInUse,
                field: 'emailWrapper'
            },
            'customer.account.already.exists': {
                message: messageBundle.signup.customerAccountAlreadyExists
            }
        },
        
        initialize: function () {
            this.model = new SignupForm();
        },
        
        handleFormSubmit: function () {
            this.clearError();
            
            if (this.isEmpty(this.ui.name)) {
                this.showError(this.ui.errorMessageArea, messageBundle.signup.invalidNameErrorMessage);
                this.markErrorField(this.ui.nameWrapper);
            } else if (this.isEmpty(this.ui.email) || !emailValidator(this.ui.email.val())) {
                this.showError(this.ui.errorMessageArea, messageBundle.signup.invalidEmailErrorMessage);
                this.markErrorField(this.ui.emailWrapper);
            } else {
                this.showLoading();
                this.model.set('name', this.ui.name.val());
                this.model.set('email', this.ui.email.val());
                this.model.save();
            }

            return false;
        },
        
        handleSignupSuccess: function () {
            window.location = window.location.protocol + '//' + window.location.host  + '/' +
                    window.location.pathname.split('/').slice(0, -1).join('/') + '?info=signupSuccess';
        },
        
        handleSignupError: function (model, response) {
            var message = null;
            var field = null;
            var responseJSON = this.getResponseJSON(response);

            if (!_.isEmpty(responseJSON.message)) {
                var mapping = this.serverErrorMap[responseJSON.message];
                if (mapping) {
                    message = mapping.message;
                    field = mapping.field;
                } else {
                    message = responseJSON.message;
                }
            }
            
            this.hideLoading();
            this.showError(this.ui.errorMessageArea, message ? message :  messageBundle.signup.unexpectedError);
            if (field) {
                this.markErrorField(this.ui[field]);
            }
        },
        
        getResponseJSON: function (response) {
            var responseJSON;
            if (response.responseJSON) {
                responseJSON = response = response.responseJSON;
                if (responseJSON.length > 0) {
                    responseJSON = response[0];
                }
            } else {
                responseJSON = {};
            }
            return responseJSON;
        },
        
        clearError: function () {
        	this.clearErrorField(this.ui.nameWrapper);
            this.clearErrorField(this.ui.emailWrapper);
            this.ui.errorMessageArea.text('');
        },
        
        onShow: function () {
            $('input[placeholder]').placeholder();
            if (!this.isIE()) {
                this.ui.name.focus();
            }
        }
    });
    
    Cocktail.mixin(SignupView, pageLoadIndicatorMixin);
    return SignupView;
});

define('collections/historicalTransferCollection',[
    'collections/base/baseCollection',
    'helpers/skydropContextHelper',
    'underscore',
    'models/historicalTransfer'
],
function (BaseCollection, contextHelper, _, historicalTransfer) {
    'use strict';
    return BaseCollection.extend({
        MAX_RESULTS: 10,
        model: historicalTransfer,
        
        initialize: function(options) {
            this.lastKey = options.lastKey
        },
        
        url: function () {
            var baseURL = contextHelper.getHistoricalTransfersUrl();
            return baseURL + this.urlSuffix(baseURL);
        },
        
        defaults: {},
        
        parse: function (response) {
            if (_.isUndefined(response.results) === false) {
                this.finished = response.finished;
                this.lastKey = response.lastKey;
                return response.results;
            }
            return response;
        },
        
        urlSuffix: function (baseURL) {
        	var urlSuffix = "";
            var separator = '?', _maxResult = 'maxResults=' + this.MAX_RESULTS;
            if (_.contains(baseURL, separator)) {
                separator = '&';
            }
            
            if (_.isUndefined(this.lastKey) === false) {
                urlSuffix = separator + 'lastKey=' + this.lastKey + '&' + _maxResult;
            } else {
            	urlSuffix = separator + _maxResult;
            }
            urlSuffix += '&includeSkippedFiles=false'
            return urlSuffix;
        }
    });
});

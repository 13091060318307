define('views/composite/storageConfigsView',[
    'views/base/baseCompositeView',
    'views/items/storageConfigView',
    'constants/storageConfigStatus',
    'hbs!templates/composite/storageConfigs'
], function (
    BaseCompositeView,
    StorageConfigView,
    StorageConfigStatus,
    storageConfigsTmpl
) {
    'use strict';
    return BaseCompositeView.extend({
        className: 'highlightable-table',
        template: storageConfigsTmpl,
        childView: StorageConfigView,
        
        filter: function (child, index, collection) {
        	if(this.options.isRevokedStorageConfigVisible === false) {
        		return _.isEqual(child.get('status'), StorageConfigStatus.active);
	        }
	        return collection;
        }
    });
});

define('views/layouts/signupPanelLayout',[
    'views/base/baseLayoutView',
    'hbs!templates/layouts/signupPanel'
], function (BaseLayoutView, signupPanelTmpl) {
    'use strict';
    return BaseLayoutView.extend({
        template: signupPanelTmpl,

        className: 'signup-panel-layout',

        regions: {
            panelRegion: '.signup-panel-region'
        },

        initialize: function (options) {
            this.view = options.view;
        },
        
        showPanelView: function (view) {
            this.view = view;
            this._showView();
        },
        
        _showView: function () {
            this.panelRegion.show(this.view);
        },
        
        onShow: function () {
            this._showView();
        }
    });
});

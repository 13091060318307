
/* START_TEMPLATE */
define('hbs!templates/items/apiKey',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

function program1(depth0,data) {
  
  
  return "text-muted";
  }

function program3(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n	        <div class=\"secret truncate-ellipsis\" title=\"";
  if (helper = helpers.secret) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.secret); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"><span class=\"credential-label\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "keys.table.secretLabel", options) : helperMissing.call(depth0, "i18n", "keys.table.secretLabel", options)))
    + "</span>";
  if (helper = helpers.secret) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.secret); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n         ";
  return buffer;
  }

function program5(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n             <a class=\"generate-secret obvious-link truncate-ellipsis\" title=\""
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "keys.table.generateSecretLabel", options) : helperMissing.call(depth0, "i18n", "keys.table.generateSecretLabel", options)))
    + "\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "keys.table.generateSecretLabel", options) : helperMissing.call(depth0, "i18n", "keys.table.generateSecretLabel", options)))
    + "</a>\n	     ";
  return buffer;
  }

  buffer += "<div class=\"col-md-4 col-sm-4 col-xs-4 credentials-column truncate-ellipsis ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.nameFieldEmpty), {hash:{},inverse:self.noop,fn:self.program(1, program1, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" id=\"name\" title=\"";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n\n<div class=\"col-md-7 col-sm-7 col-xs-7 credentials-column\">\n    <div class=\"credentials\">\n        <div class=\"truncate-ellipsis\" title=\"";
  if (helper = helpers.id) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.id); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"><span class=\"credential-label\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "keys.table.keyLabel", options) : helperMissing.call(depth0, "i18n", "keys.table.keyLabel", options)))
    + "</span>";
  if (helper = helpers.id) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.id); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n	     ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.secret), {hash:{},inverse:self.program(5, program5, data),fn:self.program(3, program3, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n     </div>\n</div>\n<div class=\"col-md-1 col-sm-1 col-xs-1 credentials-column\" id=\"status\">";
  if (helper = helpers.localizedStatus) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.localizedStatus); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n<div class=\"col-md-1 col-sm-1 col-xs-1 table-column context-menu-column \">\n    <div class=\"overflow-container pull-right\">\n        <span class=\"overflow-button pull-right\" data-toggle=\"dropdown\"><i class=\"fa fa-ellipsis-v fa-lg\"></i></span>\n        <ul class=\"dropdown-menu\" role=\"menu\">\n            <li role=\"presentation\">\n                <a role=\"menuitem\" tabindex=\"-1\" href=\"#\" class=\"apiKey-action\">";
  if (helper = helpers.action) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.action); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</a>\n            </li>\n            <li role=\"presentation\">\n                <a role=\"menuitem\" tabindex=\"-1\" href=\"#\" class=\"apiKey-rename\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "keys.action.rename", options) : helperMissing.call(depth0, "i18n", "keys.action.rename", options)))
    + "</a>\n            </li>\n        </ul>\n    </div>\n</div>";
  return buffer;
  });
Handlebars.registerPartial('templates/items/apiKey', t);
return t;
});
/* END_TEMPLATE */
;

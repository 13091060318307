require.config({
    baseUrl: 'resources/js',
    
    deps: [
        'bootstrap.dropdown',
        'bootstrap.modal',
        'ieConsoleFix',
        'backbone.queryparams',
        'jquery.jeditable',
        'jquery.jeditable.maxlength',
        'jquery.placeholder'
    ],
    
    shim: {
        'bootstrap.dropdown': {
            deps: ['jquery'],
            exports: 'jquery'
        },
        'bootstrap.modal': {
            deps: ['jquery'],
            exports: 'jquery'
        },
        'bootstrap.collapse': {
            deps: ['jquery'],
            exports: 'jquery'
        },
        'jquery.placeholder': {
            deps: ['jquery'],
            exports: 'jquery'
        },
        'jquery.jgrowl': {
            deps: ['jquery'],
            exports: 'jquery'
        },
        'backbone.queryparams': {
            deps: ['backbone'],
            exports: 'Backbone'
        },
        'jquery.jeditable': {
            deps: ['jquery'],
            exports: 'jquery'
        },
        'jquery.jeditable.maxlength': {
            deps: ['jquery.jeditable'],
            exports: 'jquery'
        },
    },
    
    paths: {
        /* main libraries */
        jquery: '../bower_components/jquery/dist/jquery',
        backbone: '../bower_components/backbone/backbone',
        'backbone.queryparams': '../bower_components/backbone-query-parameters/backbone.queryparams',
        underscore: '../bower_components/underscore/underscore',
        lodash: '../bower_components/lodash-amd/modern/main',
        marionette: '../bower_components/backbone.marionette/lib/backbone.marionette',
        quantities: '../bower_components/js-quantities/src/quantities',
        cocktail: '../bower_components/backbone.cocktail/Cocktail',
        moment: '../bower_components/moment/moment',
        d3: '../bower_components/d3/d3',
        d3tip: '../bower_components/d3-tip/index',
        validator: '../bower_components/validator-js/validator',
        
        'jquery.jeditable': '../bower_components/jeditable/jquery.jeditable',
        'jquery.jeditable.maxlength': 'libs/jquery.jeditable.maxlength',
        
        /* bootstrap javascript */
        'bootstrap.dropdown': '../bower_components/bootstrap-sass-official/assets/' +
            'javascripts/bootstrap/dropdown',
        'bootstrap.collapse': '../bower_components/bootstrap-sass-official/assets/' +
            'javascripts/bootstrap/collapse',
        'bootstrap.modal': '../bower_components/bootstrap-sass-official/assets/' +
            'javascripts/bootstrap/modal',
        
        /* requirejs plugins */
        hbs: '../bower_components/require-handlebars-plugin/hbs',
        text: '../bower_components/requirejs-text/text',
        i18n: '../bower_components/requirejs-i18n/i18n',
        
        /* aliases */
        nls: '../nls',
        templates: '../templates',
        ieConsoleFix: 'helpers/ieConsoleFix',
        
        /* jquery plugins */
        'jquery.placeholder': '../bower_components/jquery-placeholder/jquery.placeholder',
        'jquery.jgrowl': '../bower_components/jgrowl/jquery.jgrowl',
        
        /* shared signiant code */
        'frontend-common-components': '../node_modules/npm-frontend-common/components'
    },

    hbs: {
        helperPathCallback: function (name) {
            'use strict';
            if (name.indexOf('i18n-objectStorageConfig') === 0) {
                return 'frontend-common-components/objectStorageConfig/js/helpers/handlebars/i18n';
            } else if (name.indexOf('i18n-gettingStartedPanel') === 0) {
                return 'frontend-common-components/gettingStartedPanel/js/helpers/handlebars/i18n';
            } else {
                return 'libs/handlebars-helpers/' + name;
            }
        }
    },
    
    config: {
        i18n: {
            locale: 'root'
        },
        'views/layouts/contentLayout': {
            initialView: 'overview',
            sideNavItems: [
                'overview',
                'activity',
                'storage',
                'keys'
            ]
        },
        'helpers/humanizeUnits': {
            minUnit : 'MiB',
            threshold: 3,
            precision: 0.1,
            maxCharacterCount: 3,
            uniformUnits: true,
            MiB: {
                precision: 1
            }
        }
    },
    
    waitSeconds: 15
});
define("require-config", function(){});


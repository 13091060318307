define('mixins/validation/requiredParametersMixin',[
    'underscore'
], function (_) {
    'use strict';

    return {
        //override this
        expectedParameters: {

        },
 
        throwMissingParameterError: function (parameterName) {
            throw  'Missing parameter ' + parameterName;
        },

        throwInvalidParameterType: function (parameterName, actualType, expectedType) {
            throw 'Expected parameter ' + parameterName + ' of type ' + expectedType +
                '. Actual type: ' + actualType;
        },
        
        throwInvalidParameterObject: function (parameterName) {
            throw 'Required parameter ' + parameterName + ' was not an instance of the required ' +
                'class';
        },
       
        validateExpectedParameters: function (options) {
            if ((_.isUndefined(options) || _.isEmpty(options)) &&
                _.isEmpty(this.expectedParameters) === false) {
                throw  'Missing expected parameters';
            }

            _.forEach(_.pairs(this.expectedParameters), function (pair) {
                if (_.isUndefined(options[pair[0]])) {
                    this.throwMissingParameterError(pair[0]);
                } else {
                    var paramValue = options[pair[0]],
                        expectedParamType = pair[1],
                        actualParamType;
                    
                    if (_.isString(expectedParamType)) {
                        actualParamType = Object.prototype.toString.call(paramValue);
                        if (actualParamType !== '[object ' + expectedParamType + ']') {
                            this.throwInvalidParameterType(pair[0], actualParamType,
                                expectedParamType);
                        }
                    } else if (_.isObject(expectedParamType)) {
                        if ((paramValue instanceof expectedParamType) === false) {
                            this.throwInvalidParameterObject(pair[0]);
                        }
                    }
                }
            }, this);
        }
    };
});

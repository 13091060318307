define('mixins/pageLoadIndicatorMixin',[
    'backbone',
    'constants/events',
    'marionette'
],
function (Backbone, Events) {
    'use strict';
    return {
        showLoading: function () {
            Backbone.Wreqr.radio.commands.execute(Events.RadioChannels.pageLoad,
                Events.PageLoadIndicator.show);
        },
        hideLoading : function () {
            Backbone.Wreqr.radio.commands.execute(Events.RadioChannels.pageLoad,
                Events.PageLoadIndicator.hide);
        }
    };
});

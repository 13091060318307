
/* START_TEMPLATE */
define('hbs!frontend-common-components/objectStorageConfig/templates/items/bucketSpecifyConfiguration',['hbs','hbs/handlebars','frontend-common-components/objectStorageConfig/js/helpers/handlebars/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"col-xs-3\">\n    <div class=\"row text-input-col\">\n        <label class=\"bucket-label text-input-label hidden\">";
  if (helper = helpers.bucketConfigurationLabel) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.bucketConfigurationLabel); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</label>\n    </div>\n</div>\n<div class=\"col-xs-10 configure-bucket-content\">\n    <div class=\"row bucket\" id=\"bucketListDiv\">\n        <div class=\"col-xs-14\">\n            <input type=\"radio\" id=\"bucketOptionSelected\" name=\"bucketOption\" value=\"1\"/>\n            <select class=\"form-control bucket-list-dropdown\" id=\"add-storage-bucket\" placeholder=\""
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.bucketPlaceholder", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.bucketPlaceholder", options)))
    + "\"/>\n        </div>\n    </div>\n    <div class=\"row filePrefix\" id=\"specifyDirectoryLinkDiv\">\n        <div class=\"\">\n            <a class=\"obvious-link\" id=\"specify-directory-link\" href=\"#\">"
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.specifyDirectoryLink", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.specifyDirectoryLink", options)))
    + "</a>\n        </div>\n    </div>\n    <div class=\"row filePrefix\" id=\"directoryDiv\">\n        <div class=\"col-xs-14\">\n            <div id=\"directory-prefix-wrapper\" class=\"has-feedback\">\n                <span id=\"directory-slash\" class=\"text-input-label forwardSlash\">"
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.slash", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.slash", options)))
    + "</span>\n                <input class=\"form-control filePrefixField\" type=\"text\" placeholder=\""
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.filePrefixPlaceholder", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.filePrefixPlaceholder", options)))
    + "\" id=\"directory-prefix\" />\n                <span id=\"directory-prefix-icon\" class=\"fa fa-exclamation form-control-feedback\" />\n            </div>\n        </div>\n    </div>\n    <div class=\"row filePrefix file-prefix-message-area hidden\">\n        <div id=\"file-prefix-message\" class=\"col-xs-12 object-storage-message-text\"></div>\n    </div>\n    <div class=\"row\" id=\"noBucketDiv\">\n        <input type=\"radio\" id=\"bucketOptionNotSelected\" name=\"bucketOption\" value=\"0\" checked/></input>\n        <div class=\"text-input-label bucket-in-configuration\">\n            ";
  if (helper = helpers.bucketSpecifiedInConfiguration) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.bucketSpecifiedInConfiguration); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\n        </div>\n    </div>\n</div>\n\n<div class=\"row encryption\">\n    <div class=\"col-xs-3 text-input-col\">\n        <div class=\"text-input-label\">\n            <label for=\"enable-server-side-encryption\" class=\"text-input-label\">\n                "
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.encryption", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.encryption", options)))
    + "\n            </label>\n        </div>\n    </div>\n    <div class=\"col-xs-10 push-down\">\n        <input type=\"checkbox\" id=\"enable-server-side-encryption\" />\n        "
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.enableServerSideEncryption", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.enableServerSideEncryption", options)))
    + "\n    </div>\n</div>";
  return buffer;
  });
Handlebars.registerPartial('frontend-common-components/objectStorageConfig/templates/items/bucketSpecifyConfiguration', t);
return t;
});
/* END_TEMPLATE */
;

define('helpers/skydropContextHelper',[
    'environment',
    'constants/properties'
], function (env, properties) {
    'use strict';
    return {
        shouldResetTimerOnLiveSession: function () {
            return false;
        },
        getBaseUrl: function () {
            return properties.skydrop.services.baseURL + env.accounts[0] + '/';
        },
        getHistoricalTransfersUrl: function () {
            return this.getBaseUrl() + 'transfers';
        },
        getTotalAggregateTransfersUrl: function () {
            return this.getHistoricalTransfersUrl() + '/aggregate';
        },
        getAggregateTransfersUrlPerdayURL: function () {
            return this.getTotalAggregateTransfersUrl() + '?groupBy=day';
        },
        getAggregateTransfersUrlPerdayURLWithDateRange: function (from, to) {
            return this.getAggregateTransfersUrlPerdayURL() + '&from=' + from + '&to=' + to;
        },
        getPasswordResetRequestUrl: function () {
            return properties.skydrop.services.restRoot + 'passwordResetRequest';
        },
        getResetPasswordUrl: function () {
            return properties.skydrop.services.restRoot + 'user/' + env.userId + '/password';
        },
        getActivateAccountURL: function () {
            return properties.skydrop.services.restRoot + 'user/' + env.userId + '/activate';
        },
        getResendActivationURL: function () {
            return properties.skydrop.services.restRoot + 'user/' + env.userId + '/activationRequest';
        },
        getStorageConfigURL: function () {
            return this.getBaseUrl() + 'storageConfig/';
        },
        getAWSStorageConfigURL: function () {
            return this.getStorageConfigURL() + 'aws';
        },
        getAzureStorageConfigURL: function () {
            return this.getStorageConfigURL() + 'azure';
        },

        getApiKeyUrl: function () {
            return this.getBaseUrl() + 'apiKeys';
        },

        getGenerateSecretUrl: function (apiKeyId) {
            return this.getApiKeyUrl() + '/secret/' + apiKeyId;
        }
    };
});

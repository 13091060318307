define('models/signupForm',[
    'models/base/baseModel'
],
function (BaseModel) {
    'use strict';
    
    return BaseModel.extend({

        url: function () {
            return 'service/signup';
        }
    });
});

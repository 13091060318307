define('models/apiKey',[
    'models/base/baseModel',
    'helpers/skydropContextHelper'
],
function (BaseModel, contextHelper) {
    'use strict';
    return BaseModel.extend({
        url: function () {
            return contextHelper.getBaseUrl() + 'apiKeys';
        },
        
        updateUrl: function () {
            return contextHelper.getApiKeyUrl() + '/' + this.id;
        }
    });
});


/* START_TEMPLATE */
define('hbs!templates/composite/storageConfigs',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"row\">\n    <div class=\"col-md-7 col-sm-7 col-xs-7 table-header\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "storage.table.bucketHeader", options) : helperMissing.call(depth0, "i18n", "storage.table.bucketHeader", options)))
    + "</div>\n    <div class=\"col-md-4 col-sm-4 col-xs-4 table-header\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "storage.table.configIdHeader", options) : helperMissing.call(depth0, "i18n", "storage.table.configIdHeader", options)))
    + "</div>\n    <div class=\"col-md-1 col-sm-1 col-xs-1 table-header\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "storage.table.statusHeader", options) : helperMissing.call(depth0, "i18n", "storage.table.statusHeader", options)))
    + "</div>\n    <div class=\"col-md-1 col-sm-1 col-xs-1 table-header\"></div>\n</div>";
  return buffer;
  });
Handlebars.registerPartial('templates/composite/storageConfigs', t);
return t;
});
/* END_TEMPLATE */
;

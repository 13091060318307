define('libs/handlebars-helpers/env',[
        'hbs',
        'environment',
        'libs/deepGet'
    ],
    function (hbs, env, deepGet) {
        'use strict';
        function envHelper(key) {
            return deepGet(env, key, key);
        }

        hbs.get().registerHelper('env', envHelper);

        return envHelper;
    });

define('models/totalTransfers',[
    'models/base/baseModel',
    'helpers/skydropContextHelper',
],
function (BaseModel, contextHelper) {
    'use strict';
    return BaseModel.extend({
        url: function () {
            return contextHelper.getTotalAggregateTransfersUrl();
        },
        
        defaults: {},
        
        parse: function (response) {
            return response.length > 0 ? response[0] : response;
        }
    });
});

define('views/layouts/keysLayout',[
    'views/base/baseLayoutView',
    'views/composite/apiKeysView',
    'views/items/emptyMessageView',
    'hbs!templates/layouts/keys',
    'collections/apiKeyCollection',
    'controllers/notificationController',
    'controllers/apiKeyController',
    'constants/apiKeyStatus',
    'mixins/pageLoadIndicatorMixin',
    'i18n!nls/messages',
    'models/apiKey',
    'underscore',
    'cocktail'
], function (
    BaseLayoutView,
    ApiKeysView,
    EmptyMessageView,
    keysLayoutTmpl,
    ApiKeys,
    notificationController,
    apiKeyController,
    ApiKeyStatus,
    pageLoadIndicatorMixin,
    messageBundle,
    ApiKey,
    _,
    Cocktail
) {
    'use strict';
    var KeysLayout = BaseLayoutView.extend({
        template: keysLayoutTmpl,

        initialize: function () {
            this.apiKeys = apiKeyController.getApiKeyCollection();
        },

        ui: {
            addKey: '#keys-add',
            showRevokedKeys: '#showRevokedKeysWrapper',
            showRevokedKeysCheckBox: '#showRevokedKeysCheckBox'
        },
        
        events: {
            'click  @ui.addKey': 'handleClickAdd',
            'click  @ui.showRevokedKeysCheckBox': 'handleClickShowRevoked'
        },

        regions: {
            keys: '.table-area'
        },

        onShow: function () {
            this.showView(false);
        },
        
        filter: function (child, index, collection) {
	        return _.isEqual(child.get('status'), ApiKeyStatus.active);
        },
        
        showView: function (isRevokedKeyVisible) {
        	var filter = this.filter;
        	if(isRevokedKeyVisible === true) {
        		filter = void 0;
        	}
            var size = this.apiKeys.size();
            if (size > 0) {
            	this.apiKeys.sort();
                
                this.keys.show(new ApiKeysView({
                    filter: filter,
                    collection: this.apiKeys
                }));
                
                this.toggleShowRevokedCheckBox(this.apiKeys);
            } else {
                
                this.keys.show(new EmptyMessageView({
                    message: messageBundle.keys.empty
                }));
            }
        },
        
        toggleShowRevokedCheckBox: function (apiKeys) {
        	_.some(apiKeys.models, function (apiKey) {
	            if (_.isEqual(apiKey.get('status'), ApiKeyStatus.revoked)) {
	                this.ui.showRevokedKeys.show();
	                return true;
	            }
	        }, this);
        },
        
        handleClickAdd: function () {
            var apiKey = new ApiKey();
            this.showLoading();
            apiKey.save(null, null, {
                success: _.bind(this.addKeySuccess, this),
                error: _.bind(this.addKeyFailure, this)
            });
        },
        
        addKeySuccess: function (model) {
            
            this.hideLoading();
            this.apiKeys.add(model, { at: 0 }); //Add key at beginning of list
            this.handleKeyAddedToCollection(model);
        },
        
        addKeyFailure: function () {
            
            this.hideLoading();
            notificationController.showErrorNotification(messageBundle.global.unexpectedError);
        },
        
        handleKeyAddedToCollection: function (model) {
            
            var isRevokedKeyVisible = false;
            if(this.ui.showRevokedKeysCheckBox.is(':checked')) {
            	isRevokedKeyVisible = true;
            }
            this.showView(isRevokedKeyVisible);
            model.trigger('newlyAdded', this);
        },
        
        handleClickShowRevoked: function () {
        	var isRevokedKeyVisible = false;
            if(this.ui.showRevokedKeysCheckBox.is(':checked')) {
            	isRevokedKeyVisible = true;
            } 
            this.showView(isRevokedKeyVisible);
        }
    });
    
    Cocktail.mixin(KeysLayout, pageLoadIndicatorMixin);
    
    return KeysLayout;
});

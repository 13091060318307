define('mainApp',[
    'jquery',
    'underscore',
    'marionette',
    'environment',
    'views/layouts/rootLayout',
    'views/layouts/footerLayout',
    'controllers/notificationController',
    'controllers/apiKeyController',
    'controllers/storageConfigController',
    'controllers/pageLoadIndicatorController',
    'views/items/pageLoadIndicatorView',
    'constants/events'
],
function ($, _, Marionette, environment, RootLayout, FooterLayout,
 notificationController, apiKeyController, storageConfigController, PageLoadIndicatorController,
    PageLoadIndicatorView, Events) {
    'use strict';
    
    function getWAuthInfo(hdr) {
        var wAuthInfo;
        var wAuth = hdr.getResponseHeader('WWW-Authenticate');
        if (!_.isUndefined(wAuth) && !_.isEmpty(wAuth)) {
            var respJSON = hdr.responseJSON;
            if (!_.isUndefined(respJSON) && !_.isEmpty(respJSON)) {
                var errorCode = respJSON.resultCode;
                if (!_.isUndefined(errorCode) && !_.isEmpty(errorCode)) {
                    var idx = wAuth.indexOf('realm=');
                    if (idx !== -1) {
                        wAuthInfo = {};
                        wAuthInfo.errorCode = errorCode;
                        wAuthInfo.baseUrl =
                            wAuth.substring(idx + 'realm='.length, wAuth.length).replace(/"/g, '');
                    }
                }
            }
        }
        return wAuthInfo;
    }
    
    var App = new Marionette.Application({
        regions: {
            footer: '.footer',
            root: '#wrap',
            pageLoadIndicator: '#page-load-indicator-container'
        },
        
        documentClickHandler: function () {
            notificationController.hideToasts();
        },
                
        initializeJSDefaults: function () {
            $.ajaxSetup({
                cache: false,
                timeout: 30000,
                statusCode: {
                    401: function (jqXHR) {
                        //authentication exception or session timeout, redirect to login page
                        var wAuthInfo = getWAuthInfo(jqXHR);
                        if (_.isUndefined(wAuthInfo)) {
                            window.location.reload();
                        }
                        else {
                            window.location.replace(wAuthInfo.baseUrl + '?info=' + wAuthInfo.errorCode);
                        }
                    }
                }
            });
            $(document).on('click', _.bind(this.documentClickHandler, this));
        },

        initializeApiKeyController: function (apiKeysJSON) {
            apiKeyController.setApiKeyCollectionFromJSON(apiKeysJSON);
        },
        
        initializeStorageConfigController: function (storageConfigJSON) {
            storageConfigController.setStorageConfigCollectionFromJSON(storageConfigJSON);
        }
    });

    App.addInitializer(function () {
        /*jshint unused:false */
        var pageLoadController = new PageLoadIndicatorController({
            region: this.pageLoadIndicator,
            view: PageLoadIndicatorView,
            radioChannel: Events.RadioChannels.pageLoad,
            showEvent: Events.PageLoadIndicator.show,
            hideEvent: Events.PageLoadIndicator.hide
        });
        this.initializeJSDefaults();
        this.initializeApiKeyController(environment.apiKeys);
        this.initializeStorageConfigController(environment.storageConfigs);
        this.root.$el.addClass('main-app');
        this.root.show(new RootLayout());
        this.footer.show(new FooterLayout());
    });
    
    return App;
});

define('controllers/gettingStartedController',[
    'marionette',
    'backbone',
    'underscore',
    'constants/events',
    'constants/gettingStarted',
    'constants/apiKeyStatus',
    'constants/storageConfigStatus',
    'constants/viewNames',
    'frontend-common-components/gettingStartedPanel/js/views/layouts/gettingStartedLayoutView',
    'frontend-common-components/gettingStartedPanel/js/constants/events',
    'controllers/apiKeyController',
    'controllers/storageConfigController',
    'frontend-common-components/modalController/modalController',
    'views/items/transferFileHelpDialogView',
    'environment',
    'module',
    'i18n!nls/messages'
], function (Marionette, Backbone, _, Events, GettingStartedConstants, ApiKeyStatus, StorageConfigStatus, ViewNames, GettingStartedLayoutView,
    GettingStartedEvents, apiKeyController, storageConfigController, ModalController,
    TransferFileHelpDialogView, env, module, messageBundle) {
    'use strict';
    
    var statusResolvers = (function () {
        var areKeysUsable = function () {
                return _.isUndefined(apiKeyController.getFirstActiveApiKey()) === false;
            },
            doesATransferExist = function () {
                return env.transfersExist === true;
            };
            
        var returnObj = {};
        returnObj[GettingStartedConstants.Titles.createAKey] = areKeysUsable;
        returnObj[GettingStartedConstants.Titles.transfer] = doesATransferExist;
        return returnObj;
    } ());
    
    var GettingStartedController = Marionette.Object.extend({
        gettingStartedLayout: null,
        radio: null,
        sideNav: null,
        transferFileHelpDialogView: null,
        
        initialize: function () {
            this.clickHandlers = (function (ctx) {
                var returnObj = {};
                returnObj[GettingStartedConstants.Titles.createAKey] = _.bind(ctx.onClickCreateKey, ctx);
                returnObj[GettingStartedConstants.Titles.transfer] = _.bind(ctx.onClickTransferAFile, ctx)
                return returnObj;
            } (this));
            
            this.radio = Backbone.Wreqr.radio.channel(Events.RadioChannels.gettingStarted);
            this.radio.commands.setHandler(Events.GettingStarted.opened, this.showLayout, this);
            this.radio.commands.setHandler(Events.TransferFileHelp.addStorageClicked, this.onClickAddStorage, this);
            
            var storageConfigCollection = storageConfigController.getStorageConfigCollection();
            this.listenTo(storageConfigCollection, 'add', this.storageConfigAdded);

            var apiKeysCollection = apiKeyController.getApiKeyCollection();
            this.listenTo(apiKeysCollection, 'add', this.apiKeyAdded);
            this.listenTo(apiKeysCollection, 'change', this.apiKeyChanged);


            this.selector = '#modal';
            this.modalController = new ModalController({selector: this.selector});

            this.sigClientZipFileUrl = module.config().sigClientZipFileUrl;
        },
        
        onClickAddStorage: function () {
            //go to add storage page
            this.sideNav.activateSideNavItemByName(ViewNames.mainLayout.storage);
        },

        storageConfigAdded: function () {
            this.refreshItemStates();
        },

        apiKeyAdded: function () {
            this.refreshItemStates();
        },

        apiKeyChanged: function () {
            this.refreshItemStates();
        },

        onClickCreateKey: function () {
            //go to keys page
            this.sideNav.activateSideNavItemByName(ViewNames.mainLayout.keys);
        },
        
        onClickTransferAFile: function () {
            var model = this.getModelForTransferFileHelpDialogView();
            
            if (_.isEmpty(this.transferFileHelpDialogView) === false) {
                this.stopListening(this.transferFileHelpDialogView,
                    Events.GettingStarted.cliModalClosed,
                    this.hideLayout);
            }
            this.transferFileHelpDialogView = new TransferFileHelpDialogView({
                    model: model,
                    selector: this.selector,
                    gettingStartedLayout: this.gettingStartedLayout
                });

            this.listenTo(this.transferFileHelpDialogView,
                Events.GettingStarted.cliModalClosed,
                this.hideLayout);
            this.modalController.showModal(this.transferFileHelpDialogView);
        },

        getModelForTransferFileHelpDialogView: function () {
            var firstConfiguration = this.getFirstActiveConfiguration();
            var firstApiKey = apiKeyController.getFirstActiveApiKey();
            var model = new Backbone.Model();
            model.set({
                apiKey: firstApiKey.get('id'),
                secret: firstApiKey.get('secret'),
                sigClientZipFileUrl: env.sigClientZipFileUrl
            });
            if(_.isUndefined(firstConfiguration) === true) {
            	model.set('flightServer', messageBundle.gettingStarted.usEastFlightServer);
            } else {
            	model.set('configId', firstConfiguration.get('configId'));
            	model.set('flightServer', firstConfiguration.get('configuration').flightServer);
            }
            return model;
        },

        refreshItemStates: function () {
            if (_.isEmpty(this.gettingStartedLayout) === false) {
                var states = this.determineItemStates();
                _.each(states, function (value, key) {
                    this.gettingStartedLayout.updateItem(GettingStartedConstants.Titles[key], value);
                }, this);
            }
        },
        
        getGettingStartedLayout: function () {
            if (this.gettingStartedLayout === null) {
                return this.constructGettingStartedLayout();
            } else {
                return this.gettingStartedLayout;
            }
        },

        setSideNav: function (sideNav) {
            this.sideNav = sideNav;
        },

        determineItemStates: function () {
            var previousEnabledState = true;
            var allComplete = true;
            var result = _.mapObject(GettingStartedConstants.Titles, function (value, key) {
                var isCompleted =  statusResolvers[value](),
                    isEnabled = !isCompleted && previousEnabledState;
                var obj = {
                    completed: isCompleted,
                    enabled: isEnabled
                };
                
                previousEnabledState = previousEnabledState && isCompleted;
                if (!isCompleted) {
                    allComplete = false;
                }
                return obj;
            });

            //if all are complete set them all to enabled. This allows users to re-click them all.
            if (allComplete) {
                _.each(result, function (value) {
                    value.enabled = true;
                });
            }
            
            
            return result;
        },
        
        buildActionItems: function () {
            var itemStates = this.determineItemStates();
            return _.map(itemStates, function (value, key) {
                var title = GettingStartedConstants.Titles[key];

                value.title = title;
                value.events =  {
                    onClick: this.clickHandlers[title]
                };
                
                return value;
            }, this);
        },
        
        constructGettingStartedLayout: function () {
            var items = this.buildActionItems();
            
            this.gettingStartedLayout = new GettingStartedLayoutView({
                actionItems: items
            });

            this.listenTo(this.gettingStartedLayout, GettingStartedEvents.close, this.layoutClosed);
            
            this.hideLayoutIfCompleted(this.gettingStartedLayout);
            
            return this.gettingStartedLayout;
        },
        
        layoutClosed: function () {
            this.radio.commands.execute(Events.GettingStarted.closed);
        },

        showLayout: function () {
            if (_.isEmpty(this.gettingStartedLayout) === false) {
                this.refreshItemStates();
                this.gettingStartedLayout.showLayout();
            }
        },

        hideLayoutIfCompleted: function (layout) {
            if (_.isUndefined(layout)) {
                layout = this.gettingStartedLayout;
            }
            
            if (_.isEmpty(layout) === false) {
                if (layout.isComplete()) {
                    this.hideLayout(layout);
                }
            }
            
        },
        
        hideLayout: function (layout) {
            if (_.isUndefined(layout)) {
                layout = this.gettingStartedLayout;
            }

            if (_.isEmpty(layout) === false) {
                layout.hideLayout();
            }
        },

        getFirstActiveConfiguration: function () {
            var storageConfigs = storageConfigController.getStorageConfigCollection();
            return _.find(storageConfigs.models, function(config) {
            	return _.isEqual(config.get('status'), StorageConfigStatus.active);
            });
        },

    });
    
    return new GettingStartedController();
});

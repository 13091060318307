define('views/items/storageSelectionView',[
    'hbs!templates/items/storageSelection',
    'frontend-common-components/objectStorageConfig/js/views/layouts/baseAddStorageLayout',
    'frontend-common-components/objectStorageConfig/js/constants/storageConfigs',
    'frontend-common-components/objectStorageConfig/js/constants/events'
], function (storageSelectionTmpl, BaseAddStorageLayout, StorageConfigs, Events) {
    'use strict';

    return BaseAddStorageLayout.extend({
        template: storageSelectionTmpl,

        initialize: function () {
          
        },

        ui: {
            'aws': '.storage-selection-box.aws',
            'azure': '.storage-selection-box.azure'
        },

        events: {
            'click @ui.aws': 'clickAWS',
            'click @ui.azure': 'clickAzure'
        },

        clickAWS: function (event) {
            this.clickStorageType(event, StorageConfigs.Types.AWS);
        },

        clickAzure: function (event) {
            this.clickStorageType(event, StorageConfigs.Types.Azure);
        },
        
        clickStorageType: function (event, type) {
            event.stopPropagation();
            this.trigger(Events.typeSelected, type);
        }
        
    });

});

define('constants/units',[
    'lodash'
], function (_) {
    'use strict';

    var B = 'B',
        KiB = 'KiB',
        MiB = 'MiB',
        GiB = 'GiB',
        TiB = 'TiB',
        PiB = 'PiB',
        KB = 'KB',
        MB = 'MB',
        GB = 'GB',
        TB = 'TB',
        PB = 'PB';


    var unitConversion = {
        KiB: KB,
        MiB: MB,
        GiB: GB,
        TiB: TB,
        PiB: PB
    };

    return {
        fileSize: {
            B: B,
            KiB: KiB,
            MiB: MiB,
            GiB: GiB,
            TiB: TiB,
            PiB: PiB,
            KB: KB,
            MB: MB,
            GB: GB,
            TB: TB,
            PB: PB
        },

        convertToBase1000Units: function (base1024Unit) {
            var base1000Unit = unitConversion[base1024Unit];
            if (_.isUndefined(base1000Unit)) {
                
                return B;
            }

            return base1000Unit;
        }

    };
});

define('views/composite/sideNavCompositeView',[
    'underscore',
    'views/base/baseCompositeView',
    'hbs!templates/composite/sideNav',
    'views/items/sideNavItemView',
    'controllers/notificationController',
    'constants/events'
], function (_, BaseCompositeView, SideNavTmpl, SideNavItemView, notificationController, Events) {
    'use strict';
    return BaseCompositeView.extend({
        template: SideNavTmpl,
        childView: SideNavItemView,
        childViewContainer: 'ul',

        childEvents: {
            'sideNavItem:click': 'activateSideNavItem'
        },
        
        initialize: function (options) {
            if (_.isUndefined(options) ||
                _.isUndefined(options.initialView)) {
                throw {
                    name: 'Error',
                    message: 'Expected parameter initialView'
                };
            }
            this.activeSideNavItem = options.initialView;
        },

        activateSideNavItem: function (view) {
            notificationController.hideToasts();
            this.trigger(Events.SideNav.selectItem, view.model.get('name'));
            this.children.each(function (theView) {
                theView.deactivate();
            });
            view.activate();
        },
        
        activateSideNavItemByName: function (itemName) {
            var sideNavViewToActivate = this.getViewByName(itemName);
            if (_.isEmpty(sideNavViewToActivate) === false) {
                this.activateSideNavItem(sideNavViewToActivate);
            }
        },
        
        getViewByName: function (itemName) {
            return this.children.find(function (childView) {
                return childView.model.get('name') === itemName;
            });
        },

        onRender: function () {
            var closureSideNavItem = this.activeSideNavItem,
                sideNavItemView = this.children.find(function (view) {
                    return view.model.get('name') === closureSideNavItem;
                });
            
            if (_.isUndefined(sideNavItemView)) {
                throw {
                    name: 'InvalidState',
                    message: 'Could not find side nav item to activate: ' + this.activeSideNavItem
                };
            }
            
            sideNavItemView.activate();
            
        }
    });
});

define('mixins/backbone/syncEvent',[
    'underscore',
    'backbone'
], function (_, Backbone) {
    'use strict';
    function wrapSuccess(model, options, event) {
        var success = options.success;
        options.success = function (model, resp, options) {
            if (success) {
                success(model, resp, options);
            }
            model.trigger(event, model, resp, options);
        };
    }

    function wrapError(model, options, event) {
        var error = options.error;
        options.error = function (model, resp, options) {
            //the 'global' handler in (mainApp.js) ajaxSetup deals with 401 errors
            if (resp.status !== 401) {
                if (error) {
                    error(model, resp, options);
                }
                model.trigger(event, model, resp, options);
            }
        };
    }

    return {
        fetch: function (options) {
            options = options ? _.clone(options) : {};
            wrapSuccess(this, options, 'fetch:success');
            wrapError(this, options, 'fetch:error');

            this.trigger('before:fetch', this);
            return Backbone.Model.prototype.fetch.call(this, options);
        },

        save: function (key, val, options) {
            if ((key === null || typeof key === 'object') && val) {
                options = _.clone(val);
            } else {
                options = options ? _.clone(options) : {};
            }

            wrapSuccess(this, options, 'save:success');
            wrapError(this, options, 'save:error');

            this.trigger('before:save', this);
            /*jshint -W116*/
            if (key == null || typeof key === 'object') {
                return Backbone.Model.prototype.save.call(this, key, options, options);
            } else {
                return Backbone.Model.prototype.save.call(this, key, val, options);
            }
            /*jshint +W116*/
        },

        destroy: function (options) {
            options = options ? _.clone(options) : {};
            wrapSuccess(this, options, 'destroy:success');
            wrapError(this, options, 'destroy:error');

            this.trigger('before:destroy', this);
            return Backbone.Model.prototype.destroy.call(this, options);
        }
    };
});


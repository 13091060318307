
/* START_TEMPLATE */
define('hbs!templates/items/generateSecretKeyDialogViewTmpl',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div>\n    <p>"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "keys.createSecretKeyConfirmation", options) : helperMissing.call(depth0, "i18n", "keys.createSecretKeyConfirmation", options)))
    + "</p>\n	</br>\n	<p>\n	    <button id=\"createSecretKeyContinueBtn\" type=\"button\" class=\"btn btn-primary\" autofocus=\"autofocus\" data-dismiss=\"modal\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "keys.confirmationButton", options) : helperMissing.call(depth0, "i18n", "keys.confirmationButton", options)))
    + "</button>\n		<button id=\"createSecretKeyCancelBtn\" type=\"button\" class=\"btn btn-default\" data-dismiss=\"modal\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "keys.cancelButton", options) : helperMissing.call(depth0, "i18n", "keys.cancelButton", options)))
    + "</button>\n	</p>\n</div>\n";
  return buffer;
  });
Handlebars.registerPartial('templates/items/generateSecretKeyDialogViewTmpl', t);
return t;
});
/* END_TEMPLATE */
;

define('models/storageConfig',[
    'models/base/baseModel',
    'helpers/skydropContextHelper'
],
function (BaseModel, contextHelper) {
    'use strict';
    return BaseModel.extend({
        idAttribute: 'configId',
        
        url: function () {
            return contextHelper.getStorageConfigURL();
        },
        
        updateUrl: function () {
            return contextHelper.getStorageConfigURL() + this.id;
        },
        
        defaults: {}
    });
});

define('views/items/mainLogoView',[
    'views/base/baseItemView',
    'hbs!templates/items/mainLogo'
], function (BaseItemView, MainLogoTmpl) {
    'use strict';
    return BaseItemView.extend({
        template: MainLogoTmpl,

        initialize: function () {
        },

        onRender: function () {
        }
    });
});

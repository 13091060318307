
/* START_TEMPLATE */
define('hbs!frontend-common-components/objectStorageConfig/templates/layouts/addStorage',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"row\">\n    <div class=\"col-xs-3 add-button add-storage-config\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "storage.addLabel", options) : helperMissing.call(depth0, "i18n", "storage.addLabel", options)))
    + "</div>\n    <div class=\"panel-content\">\n        <button type=\"button\" class=\"close pull-right always-on-top\" aria-hidden=\"true\">&times;</button>\n        <div class=\"add-storage-body\">\n        </div>\n    </div>\n</div>";
  return buffer;
  });
Handlebars.registerPartial('frontend-common-components/objectStorageConfig/templates/layouts/addStorage', t);
return t;
});
/* END_TEMPLATE */
;

define('views/items/resendActivationView',[
    'jquery',
    'underscore',
    'backbone',
    'constants/properties',
    'i18n!nls/messages',
    'validation/sigValidator',
    'hbs!templates/items/resendActivation',
    'views/base/baseItemView',
    'constants/events',
    'mixins/pageLoadIndicatorMixin',
    'models/resendActivationModel',
    'cocktail'
], function ($, _, Backbone, properties, messageBundle, validator,
             resendActivationTmpl, BaseItemView, Events, pageLoadIndicatorMixin,
             ResendActivationModel, Cocktail) {
    'use strict';

    var ResendActivationView = BaseItemView.extend({
        template: resendActivationTmpl,
        
        ui: {
            errorMessageArea: '#error-msg-area',
            activateButton: '#activate-btn',
            mainView: '.main-resend-view',
            successView: '.success-resend-view',
            successEmail: '#sentEmail'
        },

        events: {
            'submit #resend-activation-form': 'handleFormSubmit'
        },


        handleFormSubmit: function () {
            this.showLoading();
            this.model = new ResendActivationModel();
            this.model.save(null, {
                success: _.bind(this.handleSaveSuccess, this),
                error: _.bind(this.handleSaveError, this)
            });
            return false;
        },

        handleSaveError: function () {
            this.hideLoading();
            this.showError(messageBundle.global.unexpectedError);
        },

        handleSaveSuccess: function (model) {
            this.hideLoading();
            this.showSuccessView(model.get('email'));
        },

        showError: function (message) {
            this.ui.errorMessageArea.addClass('text-error');
            this.ui.errorMessageArea.text(message);
        },
        
        showSuccessView: function (email) {
            this.ui.mainView.hide();
            this.ui.successEmail.text(email);
            this.ui.successView.show();
        }
    });

    Cocktail.mixin(ResendActivationView, pageLoadIndicatorMixin);

    return ResendActivationView;
});

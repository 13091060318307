
/* START_TEMPLATE */
define('hbs!templates/items/totalTransfers',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var stack1, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += "\n    <div class=\"aggregate-usage upload row\">\n        <div class=\"upload-icon col-md-2 pull-left ";
  if (helper = helpers.uploadArrowClass) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.uploadArrowClass); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"/>\n        <div class=\"upload-text col-md-10 pull-left\">\n            "
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.formattedDownloadedBytes)),stack1 == null || stack1 === false ? stack1 : stack1.value)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + " "
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.formattedDownloadedBytes)),stack1 == null || stack1 === false ? stack1 : stack1.unit)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + " "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "overview.usageUploaded", options) : helperMissing.call(depth0, "i18n", "overview.usageUploaded", options)))
    + "\n        </div>\n    </div>\n    <div class=\"row spacer\"/>\n    <div class=\"aggregate-usage download row\">\n        <div class=\"download-icon col-md-2 pull-left ";
  if (helper = helpers.downloadArrowClass) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.downloadArrowClass); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"/>\n        <div class=\"download-text col-md-10 pull-left\">\n            "
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.formattedUploadedBytes)),stack1 == null || stack1 === false ? stack1 : stack1.value)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + " "
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.formattedUploadedBytes)),stack1 == null || stack1 === false ? stack1 : stack1.unit)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + " "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "overview.usageDownloaded", options) : helperMissing.call(depth0, "i18n", "overview.usageDownloaded", options)))
    + "\n        </div>\n    </div>\n";
  return buffer;
  }

  stack1 = helpers['if'].call(depth0, (depth0 && depth0.formattedDownloadedBytes), {hash:{},inverse:self.noop,fn:self.program(1, program1, data)});
  if(stack1 || stack1 === 0) { return stack1; }
  else { return ''; }
  });
Handlebars.registerPartial('templates/items/totalTransfers', t);
return t;
});
/* END_TEMPLATE */
;

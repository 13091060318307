define('validation/sigValidator',[
    'validator',
    'validation/emailValidation',
    'validation/passwordValidation'
], function (validator, emailValidation, passwordValidation) {
    'use strict';
    validator.extend('isSigEmail', emailValidation);
    validator.extend('isPassword', passwordValidation);

    return validator;
});

define('signupApp',[
	'jquery',
	'backbone',
    'marionette',
    'environment',
    'views/layouts/signupLayout',
    'views/items/signupView',
    'views/layouts/footerLayout',
    'controllers/pageLoadIndicatorController',
    'views/items/pageLoadIndicatorView',
    'constants/events'
],
function ($, Backbone, Marionette, environment, SignupLayout, SignupView, FooterLayout,
    PageLoadIndicatorController, PageLoadIndicatorView, Events) {
	
    'use strict';
    
    var SignupApp = Marionette.Application.extend({
    	
    	initialize: function () {
    		/*jshint unused:false */
            var pageLoadController = new PageLoadIndicatorController({
                region: this.pageLoadIndicator,
                view: PageLoadIndicatorView,
                radioChannel: Events.RadioChannels.pageLoad,
                showEvent: Events.PageLoadIndicator.show,
                hideEvent: Events.PageLoadIndicator.hide
            });
            
            this.root.show(new SignupLayout({ view: new SignupView() }));
            this.footer.show(new FooterLayout());
    	}
    });

    return new SignupApp({
        regions: {
            root: '#wrap',
            footer: '.footer',
            pageLoadIndicator: '#page-load-indicator-container'
        }
    });
});

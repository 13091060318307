define('views/items/activateAccountView',[
    'jquery',
    'underscore',
    'backbone',
    'constants/properties',
    'i18n!nls/messages',
    'validation/sigValidator',
    'hbs!templates/items/activateAccount',
    'models/activateAccountModel',
    'views/base/baseItemView',
    'constants/events',
    'mixins/pageLoadIndicatorMixin',
    'cocktail',
    'jquery.placeholder'
], function ($, _, Backbone, properties, messageBundle, validator,
             activateAccountTmpl, ActivateAccountModel, BaseItemView,
             Events, pageLoadIndicatorMixin, Cocktail) {
    'use strict';

    var ActivateAccountView = BaseItemView.extend({
        template: activateAccountTmpl,

        initialize: function (options) {
            this.model = new ActivateAccountModel();
            this.authToken = options.authToken;
            this.userId = options.userId;
            this.listenTo(this.model, 'error', this.handleSaveError, this);
            this.listenTo(this.model, 'save:success', this.handleSaveSuccess, this);
        },

        ui: {
            newPasswordField: '#new-password-field',
            newPasswordFieldWrapper: '#new-password-field-wrapper',
            confirmPasswordField: '#confirm-password-field',
            errorMessageArea: '#error-msg-area',
            activateButton: '#activate-btn'
        },

        events: {
            'submit #reset-password-form': 'handleFormSubmit',
            'keyup @ui.newPasswordField': 'handleKeyUp',
            'keydup @ui.confirmPasswordField': 'handleKeyUp'
        },

        handleKeyUp: function () {
            if (this.ui.newPasswordField.val() !== '') {
                if (!this.isValidPassword(this.ui.newPasswordField)) {
                    this.showError(messageBundle.changePassword.invalidPassword, this.ui.newPasswordFieldWrapper);
                    this.ui.activateButton.addClass('disabled');
                } else {
                    this.ui.activateButton.removeClass('disabled');
                    this.clearErrors();
                }
            }
        },

        handleFormSubmit: function () {
            if (!this.isEmpty(this.ui.newPasswordField)) {

            	if (!this.validatePasswords()) {
            		
            		return false;
            	}

                this.model.set('newPassword', this.ui.newPasswordField.val());
                if (this.triggerMethod('before:save')) {
                	this.save();
                	this.showLoading();
                } 
            } 
            
            return false;
        },
        
        validatePasswords: function () {
            if (!this.isValidPassword(this.ui.newPasswordField)) {
                this.showError(messageBundle.changePassword.invalidPassword, this.ui.newPasswordField);
                return false;
            }

            if (!this.arePasswordsEqual()) {
                this.showError(messageBundle.changePassword.passwordsDoNotMatch);
                this.resetForm();

                this.focusField(this.ui.newPasswordField.focus());

                return false;
            }
            
            return true;
        },

        save: function () {
            var headers = {};
            headers[properties.rest.skydropXHeader] = this.authToken;
            this.model.save({}, {
                    type: 'put',
                    headers: headers
                }
            );
        },
        
        resetForm: function () {
            this.ui.newPasswordField.val('');
            this.ui.confirmPasswordField.val('');
            this.ui.activateButton.addClass('disabled');
        },

        arePasswordsEqual: function () {
            var newPassword = this.ui.newPasswordField.val();
            var confirmPassword = this.ui.confirmPasswordField.val();

            return _.isEqual(newPassword, confirmPassword);
        },

        isValidPassword: function (passwordField) {
            var newPassword = passwordField.val();

            return validator.isPassword(newPassword);
        },

        handleSaveError: function (model, response) {
            this.hideLoading();
            if (response.status === 401) {
            	Backbone.Wreqr.radio.commands.execute(Events.RadioChannels.loginRouter,
                    Events.LoginRouter.route, '#activateResend/' + this.userId);
            } else if (response.status === 400) {
        		this.showError(messageBundle.login.mustSetEmail);
        	}
        },

        handleSaveSuccess: function () {
            this.hideLoading();
            this.showSuccessView();
        },

        showError: function (message, field, errorMessageArea) {
            if (field) {
                this.markErrorField(field);
            }

            if (errorMessageArea) {
            	errorMessageArea.addClass('text-error');
            	errorMessageArea.text(message);
            } else {           	
	            this.ui.errorMessageArea.addClass('text-error');
	            this.ui.errorMessageArea.text(message);
            }
        },

        clearErrors: function () {
            this.clearErrorField(this.ui.newPasswordFieldWrapper);
            this.ui.errorMessageArea.text('');
            this.ui.errorMessageArea.removeClass('text-error');
        },

        showSuccessView: function () {
            Backbone.Wreqr.radio.commands.execute(Events.RadioChannels.loginRouter,
                Events.LoginRouter.route, '#activateSuccess');
        },

        onShow: function () {
            $('input[placeholder]').placeholder();
            this.focusField(this.ui.newPasswordField);
        },

        focusField: function (field) {
            if (!this.isIE()) {
                field.focus();
            }
        },
        
        onBeforeSave: function () {
        	return true;
        }
        
    });

    Cocktail.mixin(ActivateAccountView, pageLoadIndicatorMixin);

    return ActivateAccountView;
});

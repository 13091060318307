define('controllers/notificationController',[
        'marionette',
        'jquery',
        'jquery.jgrowl'
    ],
    function (Marionette, $) {
        'use strict';

        var NotificationController = Marionette.Controller.extend({

            initialize: function () {
                this.setDefaults();
            },
            
            showNotification: function (message, options) {
                $.jGrowl(message, options);
            },
            
            showErrorNotification: function (message) {
                this.showNotification(message, {
                    theme: 'error',
                    sticky: true,
                    closeTemplate: '<i class="fa fa-times"></i>'
                });
            },
            
            showInfoNotification: function (message) {
                this.showNotification(message, {
                    theme: 'info',
                    life: 2000,
                    sticky: false,
                    closeTemplate: '<i class="fa fa-times"></i>'
                });
            },

            hideToasts: function () {
                $('div.jGrowl').find('.jGrowl-close').closest(
                        'div.jGrowl-notification').addClass('hide').trigger('jGrowl.close');
            },
            
            setDefaults: function () {
                $.jGrowl.defaults.closer = false;
                $.jGrowl.defaults.position = 'center';
                $.jGrowl.defaults.pool = 0;
                $.jGrowl.defaults.check = 10;
            }

        });

        return new NotificationController();
    });

define('views/items/activateAccountWithEmailView',[
    'jquery',
    'underscore',
    'backbone',
    'constants/properties',
    'i18n!nls/messages',
    'validation/sigValidator',
    'hbs!templates/items/activateAccountWithEmail',
    'models/activateAccountModel',
    'views/items/activateAccountView',
    'constants/events',
    'mixins/pageLoadIndicatorMixin',
    'validation/emailValidation',
    'controllers/notificationController',
    'cocktail',
    'jquery.placeholder'
], function ($, _, Backbone, properties, messageBundle, validator,
             activateAccountTmpl, ActivateAccountModel, ActivateAccountView,
             Events, pageLoadIndicatorMixin, emailValidator, notificationController, Cocktail) {
    'use strict';

    var ActivateAccountWithEmailView = ActivateAccountView.extend({
        template: activateAccountTmpl,

        ui: {
            newPasswordField: '#new-password-field',
            newPasswordFieldWrapper: '#new-password-field-wrapper',
            confirmPasswordField: '#confirm-password-field',
            errorMessageArea: '#error-msg-area',
            activateButton: '#activate-btn',
            newEmailField: '#new-email-field',
            newEmailFieldWrapper: '#new-email-field-wrapper',
            newEmailErrorMsg: '#email-error-message-area'
        },

        events: {
            'submit #reset-password-form': 'handleFormSubmit',
            'keyup @ui.newPasswordField': 'handleKeyUp',
            'keyup @ui.confirmPasswordField': 'handleKeyUp',
            
            'keyup @ui.newEmailField': 'handleEmailKeyUp',
            'focusin @ui.newEmailField': 'clearEmailErrors'
        },

        handleEmailKeyUp: function () {
            if (this.validateEmail()) {
        		this.clearEmailErrors();
                this.enablePasswordFields();
        	} else {
                this.disablePasswordFields();
                this.clearErrors();
                this.resetForm();
            }
        },

        onShow: function () {
            $('input[placeholder]').placeholder();
            this.focusField(this.ui.newEmailField);
        },

        clearEmailErrors: function () {
        	this.clearErrorField(this.ui.newEmailFieldWrapper);
            this.ui.newEmailErrorMsg.text('');
        },
        
        validateEmail: function () {
        	var email = this.ui.newEmailField.val();
        	if (!emailValidator(email)) {
        		this.showError(messageBundle.login.invalidEmailErrorMessage, this.ui.newEmailFieldWrapper, this.ui.newEmailErrorMsg);
        		return false;
        	}
        	
        	return true;
        },

        enablePasswordFields: function () {
            this.ui.newPasswordField.prop('disabled', false);
            this.ui.confirmPasswordField.prop('disabled', false);
        },

        disablePasswordFields: function () {
            this.ui.newPasswordField.prop('disabled', true);
            this.ui.confirmPasswordField.prop('disabled', true);
        },
        
        onBeforeSave: function () {
        	if (this.validateEmail()) {
        		this.model.set('newEmail', this.ui.newEmailField.val().trim());
        		return true;
        	} else {
                this.disablePasswordFields();
                this.clearErrors();
                this.resetForm();
            }
        	return false;
        },
        
        handleSaveError: function (model, response) {
        	this.hideLoading();
        	if (response.status === 401) {
        		Backbone.Wreqr.radio.commands.execute(Events.RadioChannels.loginRouter,
        				Events.LoginRouter.route, '#activateResend/' + this.userId);
        	} else if (response.status === 409) {
        		this.showError(messageBundle.login.emailInUse, this.ui.newEmailFieldWrapper, this.ui.newEmailErrorMsg);
        	} else if (response.status === 400) {
        		this.showError(messageBundle.login.cannotEditEmail, this.ui.newEmailFieldWrapper, this.ui.newEmailErrorMsg);
        	} else {
        		notificationController.showErrorNotification(messageBundle.global.unexpectedError);
        	}
        },
    });

    Cocktail.mixin(ActivateAccountView, pageLoadIndicatorMixin);

    return ActivateAccountWithEmailView;
});


/* START_TEMPLATE */
define('hbs!templates/items/sideNavItem',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<a href=\"#\">\n    <div class=\"sidenav-indicator\"></div>\n    <div class=\"sidenav-text\">";
  if (helper = helpers.localizedName) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.localizedName); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n    <div class=\"sidenav-icon icon-flight_";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" title=\"";
  if (helper = helpers.name) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.name); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + " icon\"></div>\n</a>\n";
  return buffer;
  });
Handlebars.registerPartial('templates/items/sideNavItem', t);
return t;
});
/* END_TEMPLATE */
;


/* START_TEMPLATE */
define('hbs!templates/items/transferFileDialogViewTmpl',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "--secretkey ";
  if (helper = helpers.secret) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.secret); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + " ";
  return buffer;
  }

function program3(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += " --configid ";
  if (helper = helpers.configId) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.configId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + " ";
  return buffer;
  }

function program5(depth0,data) {
  
  var buffer = "", stack1, helper, options;
  buffer += " --access-key ";
  if (helper = helpers.flightTrialAWSAccessKeyId) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.flightTrialAWSAccessKeyId); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + " --secret-key ";
  if (helper = helpers.flightTrialAWSSecretAccessKey) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.flightTrialAWSSecretAccessKey); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + " "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.trialS3Bucket", options) : helperMissing.call(depth0, "i18n", "gettingStarted.trialS3Bucket", options)));
  return buffer;
  }

function program7(depth0,data) {
  
  var buffer = "", helper, options;
  buffer += "\n					"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.transfersToPublicBucket", options) : helperMissing.call(depth0, "i18n", "gettingStarted.transfersToPublicBucket", options)))
    + "<br><br>\n					"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.replaceCredentials", options) : helperMissing.call(depth0, "i18n", "gettingStarted.replaceCredentials", options)))
    + "<br>"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.or", options) : helperMissing.call(depth0, "i18n", "gettingStarted.or", options)))
    + " <a id=\"add-storage-link\" class=\"obvious-link\" href=\"#\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.addStorageLink", options) : helperMissing.call(depth0, "i18n", "gettingStarted.addStorageLink", options)))
    + "</a>"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.useTempCredentials", options) : helperMissing.call(depth0, "i18n", "gettingStarted.useTempCredentials", options)))
    + "<br><br>\n				";
  return buffer;
  }

  buffer += "<div class=\"transfer-help-dialog-box modal-content\">\n    <div class=\"transfer-help-close\">\n        <a class=\"transfer-help-close-btn\"><i class=\"fa fa-times fa-2x\"></i></a>\n    </div>\n    <div class=\"modal-body dialog-body\">\n        <div class=\"transfer-help-cli-download\">\n            <div class=\"download-client-text\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.downloadClientText", options) : helperMissing.call(depth0, "i18n", "gettingStarted.downloadClientText", options)))
    + "</div>\n            <div class=\"download-cli-button\">\n                <a href=\"";
  if (helper = helpers.sigClientZipFileUrl) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.sigClientZipFileUrl); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" class=\"btn btn-primary btn-lg\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.downloadButton", options) : helperMissing.call(depth0, "i18n", "gettingStarted.downloadButton", options)))
    + "</a>\n            </div>\n            <div class=\"download-client-versions-text\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.downloadCliVersion", options) : helperMissing.call(depth0, "i18n", "gettingStarted.downloadCliVersion", options)))
    + "</div>\n        </div>\n        <div class=\"transfer-help-command\">\n            <div class=\"transfer-help-command-text\">\n                "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.commandLineUsage", options) : helperMissing.call(depth0, "i18n", "gettingStarted.commandLineUsage", options)))
    + "<a class=\"obvious-link\" target=\"_blank\" href="
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.flightCliGuide", options) : helperMissing.call(depth0, "i18n", "gettingStarted.flightCliGuide", options)))
    + ">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.commandLineUsageLinkMessage", options) : helperMissing.call(depth0, "i18n", "gettingStarted.commandLineUsageLinkMessage", options)))
    + "</a>\n            </div>\n            <div class=\"transfer-help-command-box\">\n                <pre class=\"transfer-help-command-dtls\">./sigcli --apikey ";
  if (helper = helpers.apiKey) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.apiKey); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + " ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.secretExists), {hash:{},inverse:self.noop,fn:self.program(1, program1, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " --direction upload --server ";
  if (helper = helpers.flightServer) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.flightServer); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + " ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.hasStorageAdded), {hash:{},inverse:self.program(5, program5, data),fn:self.program(3, program3, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += " <strong>&lt;localPath&gt;</strong> sig://<strong>&lt;remotePath&gt;</strong></pre>\n            </div>\n            <div class=\"transfer-help-info-text\">\n				";
  stack1 = helpers.unless.call(depth0, (depth0 && depth0.hasStorageAdded), {hash:{},inverse:self.noop,fn:self.program(7, program7, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.serverLocationHelpMessage", options) : helperMissing.call(depth0, "i18n", "gettingStarted.serverLocationHelpMessage", options)))
    + "<a class=\"obvious-link\" target=\"_blank\" href="
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.flightServerLocations", options) : helperMissing.call(depth0, "i18n", "gettingStarted.flightServerLocations", options)))
    + ">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "gettingStarted.serverLocationLinkMessage", options) : helperMissing.call(depth0, "i18n", "gettingStarted.serverLocationLinkMessage", options)))
    + "</a>\n            </div>\n        </div>\n    </div>\n</div>\n";
  return buffer;
  });
Handlebars.registerPartial('templates/items/transferFileDialogViewTmpl', t);
return t;
});
/* END_TEMPLATE */
;

define('views/layouts/footerLayout',[
    'views/base/baseLayoutView',
    'hbs!templates/layouts/footer',
    'views/items/footerLinksView'
], function (BaseLayoutView, FooterLayoutTmpl, FooterLinksView) {
    'use strict';
    return BaseLayoutView.extend({
        
        className: 'sticky-footer',
        template: FooterLayoutTmpl,

        initialize: function () {
        },

        regions: {
            'linksRegion': '#linksRegion'
        },
        
        onShow: function () {
            this.linksRegion.show(new FooterLinksView());
        }
    });
});

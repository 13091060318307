define('controllers/apiKeyController',[
    'marionette',
    'constants/apiKeyStatus',
    'helpers/skydropContextHelper',
    'collections/apiKeyCollection',
    'models/apiKey',
    'underscore'
], function (Marionette, ApiKeyStatus, skydropContextHelper, ApiKeyCollection, ApiKey, _) {
    'use strict';

    var ApiKeyController = Marionette.Object.extend({
        initialize: function () {
            this.apiKeyCollection = new ApiKeyCollection();
        },
        
        setApiKeyCollection: function (apiKeyCollection) {
            this.apiKeyCollection.set(apiKeyCollection.models);
        },
        
        setApiKeyCollectionFromJSON: function (apiKeyCollectionJSON) {
            var models = _.map(JSON.parse(apiKeyCollectionJSON), function (model) {
                return new ApiKey(model);
            });
            this.apiKeyCollection.set(models, { silent: true });
        },
        
        getApiKeyCollection: function () {
            return this.apiKeyCollection;
        },
        
        getApiKeyName: function (apiKeyId) {
            var apiKey = this.apiKeyCollection.get(apiKeyId);
            if (_.isUndefined(apiKey)) {
                return null;
            }
            return apiKey.get('name');
        },

        getFirstActiveApiKey: function () {
            var firstActiveApiKey;

            if (_.isUndefined(this.apiKeyCollection) === false && this.apiKeyCollection.length > 0) {
                firstActiveApiKey = this.apiKeyCollection.find(function (model) {
                    return model.get('status') === ApiKeyStatus.active;
                });
            }

            return firstActiveApiKey;
        },
    });
    return new ApiKeyController();

});

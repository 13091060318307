define('libs/handlebars-helpers/i18n',[
        'hbs',
        'i18n!nls/messages',
        'libs/deepGet'
    ],
    function (hbs, messageBundle, deepGet) {
        'use strict';
        function i18nHelper(key) {
            return deepGet(messageBundle, key, key);
        }

        hbs.get().registerHelper('i18n', i18nHelper);

        return i18nHelper;
    });

define('constants/storageConfigs',[],function () {
    'use strict';

    return {
        Types: {
            'AWS': 'AWS',
            'Azure': 'AZURE'
        }
    };
});

define('views/layouts/storageLayout',[
    'views/base/baseLayoutView',
    'views/composite/storageConfigsView',
    'views/items/emptyMessageView',
    'views/layouts/addStorageLayout',
    'models/awsStorageConfig',
    'models/storageConfig',
    'collections/storageConfigCollection',
    'frontend-common-components/objectStorageConfig/js/constants/storageConfigs',
    'hbs!templates/layouts/storage',
    'controllers/storageConfigController',
    'constants/storageConfigStatus',
    'helpers/storageHelper',
    'constants/events',
    'frontend-common-components/objectStorageConfig/js/constants/events',
    'i18n!nls/messages',
    'underscore'
], function (
    BaseLayoutView,
    StorageConfigsView,
    EmptyMessageView,
    AddStorageLayout,
    AWSStorageConfig,
    StorageConfig,
    StorageConfigs,
    StorageConfigConstants,
    storageLayoutTmpl,
    storageConfigController,
    StorageConfigStatus,
    storageHelper,
    Events,
    ObjectStorageEvents,
    messageBundle,
    _
) {
    'use strict';
    function convertToCollectionModel(config) {
        var rawConfig = config.toJSON();

        return new StorageConfig({
            configId: rawConfig.configId,
            configuration: rawConfig.configuration,
            status: rawConfig.status
        });
    }
    
    function getFilePrefix(filePrefix) {
        if (filePrefix && !storageHelper.storagePrefixEndsWithSlash(filePrefix)) {
            filePrefix = filePrefix + '/';
        }
        return filePrefix;
    }

    var StorageLayout = BaseLayoutView.extend({
        template: storageLayoutTmpl,

        initialize: function () {
            this.storageConfigs = storageConfigController.getStorageConfigCollection();
        },

        ui: {
            addStorage: '#storage-add',
            showRevokedStorageConfigs: '#showRevokedStorageConfigsWrapper',
            showRevokedStorageConfigsCheckBox: '#showRevokedStorageConfigsCheckBox'
        },
        
        events: {
            'click  @ui.addStorage': 'showAddStorageView',
            'click  @ui.showRevokedStorageConfigsCheckBox': 'handleClickShowRevokedConfig'
        },

        regions: {
            storages: '.table-area',
            addStorage: '.add-storage-region'
        },

        onShow: function () {
            this.showView(false);
        },
        
        showView: function (isRevokedStorageConfigVisible) {
            var size = this.storageConfigs.size();
            if (size > 0) {
                
                this.storages.show(new StorageConfigsView({
                	isRevokedStorageConfigVisible: isRevokedStorageConfigVisible,
                    collection: this.storageConfigs
                }));
                
                this.toggleShowRevokedCheckBox(this.storageConfigs);
            } else {
                
                this.storages.show(new EmptyMessageView({
                    message: messageBundle.storage.empty
                }));
                
                this.showAddStorageView();
            }
        },
        
        toggleShowRevokedCheckBox: function (storageConfigs) {
        	_.some(storageConfigs.models, function (storageConfig) {
	            if (_.isEqual(storageConfig.get('status'), StorageConfigStatus.revoked)) {
	                this.ui.showRevokedStorageConfigs.show();
	                return true;
	            }
	        }, this);
        },
                
        showAddStorageView: function () {
            this.addStorageView = new AddStorageLayout();
            this.listenTo(this.addStorageView, ObjectStorageEvents.awsConfigAdded, this.storageConfigAdded);
            this.listenTo(this.addStorageView, ObjectStorageEvents.azureConfigAdded, this.storageConfigAdded);
            this.listenTo(this.addStorageView, ObjectStorageEvents.closeAddStorage, this.closeAddStorageView);
            this.addStorage.show(this.addStorageView);
        },

        addConfig: function (config) {
            this.storageConfigs.add(config, { at: 0 }); //Add key at beginning of list
            this.handleClickShowRevokedConfig(config);
        },

        stopListeningToEvents: function () {
            this.stopListening(this.addStorageView, ObjectStorageEvents.awsConfigAdded, this.storageConfigAdded);
            this.stopListening(this.addStorageView, ObjectStorageEvents.azureConfigAdded, this.storageConfigAdded);
            this.stopListening(this.addStorageView, ObjectStorageEvents.closeAddStorage, this.closeAddStorageView);
        },

        storageConfigAdded: function (config) {
            var convertedConfig = convertToCollectionModel(config);
            this.addConfig(convertedConfig);
        },

        closeAddStorageView: function () {
            this.stopListeningToEvents();
            this.addStorage.empty();
        },
        
        handleClickShowRevokedConfig: function () {
        	var isRevokedStorageConfigVisible = false;
        	if(this.ui.showRevokedStorageConfigsCheckBox.is(':checked')){
        		isRevokedStorageConfigVisible = true;
        	}
            this.showView(isRevokedStorageConfigVisible);
        }
    });
    
    return StorageLayout;
});

define('views/items/emptyMessageView',[
    'models/base/baseModel',
    'views/base/baseItemView',
    'hbs!templates/items/emptyMessageView',
], function (
    BaseModel,
    BaseItemView,
    emptyMessageViewTmpl
) {
    'use strict';
    return BaseItemView.extend({
        tagName: 'span',
        className: 'emptyMessage',
        template: emptyMessageViewTmpl,
        initialize: function (options) {
            this.model = new BaseModel({ message: options.message });
        }
    });
});

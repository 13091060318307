define('views/items/generateSecretKeyDialogView',[
    'views/base/baseItemView',
    'hbs!templates/items/generateSecretKeyDialogViewTmpl'
],
function (
    BaseItemView,
    generateSecretKeyDialogViewTmpl
) {
    'use strict';

    return BaseItemView.extend({
    	className: 'generate-secret-key-dialog-box modal-content',
        template: generateSecretKeyDialogViewTmpl,
        
        ui: {
        	okBtn: '#createSecretKeyContinueBtn'
        },

        events: {
        	'click @ui.okBtn': 'okNavigation'
        },
        
        okNavigation: function () {
            this.options.callback();
        }
    });
});


/* 
 * All runtime global properties
 */
define('constants/properties',[], function () {
	'use strict';
	var V1_REST_ROOT = 'REST/v1.0/';
    return {
    
        skydrop: {
            services: {
                baseURL: V1_REST_ROOT + 'account/',
                restRoot: V1_REST_ROOT
            }
        },
    
        rest: {
            skydropXHeader: 'X-SKYDROP-SESSION-TOKEN'
        }
    };

});





/* START_TEMPLATE */
define('hbs!templates/items/login',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, self=this, helperMissing=helpers.helperMissing;

function program1(depth0,data) {
  
  var stack1, helper;
  if (helper = helpers.lastLogin) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.lastLogin); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  return escapeExpression(stack1);
  }

function program3(depth0,data) {
  
  var stack1, helper;
  if (helper = helpers.lastSuccessfulLogin) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.lastSuccessfulLogin); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  return escapeExpression(stack1);
  }

  buffer += "<form id=\"skydrop-admin-login-form\" action=\"\" method=\"POST\" role=\"form\" class=\"skydrop-admin-panel-form\">\n    <input type=\"hidden\" name=\"remember_me\" value=\"true\" />\n\n    <div id=\"skydrop-admin-login-username-wrapper\" class=\"form-element-wrapper has-feedback\">\n        <input id=\"skydrop-admin-login-username\" class=\"form-control\" type=\"text\" name=\"username\" value=\"";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.loginFailed), {hash:{},inverse:self.program(3, program3, data),fn:self.program(1, program1, data)});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\" placeholder=\""
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "login.emailPlaceholderText", options) : helperMissing.call(depth0, "i18n", "login.emailPlaceholderText", options)))
    + "\" />\n        <span class=\"fa fa-exclamation form-control-feedback\"/>\n    </div>\n    <div id=\"skydrop-admin-login-password-wrapper\" class=\"form-element-wrapper has-feedback\">\n        <input id=\"skydrop-admin-login-password\" class=\"form-control\" type=\"password\" name=\"password\" value=\"\" placeholder=\""
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "login.passwordPlaceholderText", options) : helperMissing.call(depth0, "i18n", "login.passwordPlaceholderText", options)))
    + "\"/>\n        <span class=\"fa fa-exclamation form-control-feedback\"/>\n    </div>\n    <div class=\"form-element-wrapper\">\n        <button type=\"submit\" class=\"btn btn-primary\" id=\"skydrop-admin-login-btn\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "login.signinButtonText", options) : helperMissing.call(depth0, "i18n", "login.signinButtonText", options)))
    + "</button>\n    </div>\n\n    <a href=\"#forgotPassword\" class=\"text-muted\" id=\"skydrop-admin-login-forgot-password-link\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "login.forgotPasswordLinkText", options) : helperMissing.call(depth0, "i18n", "login.forgotPasswordLinkText", options)))
    + "</a>\n</form>\n<div class=\"skydrop-admin-panel-dialog-message\"/>";
  return buffer;
  });
Handlebars.registerPartial('templates/items/login', t);
return t;
});
/* END_TEMPLATE */
;

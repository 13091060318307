
/* START_TEMPLATE */
define('hbs!templates/items/periodicTransferGraphTooltip',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing;


  buffer += "<div class=\"graphtooltip\">\n    <div class=\"graphtooltip-upload\">"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.upload)),stack1 == null || stack1 === false ? stack1 : stack1.value)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + " "
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.upload)),stack1 == null || stack1 === false ? stack1 : stack1.unit)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + " "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "overview.usageUploaded", options) : helperMissing.call(depth0, "i18n", "overview.usageUploaded", options)))
    + "</div>\n    <div class=\"graphtooltip-download\">"
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.download)),stack1 == null || stack1 === false ? stack1 : stack1.value)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + " "
    + escapeExpression(((stack1 = ((stack1 = (depth0 && depth0.download)),stack1 == null || stack1 === false ? stack1 : stack1.unit)),typeof stack1 === functionType ? stack1.apply(depth0) : stack1))
    + " "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "overview.usageDownloaded", options) : helperMissing.call(depth0, "i18n", "overview.usageDownloaded", options)))
    + "</div>\n    <br>\n    <div class=\"graphtooltip-date\">";
  if (helper = helpers.date) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.date); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n</div>";
  return buffer;
  });
Handlebars.registerPartial('templates/items/periodicTransferGraphTooltip', t);
return t;
});
/* END_TEMPLATE */
;

define('views/composite/apiKeysView',[
    'views/base/baseCompositeView',
    'views/items/apiKeyView',
    'hbs!templates/composite/apiKeys'
], function (
    BaseCompositeView,
    ApiKeyView,
    apiKeysTmpl
) {
    'use strict';
    return BaseCompositeView.extend({
        className: 'highlightable-table',
        template: apiKeysTmpl,
        childView: ApiKeyView
    });
});

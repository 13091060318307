define('helpers/activityTemplateHelper',[
    'helpers/humanizeUnits',
    'constants/units',
    'controllers/apiKeyController',
    'moment',
    'underscore',
    'i18n!nls/messages',
], function (humanizeUnits, units, apiKeyController, moment, _, messageBundle) {
    'use strict';

    //protect ourself from 'bad' data - bunch of 'paranoid' is-a functions ...
    
    function isFailedTransfer(xferStatus) {
        if (_.isUndefined(xferStatus) || _.isEmpty(xferStatus)) {
            return true;
        }
        if (/fail/ig.test(xferStatus)) {
            return true;
        }
        return false;
    }

    function isCancelled(xferStatus) {
        if (_.isUndefined(xferStatus) || _.isEmpty(xferStatus)) {
            return false;
        }
        if (/cancel/ig.test(xferStatus)) {
            return true;
        }
        return false;
    }
    
    function isSuccess(xferStatus) {
        if (_.isUndefined(xferStatus) || _.isEmpty(xferStatus)) {
            return false;
        }
        if (/success/ig.test(xferStatus)) {
            return true;
        }
        return false;
    }
    
    function isUpload(direction) {
        if (_.isUndefined(direction) || _.isEmpty(direction)) {
            return false;
        }
        if (/upload/ig.test(direction)) {
            return true;
        }
        return false;
    }
    
    function isDownload(direction) {
        if (_.isUndefined(direction) || _.isEmpty(direction)) {
            return false;
        }
        if (/download/ig.test(direction)) {
            return true;
        }
        return false;
    }
    
    return {

        formattedTransferDate: function () {
            return moment.utc(this.timestamp).fromNow();
        },
        
        formattedTransferSize: function () {
            if (_.isUndefined(this.totalTransferredBytes)) {
                return '';
            }
            var convertedBytes = humanizeUnits.fileSize(this.totalTransferredBytes);
            return convertedBytes.value + ' ' + units.convertToBase1000Units(convertedBytes.unit);
        },
        
        isEmptyResult: function () {
            return _.isUndefined(this.length) === true || this.length <= 0;
        },
        
        hostNameOrIP: function () {
            return (_.isUndefined(this.hostName) ||
                    _.isNull(this.hostName) ||
                    _.isEmpty(this.hostName)) ? this.hostIP:this.hostName;
        },
        
        transferStatusIconClass: function () {
            var statusClass = 'fa fa-lg ';
            // ALWAYS check failure first
            if (isFailedTransfer(this.transferStatus)) {
                statusClass += 'fa-exclamation-triangle text-danger';
            }
            else if (isCancelled(this.transferStatus)) {
                statusClass += 'fa-times-circle';
            }
            else if (isSuccess(this.transferStatus)) {
                if (isUpload(this.direction)) {
                    statusClass += 'fa-arrow-circle-up';
                }
                else if (isDownload(this.direction)) {
                    statusClass += 'fa-arrow-circle-down';
                }
            }
            else {
                statusClass += 'fa-exclamation-triangle text-danger';
            }
            return statusClass;
        },
        
        transferStatusIconTitle: function () {
            var titleText = '';
            if (isFailedTransfer(this.transferStatus)) {
            	titleText = messageBundle.activity.transferError;
            }
            else if (isCancelled(this.transferStatus)) {
            	titleText = messageBundle.activity.canceled;
            }
            else if (isSuccess(this.transferStatus)) {
                if (isUpload(this.direction)) {
                	titleText = messageBundle.activity.successfulUpload;
                }
                else if (isDownload(this.direction)) {
                	titleText = messageBundle.activity.successfulDownload;
                }
            }
            else {
            	titleText = messageBundle.activity.transferError;
            }
            return titleText;
        },
        
        keyDisplay: function () {
            var name = apiKeyController.getApiKeyName(this.apiKey);
            if (_.isUndefined(name) || _.isNull(name) || _.isEmpty(name)) {
                name = this.apiKey;
            }
            return name;
        }
        
    };
});


define('views/items/totalTransfersView',[
    'views/base/baseItemView',
    'hbs!templates/items/totalTransfers',
    'helpers/totalTransfersTemplateHelper',
    'helpers/humanizeUnits',
    'constants/units',
    'underscore'
], function (BaseItemView, TotalTransfersTmpl, totalTransfersTemplateHelper,
		humanizeUnits, units,  _) {
    
    'use strict';
    return BaseItemView.extend({
        template: TotalTransfersTmpl,
        templateHelpers: totalTransfersTemplateHelper,
        className: 'sub-header-offset',
        initialize: function () {
            this.listenTo(this.model, 'fetch:success', this.render, this);
            this.listenTo(this.model, 'fetch:error', this.onDataFetchError, this);
            this.model.fetch();
        },

        onDataFetchError: function () {
            this.model.set('uploadedBytes', 0);
            this.model.set('downloadedBytes', 0);
            this.render();
        },
        
        serializeData: function () {
            var object =  this.model.toJSON();
			if (_.isUndefined(object.uploadedBytes) === false &&
                _.isUndefined(object.downloadedBytes) === false) {
                var convertedBytes = humanizeUnits.fileSize([
                    object.downloadedBytes, object.uploadedBytes
                ]);

                // Convert to the base 1000 units for display purposes
                // MiB => MB, KiB => KB, TiB => TB
                object.formattedDownloadedBytes = convertedBytes[1];
                object.formattedDownloadedBytes.unit = units.convertToBase1000Units(
                    object.formattedDownloadedBytes.unit
                );

                object.formattedUploadedBytes = convertedBytes[0];
                object.formattedUploadedBytes.unit = units.convertToBase1000Units(
                    object.formattedUploadedBytes.unit
                );
            }
            return object;
        }
    });
});

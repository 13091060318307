
/* START_TEMPLATE */
define('hbs!templates/composite/sideNav',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  


  return "<ul class=\"nav nav-stacked nav-sig-sidenav\">\n</ul>\n";
  });
Handlebars.registerPartial('templates/composite/sideNav', t);
return t;
});
/* END_TEMPLATE */
;


/* START_TEMPLATE */
define('hbs!templates/items/historicalTransfer',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  buffer += "<div class=\"row activityrow\">\n    <div class=\"col-md-3 col-sm-5 hidden-xs activity-col activity-col-client\" title=\"";
  if (helper = helpers.hostNameOrIP) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.hostNameOrIP); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">";
  if (helper = helpers.hostNameOrIP) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.hostNameOrIP); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n    <div class=\"col-md-2 col-sm-4 col-xs-4 activity-col row\">\n        <div class=\"col-md-7 col-sm-7 col-xs-7 transfer-text\">";
  if (helper = helpers.formattedTransferSize) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.formattedTransferSize); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n        <div class=\"col-md-5 col-sm-5 col-sm-5\"><i class=\"";
  if (helper = helpers.transferStatusIconClass) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.transferStatusIconClass); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\" title=\"";
  if (helper = helpers.transferStatusIconTitle) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.transferStatusIconTitle); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\"/></div>\n    </div>\n    <div class=\"col-md-3 hidden-xs hidden-sm activity-col\" title=\"";
  if (helper = helpers.keyDisplay) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.keyDisplay); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">";
  if (helper = helpers.keyDisplay) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.keyDisplay); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n    <div class=\"col-md-3 col-sm-3 col-xs-8 activity-col\">";
  if (helper = helpers.formattedTransferDate) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.formattedTransferDate); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  buffer += escapeExpression(stack1)
    + "</div>\n</div>\n";
  return buffer;
  });
Handlebars.registerPartial('templates/items/historicalTransfer', t);
return t;
});
/* END_TEMPLATE */
;

define('views/items/periodTransfersView',[
    'd3',
    'underscore',
    'jquery',
    'moment',
	'views/base/baseItemView',
    'hbs!templates/items/periodTransfers',
	'i18n!nls/messages',
	'helpers/dateFormatHelper',
	'helpers/tooltip'
], function (d3, _, $,
             moment,
             BaseItemView,
             periodTransfersTmpl,
             messageBundle,
             dateFormatHelper,
             d3tooltip)
 {
	'use strict';
	return BaseItemView.extend({
		template : periodTransfersTmpl,

		ui : {
            graph: '.graph'
		},

		initialize: function () {
            this.listenTo(this.model, 'fetch:success', this.onDataFetchSuccess, this);
            this.model.fetch();
		},

		renderGraph : function (dataset) {
            var labelAreaHeight = 20,
               fullWidth = 500,
               fullHeight = 180,
               width = fullWidth,
               height = fullHeight - labelAreaHeight,
               startLabel = messageBundle.overview.thirtyDaysAgo,
               endLabel = messageBundle.overview.today;

			// make sure that our numbers are actually numbers
			dataset.forEach(function (d) {
                d.downloadedBytes = +d.downloadedBytes;
                d.uploadedBytes = +d.uploadedBytes;
			});

			// we get back the dataset in the wrong order
			dataset = dataset.reverse();

			var maxTransfersBytes = d3.max(dataset, function (d) {
                return d.uploadedBytes + d.downloadedBytes;
			}) * 1.2;
			
            if (maxTransfersBytes === 0) {
                maxTransfersBytes = 1;
			}
			
            var data = d3.layout.stack()([0, 1].map(function (index) {
                return dataset.map(function (d) {
                    if (index === 0) {
                        return {
                            x: d.startDate,
                            y: +(d.uploadedBytes + d.downloadedBytes),
                            uploadedBytes: d.uploadedBytes,
                            downloadedBytes: d.downloadedBytes,
                            startDate: d.startDate
                        };
                    } else {
                        return {
                            x: d.startDate,
                            y: +(maxTransfersBytes - (d.uploadedBytes + d.downloadedBytes)),
                            uploadedBytes: d.uploadedBytes,
                            downloadedBytes: d.downloadedBytes,
                            startDate: d.startDate
                        };
                    }
                });
            }));
			
			var domain = data[0].map(function (d) { return d.startDate; });
			var maxY = d3.max(data, function (d) {
                return d3.max(d, function (d) {
                    return d.y0 + d.y;
                });
			});
			
			var xScale = d3.scale.ordinal()
                .domain(domain)
                .rangeRoundBands([0, width], 0.05);
			
			var yScale = d3.scale.linear()
                .domain([0, maxY])
                .range([height, 0]);
			
			var colors = ['#a9a9ab', '#e3e3e8'];
			var svg = d3.selectAll(this.ui.graph.toArray())
                .append('svg')
                .attr({
                    width: fullWidth,
                    height: fullHeight
                });
            
            var groups = svg.selectAll('g')
                .data(data)
                .enter().append('g')
                .style('fill', function (d, i) {
                    return colors[i];
                });

            var tip = d3tooltip({
                svg: svg,
                className: this.isIE() ? 'd3-tip-ie' : 'd3-tip',
                followMouse: this.isIE()
            });
            
            groups.selectAll('rect')
                .data(function (d) { return d; })
                .enter()
                .append('rect')
                .attr({
                    x: function (d) {
                        return xScale(d.x);
                    },
                    y: function (d) {
                        return yScale(d.y0 + d.y);
                    },
                    height: function (d) {
                        return yScale(d.y0) - yScale(d.y0 + d.y);
                    },
                    width: xScale.rangeBand(),
                    'class': function (d, i, i2) {
                        var toReturn = 'D' + dateFormatHelper.utc(true).formattedDate(
                            d.startDate,
                            dateFormatHelper.DATE_YEAR_MONTH_DAY
                        );
                        return (i2 === 0 ? 'data': 'bg') + ' ' + toReturn;
                    }
                })
                .on('mouseover', function (d) {
                    var formattedDate = dateFormatHelper.utc(true).formattedDate(
                        d.startDate,
                        dateFormatHelper.DATE_YEAR_MONTH_DAY
                    );
                    groups.selectAll('.D' + formattedDate).classed('hover', true);
                    tip.show.apply(this, arguments);
                })
                .on('mouseout', function (d) {
                    var formattedDate = dateFormatHelper.utc(true).formattedDate(
                        d.startDate,
                        dateFormatHelper.DATE_YEAR_MONTH_DAY
                    );
                    groups.selectAll('.D' + formattedDate).classed('hover', false);
                    tip.hide.apply(this, arguments);
                });

            var labels = svg.append('g')
                .attr('class', 'labels');

            labels.append('text')
                .data([startLabel])
                .style('text-anchor', 'start')
                .text(function (d) { return d; })
                .attr({
                    'font-family': 'sans-serif',
                    'font-size': '11px',
                    x: 0,
                    y: fullHeight - 5
                });
            
            labels.append('text')
                .data([endLabel])
                .style('text-anchor', 'end')
                .text(function (d) { return d; })
                .attr({
                    'font-family': 'sans-serif',
                    'font-size': '11px',
                    x: fullWidth,
                    y: fullHeight - 5
                });
		},

		onDataFetchSuccess: function (model, data) {
            if (!_.isEmpty(data)) {
                this.renderGraph(data);
            } else {
                
            }
		},

		onRender : function () {
			
		},

		onShow : function () {

		},
		
		onDestroy: function () {
            // Oh d3-tool tip, you so silly...
            var tipId = this.isIE() ? '.d3-tip-ie' : '.d3-tip';
            $(tipId).remove();
		}
	});
});

define('views/items/loginView',[
    'jquery',
    'views/base/baseItemView',
    'hbs!templates/items/login',
    'i18n!nls/messages',
    'environment',
    'hbs/handlebars',
    'jquery.placeholder'
], function ($, BaseItemView, LoginTmpl, messageBundle, environment, Handlebars) {
    'use strict';
    return BaseItemView.extend({
        template: LoginTmpl,
        
        initialize: function () {
        },
        
        ui: {
            userName: '#skydrop-admin-login-username',
            password: '#skydrop-admin-login-password',
            userNameWrapper: '#skydrop-admin-login-username-wrapper',
            passwordWrapper: '#skydrop-admin-login-password-wrapper',
            errorMessageArea: '.skydrop-admin-panel-dialog-message'
        },
        
        /* Ui events hash */
        events: {
            'submit form#skydrop-admin-login-form': 'handleFormSubmit'
        },
        
        onRender: function () {
            this.showMessages();
        },
        
        handleFormSubmit: function () {
            this.clearError();
            if (this.isEmpty(this.ui.userName)) {
                this.showError(this.ui.errorMessageArea, messageBundle.login.emptyEmailErrorMessage);
                this.markErrorField(this.ui.userNameWrapper);
                return false;
            }
            if (this.isEmpty(this.ui.password)) {
                this.showError(this.ui.errorMessageArea, messageBundle.login.emptyPasswordErrorMessage);
                this.markErrorField(this.ui.passwordWrapper);
                return false;
            }
            return true;
        },
        
        showMessages: function () {
            if (environment.reason) {
                if (environment.reason.error !== '') {
                    this.showError(this.ui.errorMessageArea, this.resolveMessage(messageBundle.login[environment.reason.error]));
                } else if (environment.reason.info !== '') {
                    this.showInfo(this.ui.errorMessageArea, this.resolveMessage(messageBundle.login[environment.reason.info]));
                }
            }
        },

        clearError: function () {
            this.clearErrorField(this.ui.passwordWrapper);
            this.clearErrorField(this.ui.userNameWrapper);
            this.ui.errorMessageArea.text('');
        },
        
        resolveMessage: function (message) {
            var params = null;
            try {
                if (environment.reason.params) {
                    params = JSON.parse(environment.reason.params);
                }
            } catch (err) {
            }
            
            if (params != null) {
                var message = Handlebars.compile(message);
                return message(params);
            } else {
                return message;
            }
        },
        
        onShow: function () {
            $('input[placeholder]').placeholder();
            if (!this.isIE()) {
                if (!this.ui.userName.val()) {
                    this.ui.userName.focus();
                } else {
                    this.ui.password.focus();
                }
            }
        },
    });
});


/* START_TEMPLATE */
define('hbs!templates/layouts/storage',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"row\">\n    <div class=\"col-md-12 col-md-offset-1\">\n        <h1 class=\"content-header\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "storage.pageHeader", options) : helperMissing.call(depth0, "i18n", "storage.pageHeader", options)))
    + "</h1>\n        <div id=\"showRevokedStorageConfigsWrapper\" class=\"show-revoked-storage-configs-wrapper\">\n		    <label for=\"showRevokedStorageConfigsCheckBox\" class=\"show-revoked-storage-configs-label\">\n		        <input type=\"checkbox\" id=\"showRevokedStorageConfigsCheckBox\" class=\"show-revoked-storage-configs-checkbox\">\n		        "
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "storage.showRevokedCheckBox", options) : helperMissing.call(depth0, "i18n", "storage.showRevokedCheckBox", options)))
    + "\n		    </label>\n	    </div>\n	    <div class=\"vertical-space-10\"></div>\n    </div>\n</div>\n<div class=\"container-fixed\">\n	<div class=\"row\">\n	    <div class=\"col-md-1\">\n	        <a href=\"#\" id=\"storage-add\" class=\"add-storage-config\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "storage.add", options) : helperMissing.call(depth0, "i18n", "storage.add", options)))
    + "</a>\n	    </div>\n	    <div class=\"table-area col-md-8\"></div>\n		<div class=\"add-storage-region\"></div>\n	</div>\n</div>";
  return buffer;
  });
Handlebars.registerPartial('templates/layouts/storage', t);
return t;
});
/* END_TEMPLATE */
;

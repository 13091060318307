define('controllers/apiKeySecretController',[
    'helpers/skydropContextHelper',
    'frontend-common-components/modalController/modalController',
    'views/items/generateSecretKeyDialogView',
    'jquery'
], function (contextHelper, ModalController, GenerateSecretKeyDialogView, $) {
    'use strict';

    return {	
        generateSecret: function (apiKeyId) {
            return $.post(contextHelper.getGenerateSecretUrl(apiKeyId)).then(function (updatedApiKey) {
                return updatedApiKey;
            });
        }
    };
});

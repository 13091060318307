define('models/awsStorageConfig',[
    'frontend-common-components/objectStorageConfig/js/models/objectStorageConfig',
    'helpers/skydropContextHelper'
],
function (BaseStorageConfigModel, contextHelper) {
    'use strict';
    return BaseStorageConfigModel.extend({
        url: function () {
            return contextHelper.getAWSStorageConfigURL();
        }
    });
});

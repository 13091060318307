
/* START_TEMPLATE */
define('hbs!frontend-common-components/objectStorageConfig/templates/items/addAzureStorage',['hbs','hbs/handlebars','frontend-common-components/objectStorageConfig/js/helpers/handlebars/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<div class=\"row first-row\">\n    <div class=\"col-xs-12\">\n        "
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.azure.instructions", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.azure.instructions", options)))
    + "\n   </div>\n</div>\n<div class=\"row separated\">\n    <div class=\"col-xs-3 text-input-col\">\n        <label for=\"azure-storage-account\" class=\"text-input-label\">"
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.azure.account", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.azure.account", options)))
    + "</label>\n    </div>\n    <div class=\"col-xs-10 \">\n        <input class=\"form-control\" type=\"text\" \n               placeholder=\""
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.azure.storageAccountPlaceholder", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.azure.storageAccountPlaceholder", options)))
    + "\"\n               id=\"azure-storage-account\" />\n    </div>\n</div>\n<div class=\"row\">\n    <div class=\"col-xs-3 text-input-col\">\n        <label for=\"azure-storage-key\" class=\"text-input-label\">"
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.azure.key", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.azure.key", options)))
    + "</label>\n    </div>\n    <div class=\"col-xs-10 \">\n        <input class=\"form-control\" type=\"text\"\n               placeholder=\""
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.azure.keyPlaceholder", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.azure.keyPlaceholder", options)))
    + "\"\n               id=\"azure-storage-key\" />\n    </div>\n</div>\n<div class=\"row\">\n    <div class=\"col-xs-3\"></div>\n    <div id=\"azure-message\" class=\"col-xs-10 object-storage-message-text\"></div>\n</div>\n<div class=\"bucket-configuration-body\"/>\n<div class=\"row\">\n    <div class=\"col-xs-5 col-xs-offset-8\">\n        <button class=\"btn btn-primary btn-block\" id=\"confirm-add-storage\">"
    + escapeExpression((helper = helpers['i18n-objectStorageConfig'] || (depth0 && depth0['i18n-objectStorageConfig']),options={hash:{}},helper ? helper.call(depth0, "storage.addPanel.addButton", options) : helperMissing.call(depth0, "i18n-objectStorageConfig", "storage.addPanel.addButton", options)))
    + "</button>\n    </div>\n</div>\n";
  return buffer;
  });
Handlebars.registerPartial('frontend-common-components/objectStorageConfig/templates/items/addAzureStorage', t);
return t;
});
/* END_TEMPLATE */
;


/* START_TEMPLATE */
define('hbs!templates/items/emptyMessageView',['hbs','hbs/handlebars'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var stack1, helper, functionType="function", escapeExpression=this.escapeExpression;


  if (helper = helpers.message) { stack1 = helper.call(depth0, {hash:{}}); }
  else { helper = (depth0 && depth0.message); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{}}) : helper; }
  return escapeExpression(stack1);
  });
Handlebars.registerPartial('templates/items/emptyMessageView', t);
return t;
});
/* END_TEMPLATE */
;

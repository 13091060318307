define('views/layouts/loginPanelLayout',[
    'views/base/baseLayoutView',
    'hbs!templates/layouts/loginPanel'

], function (BaseLayoutView, loginPanelTmpl) {
    'use strict';
    return BaseLayoutView.extend({
        template: loginPanelTmpl,

        className: 'login-panel-layout',

        regions: {
            panelRegion: '.login-panel-region'
        },

        initialize: function (options) {
            this.view = options.view;
        },
        
        showPanelView: function (view) {
            this.view = view;
            this._showView();
        },
        
        _showView: function () {
            this.panelRegion.show(this.view);
        },
        
        onShow: function () {
            this._showView();
        }
    });
});

define('views/items/pageLoadIndicatorView',[
    'views/base/baseItemView',
    'hbs!templates/items/pageLoadIndicator'
], function (BaseItemView, PageLoadIndicator) {
    'use strict';
    return BaseItemView.extend({
        template: PageLoadIndicator,

        initialize: function () {
        },

        onRender: function () {
            
        }
        
    });
});

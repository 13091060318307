'use strict';

require.config({
    baseUrl: 'resources/js'
});

function startApp(App) {
    App.start();
}

require([
    'environment',
    'require-config'
], function (environment) {
    if (environment.authenticated) {
        require(['mainApp'], startApp);
    } else if (environment.signupRequested) {
        require(['signupApp'], startApp);
    } else {
        require(['loginApp'], startApp);
    }
});

define("main", function(){});


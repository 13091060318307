
/* START_TEMPLATE */
define('hbs!templates/items/forgotPassword',['hbs','hbs/handlebars','libs/handlebars-helpers/i18n'], function( hbs, Handlebars ){ 
var t = Handlebars.template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers);
  var buffer = "", helper, options, helperMissing=helpers.helperMissing, escapeExpression=this.escapeExpression;


  buffer += "<form id=\"forgot-password-form\" action=\"\" method=\"\" class=\"skydrop-admin-panel-form\">\n    <div id=\"forgot-password-email-wrapper\" class=\"form-element-wrapper has-feedback\">\n        <input id=\"forgot-password-email\" class=\"form-control\" type=\"text\" name=\"email\" value=\"\" placeholder=\""
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "forgotPassword.emailPlaceholderText", options) : helperMissing.call(depth0, "i18n", "forgotPassword.emailPlaceholderText", options)))
    + "\" />\n        <span class=\"fa fa-exclamation form-control-feedback\"/>\n    </div>\n\n    <div id=\"reset-password-btn-wrapper\" class=\"form-element-wrapper\">\n        <button type=\"submit\" class=\"btn btn-primary\" id=\"reset-password-btn\">"
    + escapeExpression((helper = helpers.i18n || (depth0 && depth0.i18n),options={hash:{}},helper ? helper.call(depth0, "forgotPassword.requestPasswordResetText", options) : helperMissing.call(depth0, "i18n", "forgotPassword.requestPasswordResetText", options)))
    + "</button>\n    </div>\n</form>\n\n<div class=\"skydrop-admin-panel-dialog-message\"/>";
  return buffer;
  });
Handlebars.registerPartial('templates/items/forgotPassword', t);
return t;
});
/* END_TEMPLATE */
;

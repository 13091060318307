define('views/layouts/loginLayout',[
    'views/base/baseLayoutView',
    'hbs!templates/layouts/login',
    'views/layouts/loginPanelLayout'
], function (BaseLayoutView, LoginLayoutTmpl, LoginPanelLayout) {
    'use strict';
    return BaseLayoutView.extend({
        template: LoginLayoutTmpl,
        
        className: 'login-layout',
        
        regions: {
            loginRegion: '.login-view'
        },

        initialize: function (options) {
            this.view = options.view;
        },

        _showView: function () {
            var loginPanelLayout = new LoginPanelLayout({
                view: this.view
            });
            this.loginRegion.show(loginPanelLayout);
        },
        
        onShow: function () {
            this._showView();
        }
    });
});
